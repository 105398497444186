import { OrderItem } from "../utilits/order_item";

export class CartItem {

  constructor(item, delegate) {
    this.item = item;
    this.orderItem = new OrderItem.findOrInitializeFor(item);
    this.isNew = this.orderItem.item_exists();
    this.delegate = delegate;
  }

  quantityPlus() { 
    this.orderItem.quantity ++;
    this.delegate.orderItemWasChanged();
  }

  quantityMinus() { 
    if(this.orderItem.quantity > 1) this.orderItem.quantity --;
    this.delegate.orderItemWasChanged();
  }

  addOption(id) { 
    this.orderItem.selectOption(id)
    this.delegate.orderItemWasChanged()
  }

  removeOption(id) {
    this.orderItem.unselectOption(id)
    this.delegate.orderItemWasChanged()
  }

  setSpecialRequest(request) { this.orderItem.notes = request }

  save() {
    this.orderItem.buildDescription(this.delegate.item.extras);
    return this.orderItem.save(); 
  }

  remove() { this.orderItem.remove(); }
  description() { return this.orderItem.desc }
  
  static all() { return OrderItem.all(); }
}