import { Dialog } from "../utilits/dialog";
class userShopLoyaltyView{
  constructor(){
    this.bindListeners();
  }

  bindListeners(){
    $('.stamp_count').on('keyup', function(e){
      if(e.which == 13){
        $.ajax({
          type: "PUT",
          dataType: "json",
          url: "/admins/loyalties/" + $(this).attr("id").split("_").pop(),
          data: {
            "loyalty[stamp_count]": $(this).val()
          },
          success: function(){},
          error: function(){}
        });
      }
    });

    var dialog = new Dialog();

    $('.create-gift-stamps').on('click',function(e){
        var user_branch_loyalty_id = $(this).parent().attr("id").split('-').pop();
        dialog.modal = $('#GiftStampModel');
        $('#GiftStampModel').find(".modal-body").children("form").attr("action","/admins/users/-1/user_branch_loyalties/"+ user_branch_loyalty_id +"/gift_stamps" )
        dialog.show();
    });

    $('#create-gift-stamps-form').on('ajax:error',function(e,xhr, status, error){
        dialog.add_errors(xhr.responseJSON.errors)
    });

    $('#create-gift-stamps-form').on('ajax:success',function(){
      dialog.hide();
      location.reload();
    });

  }
}

export {userShopLoyaltyView}