class LocationManager{
  watchPosition(successCb, ErrorCb){
    navigator.geolocation.watchPosition(successCb, ErrorCb);
  }

  getCurrentPosition(successCb, ErrorCb){
    navigator.geolocation.getCurrentPosition(successCb, ErrorCb);
  }

  browserSupportGeoLocation(){
    return navigator.geolocation;
  }

}
export {LocationManager}