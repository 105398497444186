


var WorkingDay = function(element){

  this.element = element;
  this.__id = undefined;
  setObjectId(this);
}

WorkingDay.DAYESOfWeek = [{index: 6, name:"sun"}, {index:0, name: "mon"},{index:1, name: "tue"}, {index: 2, name:"wed"}, {index: 3, name: "thu"}, {index: 4, name:"fri"}, {index: 5, name: "sat"}] 

WorkingDay.prototype.serialize = function(){
  return {isActive: isActiveDay(this), timeFrom: from(this), timeTill: until(this), day: dayName(this)};
}

WorkingDay.prototype.renderUI = function(options){
  if (options.isActive == true) activateDay(this);
  setTimeFrom(this, options.start);
  setTimeTill(this, options.end);
}

//Private
function setTimeTill(_this, until){
  $(_this.element).find('.timeTill').first().val(until);
}

function setTimeFrom(_this, from){
  $(_this.element).find('.timeFrom').first().val(from);
}

function activateDay(_this){
  $(_this.element).find('.dayCheckbox').first().prop('checked', true);
}

function isActiveDay(_this){
  return $(_this.element).find('.dayCheckbox:checked').length != 0;
}

function setObjectId(_this){
  _this.__id = parseInt($(_this.element).find('.dayCheckbox').first().attr('id').split('-')[1]);
}

function dayName(_this){
  return $(_this.element).find('.dayCheckbox').first().attr('id').split('-')[0];
}

function from(_this){
  return $(_this.element).find('.timeFrom').first().val();
}

function until(_this){
  return $(_this.element).find('.timeTill').first().val();
}


export {WorkingDay}