const orderItemTemplate = require('../templates/order_item')
import "../utilits/drivu_socket_io"
import { DrivuSocketIO } from "../utilits/drivu_socket_io";

class OrderDetailsView{
  constructor(options){
    Object.keys(options).forEach(function(key){
      this[key] = options[key];
    }.bind(this))
    this.destination = new google.maps.LatLng(parseFloat(this.branch.parking_latitude), parseFloat(this.branch.parking_longitude));
    this.order_location = new google.maps.LatLng( this.order.latitude || 0, this.order.longitude || 0);
    this.bindListeners();
    this.connectSocket();
    this.showMap();
  }

  bindListeners(){
    var _this = this;
    $('.create_item_check_box').on('ajax:success',function(event,data, status, xhr) {
      $(this).attr('checked', false);
      var item_html = orderItemTemplate({menu_item: data.menu_item, order_item: data.order_item}) //JST['templates/order_item'](data);
      $('.item-list').append(item_html);
      $("#newItemModal").modal("hide");
      var order_total = $('#order_total');
      order_total.val(parseFloat(order_total.val()) + parseFloat(data.order_item.price) );
    });

    $('.create_item_check_box').on('ajax:error',function(event,xhr, status, error) {
      _.each(xhr.responseJSON.errors,function(error){
        $(this).parents().find('.modal-content').prepend("<p>"+error+"</p>")
      }.bind(this));
    });

    $( ".item-list" ).on('ajax:success',"a.fa-trash",function(event, data, status, xhr) {
      $("#order_vat").val(data.new_values.vat);
      $("#order_total").val(data.new_values.total);
      $(this).closest('.row').remove()
    });

    $( ".item-list" ).on('ajax:error', "a.fa-trash", function(event,xhr, status, error) {
      $('.order-info').prepend("<p>"+xhr.responseJSON.errors+"</p>");
    });

    $("#payment_method").on('change', function(e){
      _this.update_payment_method($(this).val());
    });

    $('#order-info').on('keypress','.edit_order_item_price', function(e){
      if (e.which == 13){
       var item_id = $(this).attr("id").split('-').pop();
       $.ajax({
         url: `/admins/orders/${_this.order.id}/order_items/${item_id}`,
         method: "put",
         dataType: "json",
         data: {order_item: {price: $(this).val()}},
       })
      }
    })
  }

  update_payment_method(payment_method_value){
    var params = (payment_method_value == -1) ? {from_loyalty: true } : { payment_method: payment_method_value }
    $.ajax({
      method: "put",
      url: `/admins/orders/${this.order.id}`,
      data: { order: params },
      datatype: "JSON",
      success: function(data, status, xhr){
      },
      error: function(xhr, status, errors){
        $('.order-info').prepend("<p>"+xhr.responseJSON.errors+"</p>");
      }
    })
  }

  showMap(){

    this.map = new google.maps.Map(document.getElementById("map"), {zoom: 17, center: this.order_location, mapTypeId: google.maps.MapTypeId.ROADMAP, mapTypeControl: true});
    this.orderMarker = new google.maps.Marker( {position: this.order_location, map: this.map} );

    var shopMarker = new google.maps.Marker({
      position: this.destination,
      map: this.map,
      icon: this.branch_image.thumb.url,
      width: "32",
      height: "32"
    });
    this.orderMarker.setMap(this.map);
    shopMarker.setMap(this.map);
  }

  connectSocket(){
    var socket = new DrivuSocketIO({user_id: this.user_id, order_owner_id: this.order.user_id, new_location_callback: function(payload){
      console.log('new_location_callback');
      this.update_order_location(payload.latitude, payload.longitude);
      if(payload.distance) {
        $('#distanceCounter').html(payload.distance);
      }

      if(payload.duration) {
        $('#durationCounter').html(parseInt(payload.duration / 60));
      }

    }.bind(this)}, function(){
      console.log("examine_url");
      socket.examine_url(this.order.user_id);
    }.bind(this));
  }

  update_order_location(latitude, longitude) {
    console.log('update_order_location')
    console.log(latitude);
    console.log(longitude)
    var userEntered = false;
    this.order_location = new google.maps.LatLng(latitude, longitude);
    if (google.maps.geometry.spherical.computeDistanceBetween(this.destination, this.order_location) <= 100) {
      $('#arrived').show();
      if (!userEntered) {
        userEntered = true;
      }
    } else {
      $('#arrived').hide();
      if (userEntered) {
        userEntered = false;
      }
    }
    this.orderMarker.setPosition(this.order_location);
    this.map.panTo(this.order_location);
  };

  setNavigation(navigation) {
    var flightPath = new google.maps.Polyline({
      path: google.maps.geometry.encoding.decodePath(navigation.points),
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });
    flightPath.setMap(this.map);
  }

  setParkingLocation(lat, lng) {
    var myLatlng = new google.maps.LatLng(parseFloat(lat),parseFloat(lng));
    var circle = new google.maps.Circle({
      strokeColor: '#007f00',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      clickable: false,
      fillColor: '#5ada7f',
      fillOpacity: 0.35,
      map: this.map,
      center: myLatlng,
      radius: 25
    });
  }
}

export{ OrderDetailsView }