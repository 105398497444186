import { EventEmitter } from "../utilits/event_emitter"
export class DrivuObjects {

  static eventEmitter = new EventEmitter()

  static newForElement(element) {
    var app = Packs.application;
    element.getAttribute('context').split('.').forEach((it) => { app = app[it]; });
    if(element.getAttribute('__uuid')) return
    if(!app.pool) app.pool = [];
    // const uuid = crypto.randomUUID();
    const uuid = this.uuid()
    element.setAttribute('__uuid', uuid)
    var object = new app(element)
    object.__uuid = uuid;
		app.pool.push(object);
    this.emit(`${element.getAttribute('context')}.created`, object)
  }

  static findForElement(selector) {

    var element;
    if(typeof selector === 'string') { element = $(selector).last().get(0) }
    if(typeof selector === 'object') { element = selector.jquery ? selector.get(0) : selector }
    if(!element) return undefined
    const uuid = element.getAttribute('__uuid')
    if(!uuid) return undefined
    var app = Packs.application;
    element.getAttribute('context').split('.').forEach((it) => { app = app[it]; });
    return _.find(app.pool, (it) => { return it.__uuid == uuid })
  }

  static on(event, cb) { 
    this.eventEmitter.on(event, cb) 
  }
  
  static emit(event, data) { 
    this.eventEmitter.emit(event, data);
  }

  static uuid() {
    return ('10000000-1000-4000-8000-100000000000').replace(/[018]/g, c => (
      c^Math.random()*16>>c/4).toString(16)
    );
  }
}