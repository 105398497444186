import "underscore"
import { ImageCropper } from "../views/image_cropper";

const menuItemControlsTemplate = require('../templates/menu_item_controls');

const maxSize = 5,
      minWidth = 1200,
      minHeight = 1200,
      minQuality = 0.8,
      jpgContentType = "image/jpeg";

function _getSizeInMegabytes(size){ return size / 1024 / 1024 }

function _getScaledDimensions(width, height){
  var newWidth, newHeight;
  if(height == width) return [minWidth, minHeight];
  if(width > height) {
    newHeight = minHeight;
    newWidth = (width / height) * minWidth;
    return [newWidth, newHeight];
  }

  if(height > width) {
    newWidth = minWidth;
    newHeight = (height / width) * minHeight;
    return [newWidth, newHeight];
  }
}

export class ImageFile {
  constructor(file){ this.file = file; }
  getType() { return this.file.type; }
  getSize() { return this.file.size; }
  getName() { return this.file.name; }
  getSizeInMegabytes() { return _getSizeInMegabytes(this.file.size); }
  enhanceFile(callback){
    var file = this.file;
    if(_getSizeInMegabytes(file.size) <= maxSize) {
      callback(file);
    }else{
      this.convertToJPG(function(file){
        if(_getSizeInMegabytes(file.size) <= maxSize){
          callback(file);
        }else{
          this.changeSize(minWidth, minHeight, function(file){
            if(_getSizeInMegabytes(file.size) <= maxSize){
              callback(file);
            }else{
              this.changeQuality(minQuality, function(file){
                callback(file);
              }.bind(this))
            }
          }.bind(this))
        }
      }.bind(this));
    }
  }

  isJPG() { return this.getType() == jpgContentType }
  convertToJPG(cb) { (this.isJPG()) ? cb(this.file) : this.compressImage({dataType: jpgContentType}, function(file){ cb(file) }); }
  changeSize(width, height, cb) { this.compressImage({width: width, height: height}, function(file){ cb(file) })};

  changeQuality(quality, cb) { this.compressImage({quality: quality}, function(file){ cb(file) } ) }
  //options [height, width, dataType, quality]
  compressImage(options, callback){
    var c = document.createElement("canvas"),  // create a temp. canvas
      ctx = c.getContext("2d"),
      url = URL.createObjectURL(this.file),
      img = new Image(),
      drawImageActualSize = function(){
        
        var dim = (options.width && options.width < img.width) ? _getScaledDimensions(img.width, img.height) : [img.width, img.height];
        c.width = dim[0];
        c.height = dim[1];

        ctx.drawImage(img, 0, 0, c.width, c.height);

        c.toBlob(function(blob) {
          this.file = new File([blob], `${this.getName().split('.')[0]}.jpeg`, {type: "application/octet-stream"});
          callback(this.file);
        }.bind(this), options.dataType || this.getType(), options.quality || 1); 
      }.bind(this);

      img.onload = drawImageActualSize;
      img.src = url;
  }
}

export class MenuItemView {

  constructor(item) {
    var container, nameField, priceField, descriptionField, imageField, imageFile, previewer;

    var isNewRecord = false;
    var shouldSubmit = isNewRecord;

    this.isRemoved = false;
    this.errors = [];

    Object.keys(item).forEach(function(key){
      this[key] = item[key];
    }.bind(this))
  }

  setElement(element){
    this.container = $(element);
    this.hideLoadingWheel();
    this.previewer = $(element).find('.image_to_upload');
    this.nameField = $(element).find('.item-name');
    this.priceField = $(element).find('.item-price');
    this.descriptionField = $(element).find('.item-description');
    this.editFiled = $(element).find('.edit-image');

    this.bindDeleteMenuItem();
    if(!this.isNewRecord) this.bindListeners();
    this.imageField = $(element).find('.photo_upload');
    
    this.imageField.on('change', function(){
      this.showLoadingImage();
      var file = this.imageField.get(0).files[0];
      this.imageFile = new ImageFile(file);
      this.imageFile.enhanceFile(function(file){ 
        this.previewer.attr('src', URL.createObjectURL(file));
        this.isValidForm();
      }.bind(this));
    }.bind(this));

    this.editFiled.on('click', function(){
      var _this = this;
        new ImageCropper(this.previewer.attr("src")).start(function(imageEditor){
          imageEditor.getBlobFile(function(files){
            _this.imageField[0].files = files;
            _this.imageField.trigger("change");
          });
      });
    }.bind(this));

    this.previewer.on('click', function () {
      this.imageField.trigger('click');
    }.bind(this));

    this.name = this.nameField.val();
    this.price = parseFloat(this.priceField.val());
    this.description = this.descriptionField.val();
  }

  setReservationId(reservation_id){
    this.reservation_id = reservation_id;
  }

  showLoadingImage(){this.previewer.attr('src', '/loading_gif.gif');}

  submit(cb){
    this.hideErrors()
    this.showLoadingWheel()
    if(!this.isValidForm()) {
      this.showErrors(this.errors);
      return cb();
    }
    $.ajax({
      type: (this.isNewRecord ? 'POST' : 'PUT'),
      url: (this.isNewRecord ? this.insertUrl() : this.updateUrl()),
      data: this.data(),
      dataType: "JSON",
      async: true,
      cache: false,
      contentType: false,
      processData: false,
      timeout: 60000,
      success: function (data) { 
        this.showCheckMark();
        this.reload(data.item);
        cb(); 
      }.bind(this),
      error: function(res){
        this.showErrors(res.responseJSON.errors);
        this.hideLoadingWheel();
        cb()
      }.bind(this)
    });
  }

  isValidForm(){
    var isValid = true;
    if(this.imageFile && this.imageFile.getSizeInMegabytes() > maxSize) {
      this.errors.push("Image size should be less than 5 MB");
      isValid = false
    }

    return isValid;
  }

  reload(item){
    if(this.isNewRecord) {
      var controls =  menuItemControlsTemplate({item: item});
      $(this.container).find('.controls').html(controls);
      this.bindListeners();
    }
    this.isNewRecord = false;
    this.imageFile = undefined;
    this.errors = [];
    Object.keys(item).forEach(function(key){
      this[key] = (item[key] == '') ? undefined : item[key];
    }.bind(this))
  }

  baseInsetUrl(){
    return `/moderator/shop/${this.user_id}/branches/${this.branch_id}/menu_items/`
  }
  
  insertUrl(){
    return `${this.baseInsetUrl()}${this.queryParams()}`
  }

  updateUrl(){
    return `${this.baseInsetUrl()}${this.id}${this.queryParams()}`
  }

  queryParams(){
    return `?reservation_id=${this.reservation_id}&update_by_reference=${$('#update_by_reference').is(":checked")}`
  }

  data(){
    var formData = new FormData();
    if(this.imageFile) formData.append("menu_item[image]", this.imageFile.file, this.imageFile.getName());
    if(this.nameField.val()) formData.append("menu_item[name]", this.nameField.val());
    if(this.priceField.val()) formData.append("menu_item[price]", this.priceField.val());
    if(this.category_id) formData.append("menu_item[category_id]", this.category_id);
    formData.append("menu_item[description]", this.descriptionField.val() || '');
    return formData;
  }

  checkChangedFields(){
    this.shouldSubmit = (this.isNewRecord || 
                        this.name != this.nameField.val() ||
                        this.price != parseFloat(this.priceField.val()) ||
                        this.description != this.descriptionField.val() ||
                        this.imageFile != undefined);

    return (this.shouldSubmit && !this.isRemoved)
  }

  showErrors(errors){
    this.hideLoadingWheel();
    this.container.find('.errors-container').removeClass('hidden');
    errors.forEach(function(err){
      var element = `<div class="alert alert-danger" role="alert">${err}</div>`;
      this.container.find('.error-list').append(element);
    }.bind(this));
  }

  hideErrors(){
    this.container.find('.error-list').html('');
    this.container.find('.errors-container').addClass('hidden');
  }

  showLoadingWheel(){
    this.hideLoadingWheel()
    this.container.find('.circle-loader').show();
    this.container.find('.circle-loader').removeClass('load-complete');
    this.container.find('.checkmark').hide();
  }

  showCheckMark(){
    this.container.find('.circle-loader').addClass('load-complete');
    this.container.find('.checkmark').show();
    setTimeout(function(){ this.hideLoadingWheel() }.bind(this), 3000);
  }

  hideLoadingWheel(){
    this.container.find('.circle-loader').hide();
    this.container.find('.circle-loader').removeClass('load-complete');
    this.container.find('.checkmark').hide();
  }

  bindListeners(){
    this.bindBestSellerAction();
    this.bindNewItemAction();
    this.bindActiveItemAction();
  }

  bindDeleteMenuItem(){

    $(this.container).find('.delete_menu_item').on('click', function (e) {
      e.preventDefault();
      this.isNewRecord ? this.removeRow() : this.remoteDelete();
    }.bind(this));
  }

  removeRow(){
    this.container.fadeOut();
    this.isRemoved = true;
  }

  remoteDelete(){
    var confirmed = confirm("Are you sure you want to delete this item?");
    if(!confirmed) return;

    $.ajax({
      url: this.updateUrl(),
      type: "delete",
      dataType: "json",
      data: { "update_by_reference": $('#update_by_reference').is(":checked") },
      success: function () { this.removeRow(); }.bind(this),
      error: function () { $(".alert-danger.error").show(); }
    })    
  }

  bindActiveItemAction(){
    $(this.container).find('.item-active').on('change', function () {
      $.ajax({
        type: 'PUT',
        url: this.updateUrl(),
        data: { "menu_item[active]": $(this.container).find(".item-active").is(":checked") },
        success: function (data) {}
      });
    }.bind(this));
  }

  bindNewItemAction(){
    $(this.container).find('.new_item').on('change', function () {
      $.ajax({
        type: 'PUT',
        url: this.updateUrl(),
        data: { "menu_item[new_item]": $(this.container).find(".new_item input:checkbox").is(":checked") },
        success: function (data) {}
      });
    }.bind(this));
  }

  bindBestSellerAction(){
    $(this.container).find('.best_seller').on('change', function () {
      $.ajax({
        type: 'PUT',
        url: this.updateUrl(),
        data: { "menu_item[best_sellers]": $(this.container).find(".best_seller input:checkbox").is(":checked") },
        success: function (data) {}
      });
    }.bind(this));
  }
}


