export class DrivuMap {
  
  constructor (options = {}){

    this.markers = [];
    this.marker = this.generateMarker(0, 0);
    this.circle;
    this.bounds = new google.maps.LatLngBounds();
    this.elementId = options.elementId || "map-canvas";  
    this.mapOptions = options.mapOptions || {
      center: new google.maps.LatLng(parseFloat(25.245283333780417), parseFloat(55.295513929581375)),
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
    }
    this.map = new google.maps.Map(document.getElementById(this.elementId), this.mapOptions);
    this.branchLogoMarkers = []
  }
  
  onClick(callback){
    google.maps.event.addListener(this.map, 'click', function(event){
      callback(event);
    });
  }

  moveCameraToLocation(lat, lng){
    this.map.panTo(new google.maps.LatLng(lat, lng));
  };

  moveCameraToMarker(marker){
    this.moveCameraToLocation(marker.getPosition().lat(), marker.getPosition().lng());
  }

  generateMarker(lat, lng, label, img){
    return new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      label: label,
      icon: img
    });
  }

  pinMarkerToCurrentMap(marker){
    marker.setMap(this.map);
    this.markers.push(marker);
  };

  moveMarker(lat, lng, marker = this.marker){
    marker.setPosition( new google.maps.LatLng( lat, lng ) );
  }

  rotateIcon(marker, heading){
    var icon = marker.getIcon();
    icon.rotation = heading;
    marker.setIcon(icon);
  }

  clearMarkers(){
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  fitBounds(){
    this.map.fitBounds(this.bounds);
  }

  appendDrivuBranchLogos(){
    this._getDrivuBranchLogoUrl(function(){
      this._addBranchLogoMarkers();
    }.bind(this));
  }

  _getDrivuBranchLogoUrl(cb){
    if(_.isEmpty(localStorage.getItem('branch_logos_path'))){ cb }
    $.ajax({
      type: 'GET',
      url: '/branch_logos',
      success: function(res){ 
        localStorage.setItem('branch_logos_path', JSON.stringify(res));
        cb();
      }
    });
  }

  _addBranchLogoMarkers(){
    var markerUrls = JSON.parse(localStorage.getItem('branch_logos_path'));
    _.each(markerUrls, function(url){
      var drivuMarker = new Packs.application.DrivuMarker(url);
      this.branchLogoMarkers.push(drivuMarker);
      drivuMarker.addToMap(this.map);
    }.bind(this));
  }
}