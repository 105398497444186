// ClusterIcon.prototype.createCss = function(pos) {
//   var style = [];
//   style.push('background-image:url(' + this.url_ + ');');
//   var backgroundPosition = this.backgroundPosition_ ? this.backgroundPosition_ : '0 0';
//   style.push('background-position:' + backgroundPosition + ';');

//   var txtColor = this.textColor_ ? this.textColor_ : 'black';
//   var txtSize = this.textSize_ ? this.textSize_ : 11;


//   if (typeof this.anchor_ === 'object') {
//     if (typeof this.anchor_[0] === 'number' && this.anchor_[0] > 0 &&
//         this.anchor_[0] < this.height_) {
//       style.push('height:' + (this.height_ - this.anchor_[0]) +
//           'px; padding-top:' + this.anchor_[0] + 'px;');
//     } else {
//       style.push('height:' + this.height_ + 'px; line-height:' + this.height_ +
//           'px;');
//     }
//     if (typeof this.anchor_[1] === 'number' && this.anchor_[1] > 0 &&
//         this.anchor_[1] < this.width_) {
//       style.push('width:' + (this.width_ - this.anchor_[1]) +
//           'px; padding-left:' + this.anchor_[1] + 'px;');
//     } else {
//       style.push('width:' + this.width_ + 'px; text-align:center;');
//     }
//   } else {
//     style.push(
//         `height:${this.height_ }px; 
//         line-height:70px;
//         width: ${this.width_ - 30}px;
//         text-align:center;
//         background-color: yellow`);
//   }

  
//   style.push(
//       `cursor: pointer;
//       top: ${pos.y }px; 
//       right: ${pos.x}px; 
//       color: ${txtColor};
//       position:absolute; 
//       font-size: ${txtSize}px; 
//       font-family: Arial,sans-serif;
//       font-weight: bold; 
//       padding-top: 30px;
//       padding-left: 50px;`
//     );
//   return style.join('');
// };

// ClusterIcon.prototype.onAdd = function() {
  
//   console.log('onAdd');

//   this.div_ = document.createElement('DIV');
//   if (this.visible_) {
//     var pos = this.getPosFromLatLng_(this.center_);
//     this.div_.style.cssText = this.createCss(pos);

//     var innerHtml;

//     if (this.cluster_.markers_.length > 0) {
//         innerHtml = "<div><p id='clusterIconText'>" + this.sums_.text + "</p></div>";
//     }

//     this.div_.innerHTML = innerHtml;
//   }

//   var panes = this.getPanes();
//   panes.overlayMouseTarget.appendChild(this.div_);

//   var that = this;
//   google.maps.event.addDomListener(this.div_, 'click', function() {
//     that.triggerClusterClick();
//   });
// };

export class MapView {


  // styles: [
  //   { anchor:[2,22],
  //     textColor: "white",
  //     height: 36, 
  //     width: 36, 
  //     url: location.href.substring(0, location.href.lastIndexOf("/")+1)+'images/pushpin_cluster.png' 
  //   }]}

  static clusterStyles = [
    {
      // textColor: 'white',
      // url: '/shop_cluster/cup-bubble.png',
      // height: 36, 
      // width: 36, 
      // textSize: 16,
      // scaledSize: new google.maps.Size(50, 50),
      // anchor: [20, 22]
   
      textColor: 'white',
      url: '/shop_cluster/cup-bubble.png',
      height: 70,
      width: 90,
      textSize: 18,
      height: 70,
      anchor: 60

   
    }
  ];

  constructor(ui) {
    this.ui = ui
    
    console.log($(this.ui).data())

    this.singleInfoWindow = new google.maps.InfoWindow()
    this.branchLogoMarkers  = [];
    this.marker_label = $(this.ui).data('markerLabel') ?  $(this.ui).data('markerLabel') : ""
    this.moveAutoComplateMarker = $(this.ui).data('hideAutoComplateMarker')
    
    if($(this.ui).data('center')) {
      const latLng = $(this.ui).data('center').split(',')
      if(latLng.length == 2) {
        this.lat = parseFloat(latLng[0])
        this.lng = parseFloat(latLng[1])
      }
    } else {
      this.lng = 55.28233039805896
      this.lat = 25.214064297239194
    }
    this.marker = this.generateMarker(this.lat, this.lng, this.marker_label)

    this.mapOptions = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: $(this.ui).data('zoom') || 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: $(this.ui).data('disableDefaultUi') ? true : false,
      mapTypeControl: $(this.ui).data('removeMapTypeControl') ? false : true
    };

    this.ui.style.position = "relative"
    this.ui.style.width = "100%"
    this.ui.style.height = $(this.ui).data('height') || "500px"
    this.map = new google.maps.Map(this.ui, this.mapOptions);
    

    if( !$(this.ui).data('hide-marker') ){ this.moveMarker(); }

    this.setClickListener((event) => {
      this.lat = event.latLng.lat();
      this.lng = event.latLng.lng();
      this.moveMarker();
      O.emit('MapView.clicked', {event: event, view: this})
    })

    if( $(this.ui).data('appendDrivuBranchLogos')) { this.appendDrivuBranchLogos();}
    if( $(this.ui).data('bindAutocomplete') ) { this.bindAutocompleteSearch()}
    if( $(this.ui).data('markerCacheLogoUrl') ){ this._addDrivuMarker($(this.ui).data('markerCacheLogoUrl'))} 
  }

  setClickListener(callback){
    google.maps.event.addListener(this.map, 'click', function(event){
      callback(event);
    }.bind(this));
  }

  moveMarker(){
    this.marker.setMap(this.map);
    this.marker.setPosition(new google.maps.LatLng(this.lat, this.lng));
  }

  generateMarker(lat, lng, label="", img){
    return new google.maps.Marker({label: label, position: new google.maps.LatLng(lat, lng), icon: img});
  }

  mark(lat, lng, label="", img) {
    var marker = this.generateMarker(lat, lng, label, img)
    marker.setMap(this.map);
    return marker;
  }

  rotateIcon(marker, heading){
    console.log('on rotateIcon')
    var icon = marker.getIcon();
    icon.rotation = heading;
    marker.setIcon(icon);
    marker.setMap(this.map);
  }

  drawPolygon(coordinates, info) {
    
    var poly = new google.maps.Polygon({
      paths: coordinates,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35
    });

    poly.setMap(this.map)

    if(info) {
      google.maps.event.addListener(poly, "click", (event) => {
        this.singleInfoWindow.close()
        var content = "<ul>"
        content += Object.keys(info).map((key) => { return `<li>${key}: ${info[key]}</li>` });
        content += "</ul>"
        this.singleInfoWindow = new google.maps.InfoWindow({
          content: content
        });
        this.singleInfoWindow.setPosition(event.latLng);
        this.singleInfoWindow.open(this.map);
      });
    }
    return poly
  }

  fitAllLocations(locations) {
    var bounds = new google.maps.LatLngBounds();
    locations.forEach(it => {
      bounds.extend(new google.maps.LatLng(it.lat, it.lng));
    });
    this.map.fitBounds(bounds);
  }

  appendDrivuBranchLogos(){
    this._getDrivuBranchLogoUrl(function(){
      this._addBranchLogoMarkers(this.map);
    }.bind(this));
  }

  removeDrivuBranchLogos(){
    this.setDrivuBranchLogoMarkers(null)
  }

  showDrivuBranchLogos(){
    this.setDrivuBranchLogoMarkers(this.map);
  }

  bindAutocompleteSearch(){
    Packs.application.globalFun.getUserCountry(function(country){
      var input = document.getElementById($(this.ui).data('autocompleteInputId'));
      var autocomplete =  new google.maps.places.Autocomplete(input,{
        componentRestrictions: { country: [country]  }
      });

      autocomplete.bindTo("bounds", this.map);
        autocomplete.addListener("place_changed", function() {
        var place = autocomplete.getPlace();
        this.lat = place.geometry.location.lat();
        this.lng = place.geometry.location.lng();
        if( !$(this.ui).data('hideAutoComplateMarker') ){ 
          this.moveMarker(); 
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(17);
        }

        $(document.getElementById($(this.ui).data('autocompletePlaceId'))).val(place.place_id);
        $(document.getElementById($(this.ui).data('autocompletePlaceName'))).val(place.name);
      }.bind(this));
    }.bind(this));
  }
  
  setDrivuBranchLogoMarkers(map){
    _.each(this.branchLogoMarkers, function(drivuMarker){
      drivuMarker.marker.setMap(map)
    });
  }

  branchesOrderCluster(braches, branchOrdersCount){
    this.branchesMarkers = [];
    this.locations = [];
    var mcOptions = {gridSize: 50, styles: MapView.clusterStyles, maxZoom: 15};
    this._generateMarketAsOrderCount(braches, branchOrdersCount, function(){
      this.fitAllLocations(this.locations);
      new MarkerClusterer(this.map, this.branchesMarkers, mcOptions);
    }.bind(this));
  }

  _getDrivuBranchLogoUrl(cb){
    if(_.isEmpty(localStorage.getItem('branch_logos_path'))){ cb }
    $.ajax({
      type: 'GET',
      url: '/branch_logos',
      success: function(res){ 
        localStorage.setItem('branch_logos_path', JSON.stringify(res));
        cb();
      }
    });
  }

  _addBranchLogoMarkers(){
    var markerUrls = JSON.parse(localStorage.getItem('branch_logos_path'));
    _.each(markerUrls, function(url){
      var drivuMarker = this._addDrivuMarker(url);
      this.branchLogoMarkers.push(drivuMarker);
    }.bind(this));
  }

  _generateMarketAsOrderCount(braches, branchOrdersCount, cb ){
    _.each(braches, function(branch){
      var branchMarker = this.generateMarker( parseFloat(branch.latitude), parseFloat(branch.longitude), "", branch.user.image.white_thumb.url)
      this.branchesMarkers.push(branchMarker)
      var ordersCount = branchOrdersCount[branch.id.toString()];
      parseInt(ordersCount).times(function(){
        var location = new google.maps.LatLng(parseFloat(branch.latitude), parseFloat(branch.longitude));        
        var marker = new google.maps.Marker({
          position: location,
          icon: {
            url: "/shop_cluster/cup-bubble.png",
            labelOrigin: {x: 55, y: 30}
          },
          label: {
            text: "1",
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
          }
        });

        this.branchesMarkers.push(marker);
        this.locations.push({lat: location.lat(), lng: location.lng()})
      }.bind(this));
    }.bind(this));
    cb();
  }

  _addDrivuMarker(url){
    var drivuMarker = new Packs.application.DrivuMarker(url);
    drivuMarker.addToMap(this.map);
    return drivuMarker
  }
}
