module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<table class=\"table\">\n  <thead>\n    <tr>\n      <th scope=\"col\">On Map</th>\n      <th scope=\"col\">Store Name</th>\n      <th scope=\"col\">Logged in/out</th>\n      <th scope=\"col\">Online/Offline Switch</th>\n      <th scope=\"col\">iOS app/OS</th>\n      <th scope=\"col\">Android app/OS</th>\n      <th scope=\"col\">Offline Since	</th>\n      <th scope=\"col\">Volume	</th>\n      <th scope=\"col\">Battery	</th>\n\n\n    </tr>\n  </thead>\n  <tbody>\n    ")
    ;  devices.forEach(function(device){ 
    ; __append(" \n      <tr>\n        <td>\n          ")
    ; __append(escapeFn( device.id ))
    ; __append("\n          <div class=\"material-switch\">\n            ")
    ;  if ( device.branch.active ) { 
    ; __append("\n              <input id=\"")
    ; __append(escapeFn( device.branch_id))
    ; __append("-")
    ; __append(escapeFn( device.id))
    ; __append("-")
    ; __append(escapeFn( device.branch.user.id))
    ; __append("\" class=\"switch\" type=\"checkbox\" checked />\n              <label for=\"")
    ; __append(escapeFn( device.branch.id))
    ; __append("-")
    ; __append(escapeFn( device.id))
    ; __append("-")
    ; __append(escapeFn( device.branch.user.id))
    ; __append("\" class=\"label-success\"></label>\n            ")
    ;  } else { 
    ; __append("\n              <input id=\"")
    ; __append(escapeFn( device.branch.id))
    ; __append("-")
    ; __append(escapeFn( device.id))
    ; __append("-")
    ; __append(escapeFn( device.branch.user.id))
    ; __append("\" class=\"switch\" type=\"checkbox\" />\n              <label for=\"")
    ; __append(escapeFn( device.branch.id))
    ; __append("-")
    ; __append(escapeFn( device.id))
    ; __append("-")
    ; __append(escapeFn( device.branch.user.id))
    ; __append("\" class=\"label-success\"></label>\n           ")
    ;  } 
    ; __append(" \n          </div>\n          ")
    ;  if( !device.branch.connected ) { 
    ; __append("\n            <span class=\"glyphicon glyphicon-one-fine-empty-dot\">\n          ")
    ; }else{ 
    ; __append("\n\n            ")
    ;  if(device.branch.busy) { 
    ; __append("\n              <a href=\"#\" data-bs-toggle=\"popover\" title=\"00:00:00\" id=\"countdown::")
    ; __append(escapeFn( device.branch.id ))
    ; __append("::")
    ; __append(escapeFn( device.branch.will_not_be_busy_at ))
    ; __append("\", class=\"countDown\">\n                <span class=\"glyphicon glyphicon-one-fine-yellow-dot\">\n              </a>\n            ")
    ;  } 
    ; __append("\n\n            ")
    ;  if( !device.branch.active ) {
    ; __append("\n              <a href=\"#\" data-bs-toggle=\"popover\" title=\"00:00:00\" id=\"countdown::")
    ; __append(escapeFn( device.id ))
    ; __append("::")
    ; __append(escapeFn( device.branch.will_be_active_at ))
    ; __append("\", class=\"countDown\">\n                <span class=\"glyphicon glyphicon-one-fine-red-dot\">\n              </a>\n            ")
    ;  } 
    ; __append("\n\n            ")
    ;  if(device.branch.active && !device.branch.busy ) { 
    ; __append("\n              <span class=\"glyphicon glyphicon-one-fine-green-dot\">\n            ")
    ;  } 
    ; __append("\n\n          ")
    ;  } 
    ; __append("\n\n          ")
    ;  if ( device.background ) { 
    ; __append("<p class='text-danger'>B<p>")
    ;  } 
    ; __append("\n          ")
    ;  if ( device.loged_in ) { 
    ; __append("<p class='text-danger'>L<p>")
    ;  } 
    ; __append("\n          <p> ")
    ; __append(escapeFn( device.platform_name ))
    ; __append(" </p>\n        </td>\n\n        <td>\n          <ul>\n            <li><small>")
    ; __append(escapeFn( device.shopname ))
    ; __append("</small></li>\n            <li><small>")
    ; __append(escapeFn( device.branch.name ))
    ; __append("</small></li>\n            <li><small>")
    ; __append(escapeFn( device.branch.phone_number ))
    ; __append("</small></li>\n          </ul>\n        </td>\n\n        <td>")
    ; __append(escapeFn( device.loged_in ? 'logged in' : 'logged out'  ))
    ; __append("</td>\n\n        <td>\n          ")
    ; __append(escapeFn( device.online_status ? "online" : "offline" ))
    ; __append("\n          <br />\n          ")
    ; __append(escapeFn( !device.online_status ? device.branch.inactive_reason : ""  ))
    ; __append("\n        </td>\n\n        <td>\n          <p>")
    ; __append(escapeFn( device.ios_version))
    ; __append("</p>\n          <p>")
    ; __append(escapeFn( device.os_ios_version))
    ; __append("</p>\n        </td>\n      \n        <td>\n          <p>")
    ; __append(escapeFn( device.android_version ))
    ; __append("</p>\n          <p>")
    ; __append(escapeFn( device.android_os_version ))
    ; __append("</p>\n        </td>\n\n        <td>")
    ; __append(escapeFn( device.branch.active ? '-' :   device.offline_since ))
    ; __append("</td>\n      \n        <td>")
    ; __append(escapeFn( device.sound_level))
    ; __append("%</td>\n \n        <td>\n          <div class=\"battery\">\n            <div class=\"level\" value=\"")
    ; __append(escapeFn( device.battery_level ))
    ; __append("\" id=\"")
    ; __append(escapeFn( device.id ))
    ; __append("\">\n            </div>\n            <p class=\"battery-percent\">")
    ; __append(escapeFn( device.battery_level || "0" ))
    ; __append("%\n              ")
    ;  if(device.battery_charing) { 
    ; __append("\n               <i class=\"fa fa-bolt\" aria-hidden=\"true\"></i>\n              ")
    ;  } 
    ; __append("\n            </p>\n          </div>\n        </td>\n      \n      \n      </tr>\n    ")
    ;  }) 
    ; __append("\n  </tbody>\n</table>\n")
  }
  return __output;

}