import { itemViewRequest } from "../drivu_global_functions";
import { Indoor } from "../indoor";

Indoor.RecommendationItemView = class{
  constructor(ui) {
    $('.recommendation-items').on('click', '.add-item', function(e){
      e.preventDefault();
      itemViewRequest($(e.currentTarget).data('item-id'));
    })
  }

 static remove(){
    $('.recommendation-items').html('');
  }
}