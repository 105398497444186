export class MultiColorPicker {

  constructor(ui) {
    this.ui = $(ui)
    this.pickers = []
    this.name = $(this.ui).data('name')
    this.colors = $(this.ui).data('colors') || []
    console.log(this.colors);
    this.addButton = $(`<i class="fa fa-plus-square fa-2x add-button" aria-hidden="true"></i>`).appendTo($(this.ui))
    this.resultField = $(`<input type="hidden" name="${this.name}" class="multi-color-picker-result"></input>`).appendTo($(this.ui))
    this.addButton.click(() => { this.addPicker() })
    this.colors.forEach((it) => { 
      console.log(it);
      this.addPicker(it) })
  }

  addPicker(hex = "#000000") {
    // var default = (hex || "#000000");
    const picker = $(`<input type="color" value="${hex}">`).insertBefore(this.addButton)
    $(picker).on('input', () => { this.resetFinalValue() })
    this.pickers.push(picker)
    this.resetFinalValue()
  }

  resetFinalValue() {
    const result = this.pickers.map((it) => { return $(it).val() })
    $(this.resultField).val(result)
  }
//   <input type="color" name="parking_zone[color]" id="parking_color">
// <i class="fa fa-plus-square fa-2x" aria-hidden="true"></i>
}