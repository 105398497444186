module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append(" \n \n")
    ;  transactions.forEach(function(transaction){ 
    ; __append("\n  <tr>\n    <td>")
    ; __append(escapeFn( transaction["date"]))
    ; __append("</td>\n    <td>")
    ; __append( description(transaction) )
    ; __append(" </td>\n    <td>")
    ; __append( _number_format(transaction["amount"]) )
    ; __append("</td>\n    <td>")
    ; __append( _number_format(transaction["payment"]) )
    ; __append("</td>\n    <td>")
    ; __append(escapeFn( transaction["account_of_statment_balance"]))
    ; __append("</td>\n  </tr>\n")
    ;  })
    ; __append("\n\n")
    ;  
  function description(transaction){
    if(transaction["type"]== "InvoiceDecorator"){
      return `Invoice<a class='invoice-request' href='/shop/invoices/${transaction["id"]}' data-remote="true"> ${transaction["description"].split(" ").pop()} </a>`;
    }else{
      return `${transaction["description"]}`;
    }
  }

  function _number_format(number){
    console.log(number)
    if(number !== null){
      return accounting.formatNumber(number)
    }
  }

    ; __append("\n\n\n\n")
  }
  return __output;

}