export * as app from '../utilits/drivu_global_functions'
export class AnnouncementForm {

  constructor(ui) {
    this.ui = $(ui)
    this.announcement = $(this.ui).data('announcement')
    this.tagButtons = ui.querySelectorAll('.btn-group button');
    this.htmlElements = ui.querySelectorAll('#none-element, #country-element, #city-element');
    this.countrySelector = ui.querySelector('#country-selector');
    this.citySelector = ui.querySelector('#city-selector');
    this.locationIdInput = ui.querySelector('#announcement_location_id')
    this.locationTypeInput = ui.querySelector('#announcement_location_type')
    this.actionSelector = ui.querySelector('#announcement_action')
    this.tagSelector = ui.querySelector('#announcement_tag_id')

    this.countrySelector.addEventListener('change', (e) => {
      const selectedValue = e.target.value;
      this.locationIdInput.value = selectedValue;
    });

    this.citySelector.addEventListener('change', (e) => {
      const selectedValue = e.target.value;
      this.locationIdInput.value = selectedValue;
    });

    if (this.announcement.location_id != null) {
      $(`#${this.announcement.location_type.toLowerCase()}-selector`).selectpicker('val', this.announcement.location_id.toString())
    }

    this.tagButtons.forEach((button) => {

      button.addEventListener('click', () => {
        $('.selectpicker').selectpicker('val', '')
        const selectedTag = button.getAttribute('data-tag');

        // if(selectedTag == 'none') {
        this.locationIdInput.value = null;
        this.locationTypeInput.value = null
        // }

        if (selectedTag == 'city') { this.locationTypeInput.value = 'City' }
        if (selectedTag == 'country') { this.locationTypeInput.value = 'Country' }

        this.htmlElements.forEach((element) => {
          element.style.display = 'none';
        });

        const selectedElement = document.getElementById(`${selectedTag}-element`);
        selectedElement.style.display = 'block';
      });
    });
  }
}