import { Cart } from "../../models/cart";
import { Indoor } from "../indoor";

Cart.View = class {

  container = '#cart-view'

  titleElement = () => { $(this.container).find('.category-title') }

  constructor(delegate) {
    this.delegate = delegate;
    this.setVisibility();
    this.appendItems();
    this.appendRecommendationItem();
  }

  setVisibility() { Cart.items().length == 0 ? $(this.container).hide() : $(this.container).show() }
  appendItems() { 
    Cart.items().forEach((item) => this.appendItem(item)) 
  }
  appendItem(cartItem) {
    const id = cartItem.id;
    var child = $(`#item-cell-${id}`).clone(true).prop('id', `cart-cell-${id}`);
    this.#setPromotionTitles(child, cartItem);

    child.find('.side-orange-line').removeClass('hidden');
    child.find('.remove-cart-item').removeClass('hidden');
    child.find('.price-area').removeClass('hidden');
    child.find('.item-description').html(cartItem.desc);
    child.attr('data-cart-id', cartItem.__id);

    child.find('.remove-cart-item').on('click', () => {
      cartItem.remove();
      child.remove();
      this.appendRecommendationItem();
      this.delegate.cartWasChanged();
    });

  child.appendTo('#cart-view .category-title');

  }

  appendRecommendationItem(){
    Indoor.RecommendationItemView.remove();
    if(_.isEmpty(Cart.items())){return}
    this.#getRecommendationItems(Cart.items().pop().id)
  }
  
  #setPromotionTitles(child, cartItem){
    if(cartItem.active_promotion){ 
      child.find('.befour-discount').html(`<s>${cartItem.originalGrandTotalWithCurrencyAndQuantity()}</s>`)
      child.find('.after-discount').html(`${cartItem.grandTotalWithCurrency()}`);
    }else{
      child.find('.befour-discount').html(`${cartItem.originalGrandTotalWithCurrencyAndQuantity()}`)
    }

  }

  #getRecommendationItems(menu_item_id){
    $.ajax({
      url: `/moderator/menu_items/${menu_item_id}/menu_item_recommendations.js`,
      method: 'get',
    })
  }
}