import { workingHoursView } from "./working_hours_view";
const pickupHoursTemp = require('../../templates/pickup_hours')

export class PickUpHourView extends workingHoursView{
  
  constructor ( options = {} ){
    super(options)
    this.type =  "pickupHour"
  }

  renderUI(){
    this.containerElement.find('form').find(this.workingHourContainerClassName).append(
     pickupHoursTemp({pickupDaysOfWeek: this.daysOfWeek, branch_id: this.branch_id, type: this.type})
    );
  }

  delegateEvents(){
    var _this = this;
    $(this.workingHourContainerClassName).on("change", `.timeFrom`, function(e){
      var next_hour = $(e.currentTarget).find("option:selected").next().val() || "00:00";
      var timeTillId = `${$(e.currentTarget).data().day}-${_this.type}-timeTill`;
      $(_this.workingHourContainerClassName).find(`#${timeTillId}`).val(next_hour);
    });
  }
  
  appendBranchOnHoursHiddenFiled(){
    $(this.containerElement).find('form').append(`<input type="hidden" name="branch[pickup_hours]" id=${this.branchOnHoursFiledId} value="[]">`)
  }
} 