import { Indoor } from "../indoor";
import { Cart } from "../../models/cart";

Indoor.RegistrationForm = class {

  form = '#ajax_signup';
  errorsContainer = '.errors';
  passwordField = '#user_password';

  constructor(ui) {
    this.ui = ui;
    this.loadLoginListener();
    this.loadShowPasswordListener();
    this.loadRandomSecurePassword();
  }

  loadShowPasswordListener() {
    $("#show_hide_password a").on('click', function(event) {
      event.preventDefault();
      if($('#show_hide_password input').attr("type") == "text"){
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass( "fa-eye-slash" );
        $('#show_hide_password i').removeClass( "fa-eye" );
      }else if($('#show_hide_password input').attr("type") == "password"){
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass( "fa-eye-slash" );
        $('#show_hide_password i').addClass( "fa-eye" );
      }
    });    
  }

  loadRandomSecurePassword() {
    const password = Math.random().toString(36).slice(-8);
    if($(this.passwordField).val() == '') $(this.passwordField).val(password);
  }

  loadLoginListener() {
    $(this.ui).find(this.form).on('ajax:complete', function(e, xhr, status){
    
      if(xhr.status > 299) {

        $(this.ui).find(this.errorsContainer).html('');
  
        xhr.responseJSON.errors.forEach((e) => {
          $(this.ui).find(this.errorsContainer).append(`<div class="alert alert-danger" role="alert">${e}</div>`);
        });

      } else { 
        Cart.userWasRegistered()
        window.location.reload() 
      }
    }.bind(this));    
  }
}