export class CreditCardView {

  static config = {}

  iframeId = '#payment-iframe'
  creditCardCells = '.cc-cell'

  constructor(ui) {
    this.ui = ui
    this.processCode = undefined
    this.returnUrl = undefined
    this.refId = undefined
    this.creditCard = undefined
    this.creditCardId = $(this.ui).find(this.creditCardCells).data('id')
    this.delegate = {
      gotPaymentUrl: (url, processCode) => {},
      iframeWasCreated: (iframe) => {},
      paymentWasAuthorized: (refId, creditCard) => {},
      paymentFailed: (error) => {}
    }

    window.onmessage = function(event) {
      console.log('CreditCardView recived return url message')
      if(event.data == 'check_status'){
        this.checkUrlRequest();
      }
    }.bind(this);

  }

  load(purpose, amount, currency, plan_id) {
    $.ajax({
      type: 'GET',
      url: '/payment_urls',
      data: {
        purpose: purpose,
        currency: currency,
        amount: amount, 
        plan_id: plan_id
      }
    }).done((data) => { 
      this.processCode = data.process_code
      this.returnUrl = data.done_url
      
      try {
        this.delegate.gotPaymentUrl(this.returnUrl, this.processCode)
      } catch (error) {}

      this.appendFrame(data.url) 
    })
  }

  emitCheckStatus(){
    
    console.log('emitCheckStatus after return url');
    console.log(this);
    console.log(this.pool);

    $(this.iframe).data('checkStatus', true);
    console.log('after set');
    console.log($(this.iframe).data());
    this.checkUrl(this.iframe);
  }

  appendFrame(url) {
    this.iframe = document.createElement("IFRAME");
    this.iframe.src = url
    this.iframe.height = 600;
    this.iframe.width = '100%'
    this.iframe.sandbox = 'allow-forms allow-modals allow-popups-to-escape-sandbox allow-popups allow-scripts allow-top-navigation allow-same-origin'
    this.iframe.setAttribute("onLoad", `Packs.application.${this.className()}.iframeGotNewUrl(this);`);
    this.iframe.id = this.processCode
    $(this.ui).find(this.iframeId).html(this.iframe)
    try {
      this.delegate.iframeWasCreated(this.iframe)
    } catch (error) {}
  }

  checkUrl(e) {
    // console.log('checkUrl');
    // console.log($(e));
    // console.log($(e).data('checkStatus'));
    e.contentWindow.postMessage("your message here", "*");

    // try {

    //   if( e.contentWindow.location.href == this.returnUrl ) {
    //     // payment_urls/:id/check_status
    //     // this.checkUrlRequest();
    //   }
    // } catch (error) {

    //   console.log(error);
    // }
  }

  static iframeGotNewUrl(iframe) {
    const instance = _.find(this.pool, (it) => { return it.processCode == iframe.id })
    instance.checkUrl(iframe)
  }

  setDelegate(delegate) { this.delegate = delegate }
  className() { return this.ui.getAttribute('context') }


  checkUrlRequest(){
    $.ajax({
      type: 'GET',
      url: `/payment_urls/${this.processCode}/check_status`
    }).done((data) => {
      this.refId = data.ref_id
      this.creditCard = data.card
        try {
          this.delegate.paymentWasAuthorized(this.refId, this.creditCard)
        } catch (error) {}
    }).fail((data) => {
      try {
        this.delegate.paymentFailed(data.responseJSON.error)
      } catch (error) {}
    })

  }
}