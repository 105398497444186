export class EditUserView {

  constructor(options) {
    this.user = options.user;
    (this.user.role == 7) ? this.show_shops() :  this.hide_shops();
    this.initBinding();
  }

  initBinding() {
    var _this = this;
    $("#user_role").on("change", function(e){
      ($(e.currentTarget).val() == 7) ? _this.show_shops() : _this.hide_shops()
    });

    $("#shop-list").on('click', ".remove", function(e){
      var shopId = $(e.currentTarget).parent().attr("id").split("-").pop();
      var shopIds =  JSON.parse($("#user_shop_ids").val());
      shopIds =  _.reject(shopIds, function(num){ return num == shopId});
      $('#shop-list').find("#manager-shop-"+shopId).remove();
      $("#user_shop_ids").attr("value", JSON.stringify(shopIds) );
    });

    $('#users-search-txt').autocomplete({
      source: function(request, response) {
        $.ajax({
          dataType: 'json',
          data: { term: request.term },
          url: '/moderator/search_shops',
          success: function(data, s, xhr) {
            var data = xhr.responseJSON["shops"];
            response($.map(data, function(value, key) {
            return { label: value.username, value: value.id }
            }));
          }
        });
      },
      appendTo: '#users-search-results',
      select: function(e, ui) {
        var current = JSON.parse($("#user_shop_ids").val());
        current.push(parseInt(ui.item.value));
        $("#user_shop_ids").val(JSON.stringify(current));
        _this.append_shop(ui.item);
      }
    });
  }

  append_shop(item){
    $('#shop-list').append(
    " <li  id='manager-shop-"+ item.value + "'>" +
    "<i class='fas glyphicon glyphicon-remove text-info mx-2 remove'></i>" + item.label + "</li>");
  }

  show_shops(){
    $('.users-search').show();
    $('.shops').show();
  }

   hide_shops(){
     $('.users-search').hide();
     $('.shops').hide();
   }

}


