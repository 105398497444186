var GoogleMap = function(options){
  options = options || {};
  this.lat =  parseFloat(options.lat || 0);
  this.lng = parseFloat(options.lng || 0);
  this.radius = parseInt(options.radius || 0);
  this.marker = this.generate_marker(0,0);
  this.markers = [];
  this.circle;
  this.latitude_box = (!options.hasOwnProperty("latitude_box")) ? $('#branch_latitude') : options.latitude_box;
  this.longitude_box = (!options.hasOwnProperty("longitude_box")) ? $('#branch_longitude') : options.longitude_box;
  this.elementId = options.elementId || "map-canvas";
  this.after_on_click_listener = options.after_on_click_listener || function(){};
  this.zoomLevel = options.zoomLevel || 13;
  this.mapTypeId = options.mapTypeId || google.maps.MapTypeId.ROADMAP;
  this.mapOptions = {
    center: new google.maps.LatLng(this.lat, this.lng),
    zoom: this.zoomLevel,
    mapTypeId: this.mapTypeId,
    mapTypeControl: true
  };

  this.map = new google.maps.Map(document.getElementById(this.elementId), this.mapOptions);
  this.click_listener = options.click_listener || function(event, marker){
    this.lat = event.latLng.lat();
    this.lng = event.latLng.lng();
    this.marker.setPosition(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
    this.latitude_box.val(event.latLng.lat());
    this.longitude_box.val(event.latLng.lng());
    this.pin_marker_to_current_map(this.marker);
    this.after_on_click_listener(event, marker);
    // this.draw_circle(this.radius);
  }.bind(this);

  (this.lat != 0 && this.lng != 0) ? _pin_region_location(this) : _pin_current_location(this);
  this.set_click_listener(this.click_listener);

  this.circle = new google.maps.Circle({
    strokeColor: '#008000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#008000',
    fillOpacity: 0.35,
    map: this.map,
    center: {lat: this.lat, lng: this.lng},
    radius: this.radius
  });

}

GoogleMap.prototype.set_map = function(state, city, edit_location){
}

GoogleMap.prototype.reset_click_lisener = function(state, city, edit_location){
}

GoogleMap.prototype.draw_circle = function(radius){
  this.radius = radius;
  this.circle.setRadius(radius);
  this.circle.setCenter({lat: this.lat, lng: this.lng});
  

  return this.circle;
};

GoogleMap.prototype.draw_custom_circle = function(radius,lat,lng){
  var myLatlng = new google.maps.LatLng(parseFloat(lat),parseFloat(lng));
  var circle = new google.maps.Circle({
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    clickable: false,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    map: this.map,
    center:myLatlng,
    radius: radius
  });
};

GoogleMap.prototype.set_click_listener = function(callback){
  google.maps.event.addListener(this.map, 'click', function(event){
    callback(event, this.marker);
  }.bind(this));
};

GoogleMap.prototype.move_camera_to_location = function(lat, lng){
  this.map.panTo(new google.maps.LatLng(lat, lng));
};

GoogleMap.prototype.move_camera_to_marker = function(marker){
  this.move_camera_to_location(marker.position.lat(), marker.position.lng());
};

GoogleMap.prototype.generate_marker = function(lat, lng){
  return new google.maps.Marker({position: new google.maps.LatLng(lat, lng)});
};

GoogleMap.prototype.generate_restaurant_marker = function(lat, lng, shop_thumb){
  return new google.maps.Marker(
    {
      position: new google.maps.LatLng(lat, lng),
      icon: shop_thumb
    }
  );
};

GoogleMap.prototype.pin_marker_to_current_map = function(marker){
  marker.setMap(this.map);
  this.markers.push(marker);
};

GoogleMap.prototype.delete_markers = function(){
  for (var i = 0; i < this.markers.length; i++) {
    this.markers[i].setMap(null);
  }
};

GoogleMap.prototype.reload = function(){
  this.map = new google.maps.Map(document.getElementById(this.elementId), this.mapOptions);
  this.move_camera_to_location(this.lat, this.lng);
}

function _set_defualt_location(address, _this){
  var geocoder = new google.maps.Geocoder();
  geocoder.geocode({
    'address': address
  },
  function(result, status){
    if (status == google.maps.GeocoderStatus.OK){
      _this.map.setCenter(result[0].geometry.location);
    }else{
      alert("Geocode was not successful for the following reason: " + status);
    }
  });
};

function _pin_region_location(_this){
  _this.marker = _this.generate_marker(_this.lat, _this.lng);
  _this.marker.setMap(_this.map);
  _this.move_camera_to_location(_this.lat, _this.lng);
};

function _pin_current_location(_this){
  var pinCurrentLocation = function(location){
    _this.marker = _this.generate_marker(location.coords.latitude, location.coords.longitude);
    _this.marker.setMap(_this.map);
    _this.move_camera_to_location(location.coords.latitude, location.coords.longitude);
  };

  var errorCallback = function(){
    _set_defualt_location(_get_address('Dubai', 'UE'), _this);
  };

  navigator.geolocation.getCurrentPosition(pinCurrentLocation, errorCallback);
};

function _get_address(state, city){
  return (state == "") ? city : state + ',' + city;
}

export{GoogleMap}