import { ApplePaySession } from "../utilits/apple_pay_session";

import applePayLogo from '../../images/apple-pay-LogoSvg.png';
import creditCardLogo from '../../images/credit-card-empty.png';

const paymentMethodTemp = require("../templates/web_payment_methods.ejs");

const SALE_REQUEST_ELEMENT  = '#sale-request-btn';
const CURBSIDE_FROM_ONLINE_PAYMENT_ELEMENT = "#curbside-from-online-payment";
const APPLE_PAY_BTN_ELEMENT = 'apple-pay-button';
const AUTH_REQUEST_ELEMENT = '.auth-request-btn';
const IN_USE_CREDIT_CARD_INFO = '.in-use-credit-card';
const PAY_FROM_ONLINE_SALES_NOTE = '.pay-from-online-sales-note';

const SAVE_CREDIT_CARD_AUTH_REQUEST_ELEMENT = '#save_credit_card';
const HOSTED_PAYMENT_PAGE_FORM_ELEMENT = '#get-hosted-payment-page-form';
const CHANGE_CREDIT_CARD_ELEMENT = '.auth-request-btn-inside-credit-card';

export class AdvPaymentMethodViews {

  constructor(options){


    $('#loadingDiv').hide();
    this.shop_adv_sales_request_path = options.shop_adv_sales_request_path;
    this.user_has_saved_credit_card = options.shop_in_use_credit_card;

    
    this.branch_id = options.branch_id;
    this.plan_id = options.plan_id;
    this.country_code = options.country_code;
    this.currency_code = options.currency_code;
    this.payment_amount = options.payment_amount;
    this.user_payment_options  = [];
    this.applePaySaleRequestPath = options.applePaySaleRequestPath;
    this.curbside_pay_from_online_payment_sales_request_path = options.curbside_pay_from_online_payment_sales_request_path;

    this.credit_card_payment_present = JSON.parse(options.credit_card_payment_present);
    this.curbside_plans_payment_methods_from_online_payment_sales_present = JSON.parse(options.curbside_plans_payment_methods_from_online_payment_sales_present);
    this.apple_pay_present = window.ApplePaySession && JSON.parse(options.apple_pay_present) ;

    var paymentEvents = {
      "payFromNewCard": this.payFormNewCard,
      "payFromSavedCard": this.user_has_saved_credit_card ? this.payFromSavedCard : this.payFormNewCard,
      "payFromApplePay": this.payFromApplePay,
      "curbsidePayFromOnlinePaymentSales": this.curbsidePayFromOnlinePaymentSales,
    }

    this.findUserPaymentOptions(options.form_authenticity_token ,function(){
      this.selectDefaultPaymentMethod(paymentEvents);
    }.bind(this));
    
    $(CHANGE_CREDIT_CARD_ELEMENT).on('click', function(e){
      e.stopPropagation();
      $('#loadingDiv').show();
      this.payFormNewCard();
    }.bind(this));

    $('.confirm-sale-payment').on('click', function(){
      $('#loadingDiv').show();
      $('#confitmation-dialog').hide();
      $(IN_USE_CREDIT_CARD_INFO).find('form').trigger('submit');
    });

    $('.cancel-sale-payment').on('click', function(){
      $('#confitmation-dialog').hide();
    });

    $(SALE_REQUEST_ELEMENT).on('click', function(){
      $('#confitmation-dialog').show();
    });

    $(SAVE_CREDIT_CARD_AUTH_REQUEST_ELEMENT).on('change', function()  {
      this.payFormNewCard();
    }.bind(this));

    $(CURBSIDE_FROM_ONLINE_PAYMENT_ELEMENT).on('click', function(){
      this.sendCurbsideFromOnlinePaymentRequest();
    }.bind(this));

    $('.payment-methods').on('click', '.payment-option',function (e) {
      paymentEvents[$(e.currentTarget).data().paymentMethod].bind(this)();
    }.bind(this));
  }

  findUserPaymentOptions(form_authenticity_token, cb){
    this.user_payment_options = []
    
    if (this.credit_card_payment_present){ 
      this.user_payment_options.push({ name: "Credit Card", icon: creditCardLogo, value: 'credit-card', on_select_event: "payFromSavedCard"})
     }
    if (this.curbside_plans_payment_methods_from_online_payment_sales_present) {this.user_payment_options.push({name: "From Online Payment Sales", icon: creditCardLogo, value: 'curbsidePayFromOnlinePaymentSales', on_select_event: "curbsidePayFromOnlinePaymentSales"})}

    if (this.apple_pay_present) {
      this.user_payment_options.push({name: "Apple Pay", icon: applePayLogo, value: 'apple-pay', on_select_event: "payFromApplePay"})  
      $(APPLE_PAY_BTN_ELEMENT).css('display', 'initial');
      $(APPLE_PAY_BTN_ELEMENT).on('click', this.onApplePayButtonClicked.bind(this));
    }
    
    $(".payment-methods").append(paymentMethodTemp({form_authenticity_token: form_authenticity_token, web_payment_methods: this.user_payment_options, shop_adv_sales_request_path: this.shop_adv_sales_request_path, shop_in_use_credit_card:  this.user_has_saved_credit_card}));
    cb();
  }

  selectDefaultPaymentMethod(paymentEvents){
     if(this.apple_pay_present == true) {  return paymentEvents["payFromApplePay"].bind(this)();} 
     if(this.user_has_saved_credit_card != undefined && this.credit_card_payment_present == true ){ return paymentEvents["payFromSavedCard"].bind(this)(); }
     
     if(this.user_has_saved_credit_card == undefined && this.credit_card_payment_present == true ){
      this.payFormNewCard();
      return ;
     }

     if(this.curbside_plans_payment_methods_from_online_payment_sales_present ){ return paymentEvents["curbsidePayFromOnlinePaymentSales"].bind(this)(); }
  }

  payFromSavedCard(){
    this._select_as_active($('.payment-option').find("input[name=payment_method][value=credit-card]").parents('.payment-option'))
    $(IN_USE_CREDIT_CARD_INFO).collapse('show');
    $(SALE_REQUEST_ELEMENT).show();

    $(AUTH_REQUEST_ELEMENT).hide();
    $(CURBSIDE_FROM_ONLINE_PAYMENT_ELEMENT).hide();
    $(APPLE_PAY_BTN_ELEMENT).css('display', 'none');

    $('#telr-ifream').attr('src', '');
    $(CHANGE_CREDIT_CARD_ELEMENT).show(); // change credit card 
    $(HOSTED_PAYMENT_PAGE_FORM_ELEMENT).show();

  }

  payFromApplePay(){
    this._select_as_active($('.payment-option').find("input[name=payment_method][value=apple-pay]").parents('.payment-option'))
  
    $(APPLE_PAY_BTN_ELEMENT).css('display', 'initial');
    $(CURBSIDE_FROM_ONLINE_PAYMENT_ELEMENT).hide();
    this._hideOnlinePaymentElements();

    // $(IN_USE_CREDIT_CARD_INFO).collapse('hide');
    // $(SALE_REQUEST_ELEMENT).hide();
    // $(AUTH_REQUEST_ELEMENT).hide();
    // $(CURBSIDE_FROM_ONLINE_PAYMENT_ELEMENT).hide();
    // $(APPLE_PAY_BTN_ELEMENT).css('display', 'initial');
    // $(HOSTED_PAYMENT_PAGE_FORM_ELEMENT).hide();
    // $('#telr-ifream').attr('src', '');
  }

  payFormNewCard(){
    this._select_as_active($('.payment-option').find("input[name=payment_method][value=credit-card]").parents('.payment-option'))
    $('#loadingDiv').show();
    $(SALE_REQUEST_ELEMENT).hide();
    $(APPLE_PAY_BTN_ELEMENT).css('display', 'none');
    $(AUTH_REQUEST_ELEMENT).hide();
    $(CURBSIDE_FROM_ONLINE_PAYMENT_ELEMENT).hide();
    $('#telr-ifream').attr('src', '');
    this.user_has_saved_credit_card ?  $(CHANGE_CREDIT_CARD_ELEMENT).show()  : $(CHANGE_CREDIT_CARD_ELEMENT).hide();
    // (this.apple_pay_present) ? $(AUTH_REQUEST_ELEMENT).show() : $(AUTH_REQUEST_ELEMENT).trigger('click')
    $(HOSTED_PAYMENT_PAGE_FORM_ELEMENT).show();
    $(HOSTED_PAYMENT_PAGE_FORM_ELEMENT).trigger('submit');      

  }

  curbsidePayFromOnlinePaymentSales(){
    this._select_as_active($('.payment-option').find("input[name=payment_method][value=curbsidePayFromOnlinePaymentSales]").parents('.payment-option'))
    $(CURBSIDE_FROM_ONLINE_PAYMENT_ELEMENT).show();
    $(APPLE_PAY_BTN_ELEMENT).css('display', 'none');
    $(PAY_FROM_ONLINE_SALES_NOTE).collapse('show');
    this._hideOnlinePaymentElements();
  }

  _select_as_active(e){
    $('.payment-methods').find('.active').toggleClass('active');
    $(e).addClass('active');
    $(e).find('input[name=payment_method]').prop('checked', true);
  }

  onApplePayButtonClicked() {
    // alert('onApplePayButtonClicked');
    var _this = this;
    new ApplePaySession({
      country_code: this.country_code,
      currency_code: this.currency_code, 
      payment_amount: this.payment_amount,
      onPaymentAuthorized: function(applePayToken){
        // alert('onPaymentAuthorized')
        $('.results').append("<p> onPaymentAuthorized get captured  </p>")
        this.onPaymentAuthorized(_this, applePayToken)
     }.bind(this)
    }).createSession();
  }

  onPaymentAuthorized(_this, applePayTOken){
    // alert('send apple pay request path')
    $.ajax({
      url: this.applePaySaleRequestPath,
      method: 'post',
      data: {apple_pay: {apple_pay_token: JSON.stringify(applePayTOken), total: this.payment_amount} },
      dataType: "JSON",
      success: function(data, status, xhr){
        alert('apple pay request sucess ')
        $('.results').append(`<li>captured success</li>`);
      },
      error: function(status, errors){
        // alert('apple pay request errors ');
        $('.results').append(`<li>captured faild ${errors}</li>`);
      }
    });
  }

  sendCurbsideFromOnlinePaymentRequest(){
    $.ajax({
      url: this.curbside_pay_from_online_payment_sales_request_path,
      method: 'post',
    });
  }

  _hideOnlinePaymentElements(){
    $(IN_USE_CREDIT_CARD_INFO).collapse('hide');
    $(SALE_REQUEST_ELEMENT).hide();
    $(AUTH_REQUEST_ELEMENT).hide();
    $(HOSTED_PAYMENT_PAGE_FORM_ELEMENT).hide();
    $('#telr-ifream').attr('src', '');
  }

}