export class Branch{
  constructor(options = {}){
    
    this.id = options.id
    this.name = options.name;
    this.display_name = options.display_name;
    this.latitude = options.latitude;
    this.longitude = options.longitude;
    this.phone_number = options.phone_number;
    this.parking_latitude = options.parking_latitude || options.latitude;
    this.parking_longitude = options.parking_longitude || options.longitude;
    this.tax_type = options.tax_type;
    this.daily_order_count = options.daily_order_count;
    this.preparation_time = options.preparation_time;
    this.on_hours_attributes = options.on_hours_attributes;
    this.branch_template_id = options.branch_template_id;
    this.place_id = options.place_id;
    this.ipad_exist = options.ipad_exist;
    this.init();
  }

  attributes(){
    return {
      "name": this.name, 
      "display_name": this.display_name,
      "latitude": this.latitude ,
      "longitude": this.longitude ,
      "phone_number": this.phone_number,
      "parking_latitude": this.parking_latitude,
      "parking_longitude": this.parking_longitude,
      "tax_type": this.tax_type,
      "preparation_time": this.preparation_time,
      "place_id": this.place_id,
      "daily_order_count": this.daily_order_count
    }
  }

  init(){
    localStorage.removeItem("branch");
    localStorage.setItem("branch", JSON.stringify(this));
  }

  valid() {
    return (this.latitude && this.longitude && this.display_name) ? true : false
  }

  destroy(){
    localStorage.removeItem("branch");
  }

  getBranch(){
    localStorage.getItem("branch");
  }

  updateName(name){
    this.name = name;
    this.save_update();
  }

  updateLocation(lat, lng){
    this.latitude = lat; 
    this.longitude = lng;
    this.save_update();
  }

  updateParkingLocation(lat, lng){
    this.parking_latitude = lat;
    this.parking_longitude = lng;
    this.save_update();
  }

  updateDisplayName(display_name){
    this.display_name = display_name
    this.save_update();
  }

  updateName(area_name){
    this.name = area_name;
    this.save_update();
  }

  updatePhoneNumber(phone_number){
    this.phone_number = phone_number;
    this.save_update();
  }

  updateIpadExist(value){
    this.ipad_exist = value
  }

  save_update(){
    localStorage.setItem("branch", JSON.stringify(this));
  }

  location_present(){
    return (this.latitude || this.longitude) ? true : false
  }

  name_present(){
    return (this.name) ? true : false
  }

  display_name_present(){
    return (this.display_name) ? true : false
  }

  error_messages(){
    var errors  = [];
    if (!this.name_present() || !this.display_name_present()) errors.push("Add your shop name<br/> اضف اسم نشاطك التجاري") 
    if (!this.location_present()) errors.push("select your branch location on the map وحدد موقع فرعك على الخريطة"); 
    return errors;
  }

  append_validation_errors(form_key, form_errors){
    $('.form-group').children('.invalid-feedback').remove();
    _.mapObject(form_errors, function (errors, key) {
      _.each(errors, function (error) {
        $('#' + form_key + '_' + key).addClass('is-invalid');
        if (!_.isEmpty(error)) {
          $('#'+form_key+'_' + key).parents('.form-group').find('.errors-con').find('.invalid-feedback').append("(" + error + "" + ")");
        }
      });
    });
  }

  append_as_html_hidden_files(element){
    _.each(Object.entries(this.attributes()), function(array){
      var key = array[0]
      var value = array[1]
      element.append("<input value='" + value + "'" +
        "type='hidden' name=user[branches_attributes][][" + key+ "] "+ 
        "id=user_branches_attributes[]["+key+"]>")
    });

    element.append("<input value='" + JSON.stringify(this.on_hours_attributes) + "'" +
      "type='hidden' name=on_hours id=branch-on_hours>");

    element.append("<input value='" +  this.branch_template_id + "'" +
      "type='hidden' name=branch_template_id id=branch_template_id>");
  }
}