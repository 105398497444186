const predictionsEmulationTemplate = require("../templates/predictions_emulation") 

export class PredictionTestsView{
  constructor(options){
    this.initListeners();
  }

  initListeners(){
    var _this = this;
    console.log(this);

    var geo_chart = function(){
      console.log(this);
      console.log(_this);
      _this.google_map = new Packs.application.GoogleMap({latitude_box: $("#latitude"),longitude_box: $('#longitude')});
      _this.google_map.set_map('Dubai', 'UE');
      _this.google_map.move_camera_to_location(25.201486373250898, 55.24091555277573);
      _this.get_branches(_this);
      $(".search_prediction").on('click', function(){
        this.get_prediction_test_in_region(this);
      }.bind(_this));
    }.bind(this)
  
    var geo_chart =  new geo_chart();
    this.get_prediction_test_in_region(_this);
    this.get_branches(_this);
  }

  get_prediction_test_in_region(_this){
    $.ajax({
           type: 'GET',
           url: '/admins/prediction_tests',
           data: {
             'predictions[username]': $('#username').val(),
             'predictions[city_name]': $('#city_name').val(),
             'predictions[latitude]': $("#latitude").val(),
             'predictions[longitude]':$('#longitude').val(),
             'predictions[time]':$('#time').val()
           },
           dataType: "json",
           success: function(data, textStatus, xhr){
             console.log(data);
             var result = predictionsEmulationTemplate({predictions: data.predictions});
             $('#result').html(result);
    
           }
       });
  }
 
  get_branches(_this){
     $.ajax({
           type: 'GET',
           url: '/admins/prediction_branches',
           data: {
           },
           dataType: "json",
           success: function(data,textStatus,xhr){
             _.each(xhr.responseJSON.branches,function(branch){
               var marker = _this.google_map.generate_restaurant_marker(branch.latitude,branch.longitude, xhr.responseJSON.info[branch.id]["logo"]);
               _this.google_map.pin_marker_to_current_map(marker);
             });
           }
       });
  }
}