import { Branch } from "./branch";
import { Item } from "./item";
import { Model } from "./model";

export class Order extends Model {
  // id; total; originalTotal; discounted; vat; branchId; currency; preOrder; items; branch;

  constructor(options) {
    console.log(options);
    super(options)
    this.items = options.items.map((item) => { return new Item(item) })
    this.branch = new Branch(options.shop.branch)
  }
}