require('async')
import "bootstrap-maxlength";
import { ActiveCategoryTab } from "../utilits/active_category_tab";
import { MenuItemView, ImageFile } from "../utilits/menu_item";
import * as globalFun from '../utilits/drivu_global_functions'

var toastr = require('toastr');
const menuItemTemp = require('../templates/item.ejs')

export class BranchMenuView { 

  constructor(options){
    this.category_items = options.category_items;
    this.categories = options.categories;
    this.branch_id = options.branch_id;
    this.reservation_id = options.reservation_id;
    this.categories_url = options.categories_url;
    this.menu_item_update_base_url = options.menu_item_update_base_url;
    this.extras_path = options.extras_path;
    this.branch_url = options.branch_url;
    this.menuItemAttributes = options.menuItemAttributes;
    this.ready_to_go_live_path = options.ready_to_go_live_path;
    this.setItems(options.items);
    this.init_binding();
  }

  init_binding(){

    var _this = this;

    if ($('#category_tabs li').length == 1) {
      $('.special-tab-add-category a').trigger('click');
    }

    $(".special-tab-add-category").on("a", "click", function () {
      $(".branch_menu .form-control:input").trigger('focus');
    });
    
    this.on_move_to_other_category(this); // move to other category
    this.on_change_category_active(this); // category activation switch
    this.on_delete_category(this);
    this.on_item_extras_click(this);
    this.bindCategorySortable();
    this.bindOnElementInserted(this);
    this.bindItemSortable(this);
    this.bindActiveCategoryTab();
    this.OnChangeCategoryLoyalty(this);
  
    $('#ready-to-review').on('click', function (e) {
      if(! _this.items.length > 0){
        toastr.error("Add  items to go live")
        return false;
      }
      $.ajax({
        url: _this.ready_to_go_live_path,
        method: "PUT",
        data: {
          "branch[ready_to_review]": true,
          "branch_id": _this.branch_id
        },
        dataType: "JSON",
        success: function (response, status, ) {
          window.location.replace(response.redirect_url)
        },
        error: function(xhr, status, errors){
        }
      });
    });

    $('#chat-with-us').on('click', function (e) {
      window.open("https://wa.me/97180078888");
    });


    $('#add-item-in-active-category').on('click', function(){
      if(_this.categories.length <= 0 ){ 
        toastr.error("Add Category"); 
        return false;
      }
      _this.triggerAddItemOnActiveCategory();
    });

    $('.item-description').maxlength({
      alwaysShow: true,
      threshold: 140,
      warningClass: "label label-success",
      limitReachedClass: "label label-danger",
      separator: ' of ',
      preText: 'You have ',
      postText: ' chars remaining.',
      validate: true,
    });
  
    $('.new_category_form').on('ajax:success', function(e,response){
      var name = response.category.name.replace(/[^0-9A-Za-zا-ي\-]/g, '');
      location.hash = `#prefix-${name}`;
      localStorage.setItem('activeTab', `#prefix-${name}`);
      localStorage.setItem('shouldAddItem', true);
      location.reload();
    })
  
    $('.new_category_form').on('ajax:error', function(e, xhr, status, error){
      var errors = xhr.responseJSON.errors;
      var html = _.map(errors, function(err){ return `<span class="badge bg-danger">${err}</span>`; });
      $('#new-category-errors').html(html);
    });


    $(document).on("keypress", function(event){ 
      if (event.keyCode == 13) {
        $('.save-all-items').trigger('click');
        event.preventDefault();
      }
    });

    $('#newCategoryModal').on('shown.bs.modal', function () {
      $('#category_name').focus();
    });
  
    $('.save-all-items').on('click', function(e){
      globalFun.showLoadingWheel();
      var updatedItems = _.filter(_this.items, function(item){ return item.checkChangedFields()});
      async.waterfall(_.map(updatedItems, function(item){ 
        item.setReservationId(_this.reservation_id);
        return item.submit.bind(item) 
      }), function(){ $('.alert.alert-danger.unsaved_changes').hide(); globalFun.hideLoadingWheel(); })
      
      e.preventDefault();
    });
  
    $('#newCategoryModal').on('shown.bs.modal', function () {
      $('#category_name').focus();
    });

    $('.category-update').on('click',function(){
      var category_name = $(this).closest('.category-container').find('.category-name').val();
      var category_id = $(this).data('category');
      var url = `${_this.categories_url}${category_id}.json`;
      $.ajax({
        url: url,
        method: 'PUT',
        data: {
          'category[name]': category_name,
          'reservation_id': _this.reservation_id,
          'update_by_reference': $('#update_by_reference').is(":checked")
        },
        success: function(){ 
          $('.nav-link.active').html(category_name);
          $(this).hide();
          toastr.success('Category Updated');
        }.bind($(this)),
        error: function(res){
          console.log(res);

          //toastr.error(res.responseJSON.join('/n'))
        }
      });
  
      return false;
    });

    $('.category-name').on("keyup", function(e){
      $(this).closest('.category-container').find('.category-update').show();
    });

    var shouldAddItem = localStorage.getItem('shouldAddItem');

    setTimeout(() => {
      if($('.nav-link.active').length == 0) {
        $('.nav-link').first().trigger('click')
      }
      if(shouldAddItem) {
        $('#add-item-in-active-category').trigger('click');

        localStorage.removeItem('shouldAddItem')
      }
      $( "#loadingDiv" ).remove();
    }, 500);    
  }

  bindCategorySortable(){
    
    $('*[data-role=activerecord_sortable]').sortable({
      items: " > li",
      stop: function(event, ui){
        var items = $(this).children("li");
        var position = items.index(ui.item);
        $.ajax({
          url: ui.item.attr("data-move-url"),
          data: {
            position: position,
            update_by_reference: $('#update_by_reference').is(":checked")
          },
          method: "POST",
          dataType: "JSON"
        });
      }   
    });
  }

  bindItemSortable(_this){
    $('*[data-role=items-activerecord-sortable]').sortable({
      items: " > .sortable-item",
      stop: function(event, ui){
      var items = $(event.target).children(".sortable-item");
      var position = items.index(ui.item);
      $.ajax({
        url: ui.item.find('.menu-item-new').attr("data-move-url"),
        data: {position: position},
        method: "POST",
        dataType: "JSON"
      });
      }   
    });
  }

  bindActiveCategoryTab(){
    new ActiveCategoryTab({nav_tab_element_id: 'category_tabs'});
  }

  bindOnElementInserted(_this){
    globalFun.onElementInserted('body', '.menu-item-new', function(element) {
      var menuItem = _this.menuItemAttributes //JSON.parse('<%= j MenuItem.new(branch_id: params[:branch_id]).to_json.html_safe%>');
      var item = new MenuItemView(menuItem);
      item.category_id = $('.nav-link.active').parent().attr('id').split('-').pop();
      item.isNewRecord = true;
      item.setReservationId(_this.reservation_id);
      item.setElement($(element));
      $(element).find('.item-name').trigger("focus");
      $(element).get(0).scrollIntoView();
      _this.items.push(item);
    });
  }

  triggerAddItemOnActiveCategory(){
    $('.unsaved_changes').show();
    var menu_item = menuItemTemp({categories: this.categories} );
    $($('.nav-link.active').attr('href')).find('.items-container').append(menu_item);
  }

  readURL(input) {
    var $siblings, reader;
    $siblings = $(input).siblings('.image_to_upload');
    if (input.files && input.files[0]) {
      reader = new FileReader();
    }
    reader.onload = function(e) {
      var $swap;
      if ($('.image_to_upload').length === 1) {
        $('.image_to_upload').attr('src', e.target.result).removeClass('hidden');
      } else {
        $siblings.attr('src', e.target.result).removeClass('hidden');
      }
      $swap = $('.swap');
      if ($swap) {
        return $swap.removeClass('hidden');
      }
    };
    return reader.readAsDataURL(input.files[0]);
  };

  displayUnsavedMessage() {
    return $('.unsaved_changes').show();
  };

  // toDo change reload page after change
  on_move_to_other_category(_this){
    $('.switch-branch').on('change', function () {
      var category_id = $(this).val();
      var menu_item_id = $(this).attr("id").split("_").pop();
      if (category_id) {
        $.ajax({
          type: 'PUT',
          url: _this.menu_item_update_base_url + menu_item_id,
          data: { "menu_item[category_id]": category_id, reservation_id: _this.reservation_id},
          success: function (data) {
            location.reload();
          }
        });
      }
    });
  }

  on_change_category_active(_this){
    $(".category-switch").on('change', function () {
      var id = $(this).data("categoryId");
      $.ajax({
        type: 'PUT',
        url: _this.categories_url + id,
        dataType: "JSON",
        data: {
          "category[active]": $(this).is(":checked"),
          "reservation_id":  _this.reservation_id,
          "update_by_reference": $('#update_by_reference').is(":checked"),
        },
        success: function(data, status, xhr) {
          toastr.success('Updated');
        },
        error: function() {
          toastr.error('Failed to update')
        }
      });
    })
  }

  OnChangeCategoryLoyalty(_this){
    $(".category-loyalty-switch").on('change', function () {
      var id = $(this).data("categoryId");
      $.ajax({
        type: 'PUT',
        url: _this.categories_url + id,
        dataType: "JSON",
        data: {
          "category[loyalty]": $(this).is(":checked"),
        },
        success: function(data, status, xhr) {
          toastr.success('Updated');
        },
        error: function() {
          toastr.error('Failed to update')
        }
      });
    })
  }

  //TODO REMOVE RELOAD PAGE AFTER SUCCESS
  on_delete_category(_this){
    $('.delete_category').on('click', function (e) {
      e.preventDefault();
      var confirmed = confirm("Are you sure you want to delete this category?");
      if(!confirmed) return;
      var category_id = $(this).attr("id").split('-').pop();
      $.ajax({
        url: _this.categories_url + category_id,
        type: "delete",
        dataType: "JSON",
        data: {
          "category[archived]":  $(this).is(":checked"),
          "update_by_reference": $('#update_by_reference').is(":checked"),
          "reservation_id": _this.reservation_id
        },
        success(){
          window.location.reload();
        },
        errors(){}
      });
    });
  }

  on_item_extras_click(_this){
    $('.item-extras').on('click', function (e) {
      e.preventDefault();
      var menu_item_id = $(this).attr("id").split('-').pop();
      window.location.href = `${_this.extras_path.replace("add_item_id", menu_item_id)}?reservation_id=${_this.reservation_id}`
    });
  }

  setItems(items){
    this.items = _.map(items, function(menu_item){
      var item = new MenuItemView(menu_item);
      item.setElement($(`#item-element-${item.id}`));
      return item;
    });
  }
}