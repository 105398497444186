var invoice_template = require('../templates/invoice.ejs')
export class InvoiceView{
  constructor(options){
    this.invoice = options.invoice;
    this.trn = options.trn;
    this.address = options.address;
    this.openInvoice();
  }

  openInvoice(){
    $('.invoice-modal').find('.modal-body').html("")
    $('.invoice-modal').find('.modal-body').append(invoice_template({invoice: this.invoice, trn: this.trn, address: this.address, ShowOnlinePaymenTransactions: this.ShowOnlinePaymenTransactions() }) ) ;
    $('.invoice-modal').modal("show")
  }

  ShowOnlinePaymenTransactions(){
    return (this.invoice.old_invoice && (parseFloat(this.invoice.total_credit_card_fees) > 0 ))
  }
}