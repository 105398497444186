import _ from "lodash";
import {  ExtraOptionView } from "./option";
import * as globalFun from '../utilits/drivu_global_functions'
const OptionViewTemp = require("../templates/extra_option");

class Extra{
  constructor(options){
    this.id = options.id;
    this.menu_item_id = options.menu_item_id;
    this.name = options.name;
    this.content_type = options.content_type;
    this.mandatory = options.mandatory;
    this.position = options.position;
    this.active = options.active;
    this.archived = options.archived;
  }
}

export class ExtraView{
  constructor(options){
    this.container = $(options.element);
    this.optionAttributes =  options.optionAttributes;
    this.nameField = $(this.container).find('.extra-name');
    this.contentTypeField = $(this.container).find(".content-type-radio-group");
    this.mandatoryField = $(this.container).find('.mandatory');
    this.activeField = $(this.container).find('.extra-active');
    this.isNewRecord = options.isNewRecord;
    this.shopId = options.shopId;
    this.menuItemId = options.menuItemId;
    this.branchId = options.branchId;
    this.reservationId = options.reservationId
    this.extra = new Extra(options.extra);
    this.optionAttributes = options.optionAttributes;
    this.extraOptionsView = this.setExtraOptionView(options.extraOptions);
    this.isRemoved = false;
    this.bindDeleteExtra();
    this.bindOptionsSortable();
    this.bindOnNewOptionClick();
  }
  
  submit(cb){
    if(this.checkChangedFields()){
      this.submitExtra(cb);
    }else{
      this.waterfallOptionsRequest(cb)
    }
  }

  submitExtra(cb){
    this.hideErrors();
    this.showLoadingWheel();
    $.ajax({
      url: this.isNewRecord ? this.insertUrl() : this.updateUrl(),
      method: this.isNewRecord ? "post" : "put",
      data: this.data(),
      async: true,
      cache: false,
      contentType: false,
      processData: false,
      timeout: 60000,
      success: function(status, data, xhr){
        this.showCheckMark();
        this.hideLoadingWheel();
        this.reload(xhr.responseJSON.extra);
        if(this.optionChanged()){
          this.waterfallOptionsRequest(cb);
        }else{
          cb();
        }
      }.bind(this),
      error: function(xhr, status, errors){
        this.showErrors(xhr.responseJSON.errors);
        this.hideLoadingWheel();
        cb();
      }.bind(this)
    })
  }

  optionsRequestOnly(){
    return !this.checkChangedFields() && this.optionChanged();
  }

  data(){
    var formData = new FormData();
    if(this.nameField.val()) formData.append("extra[name]", this.nameField.val());
    if(this.contentTypeField) formData.append("extra[content_type]", this.contentTypeField.find("input[type='radio']:checked").val());
    if(this.mandatoryField) formData.append("extra[mandatory]", this.mandatoryField.is(':checked'));
    if(this.activeField) formData.append("extra[active]", this.activeField.is(':checked'));
    return formData;
  }

  checkChangedFields(){    
    this.shouldSubmit = (this.isNewRecord || 
                        this.extra.name != this.nameField.val() ||
                        this.extra.content_type != this.contentTypeField.find("input[type='radio']:checked").val() ||
                        this.extra.active != Boolean(this.activeField.is(':checked')) ||
                        this.extra.mandatory != Boolean(this.mandatoryField.is(':checked'))
                      );

     return (this.shouldSubmit && !this.isRemoved)
  }

  optionChanged(){
    return _.filter(this.extraOptionsView, function(optionView){
      return optionView.checkChangedFields()
    }).length > 0
  }

  updateUrl(){
    return `${this.updateBaseUrl}/${this.id}`
  }

  baseInsetUrl(){
    return `/moderator/shop/${this.shopId}/branches/${this.branchId}/menu_items/${this.menuItemId}/extras/`
  }

  insertUrl(){
    return `${this.baseInsetUrl()}${this.queryParams()}`
  }

  updateUrl(){
    return `${this.baseInsetUrl()}${this.extra.id}${this.queryParams()}`
  }

  queryParams(){
    return `?reservation_id=${this.reservation_id}&update_by_reference=${$('#update_by_reference').is(":checked")}`
  }

  bindDeleteExtra(){
    $(this.container).find('.delete_extra').on('click', function (e) {
      e.preventDefault();
      this.isNewRecord ? this.removeRow() : this.remoteDelete();
    }.bind(this));
  }

  reload(extra){
    this.isNewRecord = false;
    this.extra = new Extra(extra);
    this.extraOptionsView  = this.reloadExtraOption();
  }

  waterfallOptionsRequest(cb){
    var updatedOptions = _.filter(this.extraOptionsView, function(optionView){ return optionView.checkChangedFields()});
     async.waterfall(_.map(updatedOptions, function(optionView){ 
        return optionView.submit.bind(optionView);
    }.bind(this)), function(){ 
      globalFun.hideLoadingWheel();
      cb();
    });
  }

  reloadExtraOption(){
    return _.map(this.extraOptionsView, function(optionsView){
      optionsView.extraOption.extra_id = this.extra.id;
      optionsView.extra = this.extra;
      return optionsView
    }.bind(this))
  }

  remoteDelete(){
    var confirmed = confirm("Are you sure you want to delete this extra?");
    if(!confirmed) return;
    $.ajax({
      url: this.updateUrl(),
      type: "delete",
      dataType: "json",
      data: { "update_by_reference": $('#update_by_reference').is(":checked") },
      success: function () { this.removeRow(); }.bind(this),
      error: function () { $(".alert-danger.error").show(); }
    })    
  }

  removeRow(){
    this.container.fadeOut();
    this.isRemoved = true;
  }

  setExtraOptionView(extraOptions){
    return _.map(extraOptions, function(extraOption){
      return new ExtraOptionView({extraOption: extraOption, extra: this.extra, shopId: this.shopId, isNewRecord: false, element: `#option-element-${extraOption.id}` } )
    }.bind(this))
  }

  bindOnNewOptionClick(_this){
    this.container.on('click', '.add-new-option',function(e){
      var uniqId = `option-${new Date().getTime()}`;
      $(e.currentTarget).parents('.extra-new').find('.extra-options').append(OptionViewTemp({extra: this.extra, uniqId: uniqId }));
      var extraOptionView = new ExtraOptionView({extra: this.extra, extraOption: this.optionAttributes ,shopId: this.shopId, isNewRecord: true, element: `#${uniqId}` });
      this.extraOptionsView.push(extraOptionView);
    }.bind(this));

  }

  bindOptionsSortable(){
    console.log(this.container.find('.extra-options') );
    this.container.find('.extra-options').sortable({
      items: " > .option-row",
      start: function(evt, ui) {
        console.log("start event");
      },
      stop: function(event, ui){
        console.log("stop")
        var items = $(this).children(".option-row");
        var position = items.index(ui.item);
        console.log(position);
        $.ajax({
          url: ui.item.attr("data-move-url"),
          data: {position: position},
          method: "POST",
          dataType: "JSON"
        });
      }   
    });
  }

  showErrors(errors){
    this.hideLoadingWheel();
    this.container.find('.errors-container').removeClass('hidden');
    errors.forEach(function(err){
      var element = `<div class="alert alert-danger" role="alert">${err}</div>`;
      this.container.find('.error-list').append(element);
    }.bind(this));
  }


  hideErrors(){
    this.container.find('.error-list').html('');
    this.container.find('.errors-container').addClass('hidden');
  }

  showLoadingWheel(){
    this.hideLoadingWheel()
    this.container.find('.extra-fields-row').find('.circle-loader').show();
    this.container.find('.extra-fields-row').find('.circle-loader').removeClass('load-complete');
    this.container.find('.extra-fields-row').find('.checkmark').hide();
  }

  showCheckMark(){
    this.container.find('.extra-fields-row').find('.circle-loader').addClass('load-complete');
    this.container.find('.extra-fields-row').find('.checkmark').show();
    setTimeout(function(){ this.hideLoadingWheel() }.bind(this), 3000);
  }

  hideLoadingWheel(){
    this.container.find('.extra-fields-row').find('.circle-loader').hide();
    this.container.find('.extra-fields-row').find('.circle-loader').removeClass('load-complete');
    this.container.find('.extra-fields-row').find('.checkmark').hide();
  }

} 