'use strict';
import * as io from 'socket.io-client';

var UserSocketIO = function(user){
  this.connected = false;
  var url = user.socket_url;
  this.client = io(url, {multiplex: false, secure: true});
}

UserSocketIO.prototype.stream_location = function(latitude, longitude, duration, distance, orientation = 0){
  console.log("UserSocketIO");
  console.log(orientation);
  this.client.emit("stream_location", {
    latitude: latitude,
    longitude: longitude,
    duration: duration,
    distance: distance,
    orientation: orientation
  });
};

export {UserSocketIO}