export class ActiveCategoryTab{
  
  constructor(options){
    this.nav_tab_element_id = options.nav_tab_element_id;
    this.onTabChange();
    this.defaultValue();
  }

  onTabChange(){
    var _this = this;
    $(`#${this.nav_tab_element_id} li a`).on('click', function (e) {
      e.preventDefault();
      _this.setActiveTab(this.getAttribute('href'));
      $(this).tab('show');
    });
  }

  setActiveTab(tab_name){
    location.hash = tab_name;
    localStorage.setItem('activeTab', tab_name);
    this.#selectActiveTab();
  }

  activeTab(){
    return localStorage.getItem('activeTab');
  }

  #activeTabPresentInCurrentDom() {
    return $('a[href=\'' + this.activeTab() + '\']').length > 0
  }

  #selectFirstTab() {
    $('.nav-link').first().trigger('click');
  }

  #selectActiveTab() {
    $('a[href=\'' + this.activeTab() + '\']').tab('show');
  }

  defaultValue(){
    this.#activeTabPresentInCurrentDom() ? this.#selectActiveTab() : this.#selectFirstTab() 
  }

}
