module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("    ")
    ;  pickupDaysOfWeek.forEach(function(day){ 
    ; __append("\n      <div class=\"row justify-content-center  align-items-center singleDay my-2 g-0\">\n        <div class=\"col-sm-2 col-3 pr-2\">\n          <div class=\"d-flex d-inline-flex \">\n            <div class=\"form-check form-switch\">\n              <input type=\"checkbox\" id=\"")
    ; __append(escapeFn( `${day.name}-${day.index}-${branch_id}-${type}`))
    ; __append("\"  value=\"\" class=\"dayCheckbox form-check-input\">\n              <label class=\"form-check-label\" for=\"")
    ; __append(escapeFn( `${day.name}-${day.index}-${branch_id}-${type}`))
    ; __append("\" >  </label>\n            </div>\n            <p class=\"capitalize\"> ")
    ; __append(escapeFn( day.name ))
    ; __append("</p>\n          </div>  \n        </div>\n        \n        <div class=\"col-sm-3 col \">\n          <select id='")
    ; __append(escapeFn( `${day.name}-${type}-timeFrom` ))
    ; __append("' class=\"timeFrom form-select\" data-day=\"")
    ; __append(escapeFn( day.name))
    ; __append("\">\n            <option value=\"01:00\" selected>1:00AM</option>\n            <option value=\"02:00\">2:00AM</option>\n            <option value=\"03:00\">3:00AM</option>\n            <option value=\"04:00\">4:00AM</option>\n            <option value=\"05:00\">5:00AM</option>\n            <option value=\"06:00\">6:00AM</option>\n            <option value=\"07:00\">7:00AM</option>\n            <option value=\"08:00\">8:00AM</option>\n            <option value=\"09:00\">9:00AM</option>\n            <option value=\"10:00\">10:00AM</option>\n            <option value=\"11:00\">11:00AM</option>\n            <option value=\"12:00\">12:00PM</option>\n            <option value=\"13:00\">1:00PM</option>\n            <option value=\"14:00\">2:00PM</option>\n            <option value=\"15:00\">3:00PM</option>\n            <option value=\"16:00\">4:00PM</option>\n            <option value=\"17:00\">5:00PM</option>\n            <option value=\"18:00\">6:00PM</option>\n            <option value=\"19:00\">7:00PM</option>\n            <option value=\"20:00\">8:00PM</option>\n            <option value=\"21:00\">9:00PM</option>\n            <option value=\"22:00\">10:00PM</option>\n            <option value=\"23:00\">11:00PM</option>\n            <option value=\"00:00\">12:00AM</option>\n          </select>\n        </div>\n\n        <div class=\"col-sm-3 ps-2 col\"  hidden >\n          <select id='")
    ; __append(escapeFn( `${day.name}-${type}-timeTill` ))
    ; __append("' class=\"timeTill form-select form-control\">\n            <option value=\"01:00\">1:00AM</option>\n            <option value=\"02:00\" selected>2:00AM</option>\n            <option value=\"03:00\">3:00AM</option>\n            <option value=\"04:00\">4:00AM</option>\n            <option value=\"05:00\">5:00AM</option>\n            <option value=\"06:00\">6:00AM</option>\n            <option value=\"07:00\">7:00AM</option>\n            <option value=\"08:00\">8:00AM</option>\n            <option value=\"09:00\">9:00AM</option>\n            <option value=\"10:00\">10:00AM</option>\n            <option value=\"11:00\">11:00AM</option>\n            <option value=\"12:00\">12:00PM</option>\n            <option value=\"13:00\">1:00PM</option>\n            <option value=\"14:00\">2:00PM</option>\n            <option value=\"15:00\">3:00PM</option>\n            <option value=\"16:00\">4:00PM</option>\n            <option value=\"17:00\">5:00PM</option>\n            <option value=\"18:00\">6:00PM</option>\n            <option value=\"19:00\">7:00PM</option>\n            <option value=\"20:00\">8:00PM</option>\n            <option value=\"21:00\">9:00PM</option>\n            <option value=\"22:00\">10:00PM</option>\n            <option value=\"23:00\">11:00PM</option>\n            <option value=\"00:00\">12:00AM</option>\n          </select>\n        </div>\n\n      </div>\n   ")
    ;  })
    ; __append("\n  </div>\n\n  <div class=\"vertical-line position-absolute\"></div>\n</div>")
  }
  return __output;

}