var Dialog = function(){
  this.modal = undefined;
}

Dialog.prototype.hide = function(){
  this.modal.modal('hide');
};

Dialog.prototype.add_errors = function(errors){
  $('.alert-danger').remove();
  var errors_div = '<div class="alert alert-danger" role="alert">'+
            '<ul class="errors"></ul></div>'
  this.modal.find('.modal-content').prepend(errors_div)
  var _this = this;
  _.each(errors,function(error){
    _this.modal.find('.modal-content').find('ul.errors').append('<li>'+ error +'</li>');
  });

};

Dialog.prototype.show = function(){
  this.modal.modal('show');
};

export {Dialog}