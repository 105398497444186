module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  response.branches.forEach(function(branch) {
    ; __append("\n  <div class=\"mt-2 branch\">\n    <div class=\"row\">\n      <div class=\"col\">\n        <div class=\"d-flex \">\n          <div>\n            <img src=")
    ; __append(escapeFn(branch.logo_url ))
    ; __append(" width=\"50\" class=\"circle\"/>\n          </div>\n          <div class=\"ps-2 \">\n            <p class=\"p-0 m-0 branch-title\">\n              ")
    ; __append(escapeFn( branch.shop_name ))
    ; __append("\n            </p>\n            <small class=\"p-0 m-0 small-hint\">\n              <i class=\"fa fa-map-marker\" aria-hidden=\"true\"></i>\n              ")
    ; __append(escapeFn( branch.name ))
    ; __append("\n            </small>\n          </div>\n          <div>\n            <a href=")
    ; __append(escapeFn(`/moderator/shop/${branch.shop_id}/branches/${branch.id}/menu_items`))
    ; __append(" class=\"btn btn-orange strong-rounded-corners\">View Menu </a>\n            <a href=\"/admins/restaurants/")
    ; __append(escapeFn( branch.shop_id ))
    ; __append("/branches/")
    ; __append(escapeFn( branch.id ))
    ; __append("/menu_items?tag_id=")
    ; __append(escapeFn( current_tag_id ))
    ; __append("\" data-remote=\"true\" class= \"btn btn-success strong-rounded-corners edit-menu-btn\">Edit</a>\n          </div>\n        </div>\n      </div>\n    </div>\n\n    <div class=\"row mt-2 justify-content-center\">\n      ")
    ;  branch.items.forEach(function(item){ 
    ; __append("\n        <div class=\"col\">\n          <img src=")
    ; __append(escapeFn(item.image_url ))
    ; __append(" class=\"discover-row-img\" />\n          <div class=\"item-img-desc\">\n            <p>\n              ")
    ; __append(escapeFn( item.name ))
    ; __append("\n            </p>\n            ")
    ; if(item.tag !="" ) { 
    ; __append("\n              <p class=\"discount-label\">\n                ")
    ; __append(escapeFn( item.tag ))
    ; __append("\n              </p>\n              ")
    ;  } 
    ; __append("\n          </div>\n        </div>\n        ")
    ;  })
    ; __append("\n\n          ")
    ;  (3 - branch.items.length).times(function() {
    ; __append("\n            <div class=\"col\"></div>\n            ")
    ;  }) 
    ; __append("\n\n    </div>\n  </div>\n  <hr />\n  ")
    ;  }); 
  }
  return __output;

}