import { Indoor } from "../indoor";
import { Cart } from "../../models/cart"
import { User } from "../user";
import { CustomerLaneOrder } from "../customer_lane_order";
import { OrderSource } from "./order_source";
import { OrderItem } from "../order_item";
import { itemViewRequest } from "../drivu_global_functions";

Indoor.Menu = class {

  nextButton = '#cart-button';
  itemRow = '.item-row'
  logout = '.clear-cart'
  featuredItem = '.try-featured-item'
  constructor(ui) {
    this.branch = JSON.parse(ui.dataset.branch);
    Cart.branch = this.branch;

    Indoor.placeOrderView.branch = this.branch;

    $('.nav-tabs li:first-child a').tab('show');

    $(this.itemRow).on('click', function(e) {
      e.stopPropagation();
      itemViewRequest($(e.currentTarget).attr("id").split('-').pop(), $(e.currentTarget).data('cart-id'))
      // window.location.href = `${window.location.origin}/indoors/menus/${$(e.currentTarget).attr("id").split('-').pop()}/item?q=${$(e.currentTarget).data('cart-id')}`
    }.bind(this));

    $(this.featuredItem).on('click', function(e){
      itemViewRequest($(e.currentTarget).data().itemId);
    }.bind(this));

    this.cartView = new Cart.View(this)
    this.loadNextButton()

    $(this.nextButton).on('click', (e) => { 
      e.stopPropagation();
      User.isLoggedIn() ? this.showPlaceOrder() : this.showLogin()
      Cart.removeActions()
    })
    
    if(Cart.shouldAutoPlaceOrder()) { 
      $(this.nextButton).trigger('click') 
    }
   
    this.onLogout();
  }

  loadNextButton() {
    this.setNextButtonVisibility();
    this.setNextButtonTitle();
  }

  setNextButtonVisibility() { Cart.items().length == 0 ? $(this.nextButton).hide() : $(this.nextButton).show(); }
  setNextButtonTitle() { $(this.nextButton).val(    `Next (${Cart.grandTotal()} ${this.branch.currency_symbol})`); }
  cartWasChanged() { 
    this.loadNextButton()
    this.cartView.setVisibility(); 
  }

  showLogin() { $('#registration-modal').modal('show'); }
  showPlaceOrder() { 
    Indoor.placeOrderView.show(); 
  }

  onLogout() { 
    $(this.logout).on('click', function(){
      Cart.clear();
      localStorage.clear();
    });
  }

  clearStorage(){
    Cart.clear();
    localStorage.clear();
  }
}

Indoor.anonymousUserMenu = class extends Indoor.Menu {
 
  constructor( ui ) {
    super(ui);
    if ( this.orderExpired() ) { this.clearStorage();}
    if ( this.anonymousOrder() && this.orderPresent() ){ this.redirectToOrderPreview(); }
  }

  anonymousOrder(){
    return OrderSource.anonymous();
  }

  orderPresent(){
    return (!_.isEmpty(Indoor.anonymousUserorderDetailsView.getTraceToken()));
  }

  orderExpired(){
    return( this.anonymousOrder() && !this.orderPresent());
  }
}

Indoor.CustomerLane.Menu = class extends Indoor.anonymousUserMenu {
  constructor(ui) {
    super(ui);
  }

  redirectToOrderPreview(){
    Indoor.CustomerLane.OrderDetailsView.getOrderStatus(this.branch.id)
  }

  showPlaceOrder() { 
    Indoor.CustomerLane.PlaceOrderView.show();
  }
}

Indoor.OrderServing.Menu = class extends Indoor.anonymousUserMenu {  

  constructor(ui) {
    super(ui);
  }

  redirectToOrderPreview(){
    Indoor.OrderServing.OrderDetailsView.getOrderStatus(this.branch.id)
  }

  showPlaceOrder() { 
    Indoor.OrderServing.PlaceOrderView.show();

  }
}
