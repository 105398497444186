import _ from "underscore";

class Order {
  constructor(options) {
    this.id = options.id || null;
    this.status = options.status || "";
    this.currency = options.currency;
    this.from_loyalty = options.from_loyalty || false;
    this.branch_id = options.branch_id;
    this.user_id = options.user_id || null;
    this.cash_amount = options.cash_amount || null;
    this.code_number = options.code_number || null;
    this.latitude = options.latitude || new Number(0.0); // get it from map
    this.longitude = options.longitude || new Number(0.0);
    this.notes = options.notes || null;
    this.plate_number = options.plate_number || null;
    this.branch = options.branch || {};
    this.payment_method = options.payment_method;
    this.pickup_status = options.pickup_status;
    this.transportation = options.transportation;
    this.total = options.total || 0;
    this.pickup_time = options.pickup_time;
    this.menu_items_attributes = options.menu_items_attributes || [];
    this.errors = [],
    this.vat = options.vat;
    this.pre_order = options.pre_order || ((_.find(this.menu_items_attributes, function (item) { return item.pre_order; })) != undefined);
    this.items = options.items || [];
    this.user = options.user;
    this.shop = options.shop;
    this.navigation = options.navigation;
    this.userArrived = options.userArrived;
    this.feedbacks = options.feedbacks || [];
  }

  create(draw_branch_location) {
    this.valid();
    if (this.errors.length > 0) {
      // this.append_errors();
      alert(this.errors.join("\n"))
    } else {
      // $('.create-orders-errors').addAttr('hidden');
      // $('.create-orders-errors').find("ul").html("");  
      create_request(this, draw_branch_location);
    }
  }
  create_order_feed_back(msg, after_success_callback) {
    $.ajax({
      url: "/admins/users/" + this.user_id + "/user_orders/" + this.id + "/order_feedbacks",
      method: "POST",
      dataType: "json",
      data: {
        "feedback[reason]": msg
      },
      success: function (data, status, xhr) {
        after_success_callback();
      },
      error: function (xhr, status, error) { }
    });
  }
  valid() {
    if (this.latitude == 0 && this.longitude == 0) this.errors.push("Click anywhere on the map for your order starting point");
    if (this.plate_number == null) this.errors.push("Add Car Plate Number");
    if (this.pre_order && !this.pickup_time) this.errors.push("Select pickup time");
  }
  append_errors() {
    $('.create-orders-errors').find("ul").html("");

    _.each(this.errors, function (error) {
      $('.create-orders-errors').removeAttr('hidden');
      $('.create-orders-errors').find("ul").append(`<li>${error}</li>`);
    });
  }
}

function create_request(_this, after_create_callback){
  $.ajax({
    url: "/admins/users/"+_this.user_id+ "/user_orders",
    method: "POST",
    dataType: "json",
    data: {
      "order[branch_id]" :_this.branch_id,
      "order[user_id]" :_this.user_id,
      "order[cash_amount]":_this.cash_amount,
      "order[plate_number]": _this.plate_number,
      "order[latitude]": _this.latitude,
      "order[longitude]": _this.longitude,
      "order[from_loyalty]": _this.from_loyalty,
      "order[pickup_time]": _this.pre_order ? _this.pickup_time : '',
      "order[menu_items_attributes]": JSON.stringify(_this.menu_items_attributes)
    },
    success: function(data,status,xhr){
      // order =  new Order(xhr.responseJSON.order);
      // order.branch = xhr.responseJSON.order.branch;
      after_create_callback(new Order(xhr.responseJSON.order));
    },
    error: function(xhr,status,error){
      // append_errors(xhr.responseJSON.errors);
    }
  });
}
export { Order }