import { Spinner } from "spin.js";
export class DrivuSpinner{

  static drivuMainSpinner = null; 

  static setDrivuSpinner(){    
    if(!DrivuSpinner.drivuMainSpinner){
      DrivuSpinner.drivuMainSpinner = new Spinner({  
        lines: 12,
        length: 7,
        width: 9,
        radius: 30,
        color: '#000',
        speed: 1,
        trail: 60,
        shadow: true
      });
    }
    return DrivuSpinner.drivuMainSpinner
  }

  static showLoadingWheel(targetElement) {
    var target = targetElement || document.getElementById('sidebar');  
    DrivuSpinner.setDrivuSpinner();
    DrivuSpinner.drivuMainSpinner.spin(target);
  }

  static hideLoadingWheel(){
    DrivuSpinner.drivuMainSpinner.stop();
  }
  
}