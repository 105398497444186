import { Indoor } from "../indoor"

Indoor.OrderPreview = class {

  editButton = '#edit-button'
  collapseButton = '#collapse-order-button'
  vatTitleLabel = '#vat-label'
  vatValueLabel = '#vat-value'

  constructor(ui) {
    this.ui = ui
    this.bindListeners()
    $(this.collapseButton).trigger('click')
  }

  bindListeners() {
    $(this.collapseButton).on('click', () => {
      $(this.collapseButton).find('span.fa').toggleClass('fa-angle-up fa-angle-down')
    })
  }

  loadOrder(order) {
    $(this.vatTitleLabel).html(order.branch.getVatTitle())
    $(this.vatValueLabel).html(`${order.vat} ${order.currency}`)
  }

}