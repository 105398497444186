import { View } from "./view"
import Swal from 'sweetalert2'
export class ContactRequestCell extends View {

  constructor(ui) {
    super(ui)
    
    this.requestId = ui.dataset.id
    this.hideAfterSuccess = ui.dataset.actionHide == "true"

    $(this.callButton).click((e) => {

      window.open(`tel:${e.target.dataset.phone}`, '_self')
      $.ajax({
        type: "POST",
        url: e.target.dataset.url
      })
    })

    $(this.reasonSelector).change((e) => {
      
      var index = e.target.selectedIndex
      if(index == 0) return
      Swal.fire({
        html: require('../templates/contact_request_confirmation_form.ejs')({message: e.target.options[index].text}),
        title: 'Are you sure about the reason?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmChoice(e.target.options[index].value)
        }
      })
    })
  }

  confirmChoice(choiceValue) {
    if(choiceValue < 0) return
    this.notes = $('#extra-notes').val()
    Swal.showLoading()
    $.ajax({
      type: "put",
      url: this.reasonSelector.dataset.url,
      data: {
        "contact_request[reason_ref]": choiceValue,
        "contact_request[notes]": this.notes
      }
    })
  }

  static findByRequestId(id) {
    return this.pool.find((it) => { return it.requestId == id })
  }

  handleReason(code) {
    switch (code) {
      case 200:
        
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Saved successfully thanks',
          showConfirmButton: false,
          timer: 1500
        })

        this.hideAfterSuccess ? this.destroy() : this.updateReason()
        break;
    
      case 201:
        Swal.fire(
          'Cancelled',
          'You should call first',
          'error'
        )
        break;
      default:

        Swal.fire(
          'Cancelled',
          'You can not update this request',
          'error'
        )
        if(this.hideAfterSuccess) this.destroy()
        break;
    }
  }


  handleCall(status) {
    if(status > 299) {
      Swal.fire('This request was reserved', 'error')
      if(this.hideAfterSuccess) this.destroy()
    }
  }

  handleDetach(status) {
    if(status == 200) {
      $(this.reasonField).toggle()
      $(this.reasonSelector).toggle()
      this.reasonSelector.options[0].selected = true
      $(this.detachButton).toggle()
      $(this.agentNameLabel).toggle()
    }
  }

  updateReason() {
    $(this.reasonField).toggle()
    $(this.reasonSelector).toggle()
    this.reasonText.innerText = this.reasonSelector.options[this.reasonSelector.options.selectedIndex].text
    this.notesText.innerText = this.notes
  }
}