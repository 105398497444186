module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"img-container modal fade\" id=\"image-cropper\" tabindex=\"-1\" aria-labelledby=\"exampleModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog modal-lg\">\n      <div class=\"modal-content\">        \n        <div class=\"modal-body\">\n          <div class=\"row\">\n            <div class=\"col-12\">\n                <img id=\"image\" src=\"/images/doorsticker3.jpg\" class=\"mw-100 d-block\" style=\"max-width: 100%;\">\n            </div>\n          </div>\n        </div>\n\n        <div class=\"modal-footer\">\n          <div class=\"col-6\">\n            <div class=\"row\">\n              <div class=\"col-12 actions-buttons\">\n                <button class=\"btn btn-primary\" data-method=\"finish\">Upload</button>\n                <button class=\"btn btn-primary\" data-method=\"rotate\"  data-option=\"-45\" id=\"rotate-left\">rotate left</button>\n                <button class=\"btn btn-primary\" data-method=\"rotate\" data-option=\"45\">rotate right</button>\n              </div>\n            </div>\n          </div>\n        </div>\n\n      </div>\n    </div>\n</div>\n")
  }
  return __output;

}