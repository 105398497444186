export class MapPreview{
  constructor(ui){

    this.ui = ui
    this.latitude = $(ui).data('latitude');
    this.longitude = $(ui).data('longitude');
    this.mapLocation = new google.maps.LatLng(this.latitude, this.longitude);

    $(this.ui).on('click', function() {
      if( !_.isEmpty( String(this.latitude) ) ){
        this.showMapDialog();
      }else{
        alert('User Location not provided')
      }
    }.bind(this));
    
  }

  showMapDialog(){
    $("#dialog").dialog({
      modal: true,
      width: 600,
      hright: 450,
      open: function () {
        var mapOptions = {
          center: this.mapLocation,
          zoom: 19,
          mapTypeId: "hybrid"
        
        };
        var map = new google.maps.Map($("#dvMap")[0], mapOptions);
        new google.maps.Marker({
          position: this.mapLocation,
          map: map
        });
      }.bind(this),
      close: function () {
        $(this).dialog('destroy')
        // var closeBtn = $('.ui-dialog-titlebar-close');
        // closeBtn.html('');
      }
    });

  }

}