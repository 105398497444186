import { InvoiceView } from './invoice_view';

var pagination =  require('pagination');
export class InvoicesView {

  constructor(options){
    this.invoice = options.invoice;
    this.trn = options.trn;
    this.address = options.address;
    this.totalEntries  = options.totalEntries;
    this.perPage = options.perPage;
    this.preLink = options.preLink;
    this.currentPage = options.currentPage;
    this.bindListeners();
    this.addBootstrapPaginator();
  }

  bindListeners(){
    $('.invoice-row').on('click', function(e){
      new InvoiceView({
        invoice: JSON.parse($(e.currentTarget).attr("data")),
        trn: this.trn,
        address: this.address
      });
    }.bind(this))
  }

  addBootstrapPaginator(preLink, currentPage, perPage, totalEntries, append_to_element ){
   var boostrapPaginator =  new pagination.TemplatePaginator({
      prelink: this.preLink , 
      current: this.currentPage, 
      rowsPerPage: this.perPage,
      totalResult: this.totalEntries, 
      slashSeparator: false,
      template: function(result) {
          var i, len, prelink;
          var html = '<div><ul class="pagination">';
          if(result.pageCount < 2) {
              html += '</ul></div>';
              return html;
          }
          prelink = this.preparePreLink(result.prelink);
          console.log(prelink);
          if(result.previous) {
              html += '<li class="page-item"><a class="page-link" href="' + prelink + result.previous + '">' + this.options.translator('PREVIOUS') + '</a></li>';
          }
          if(result.range.length) {
              for( i = 0, len = result.range.length; i < len; i++) {
                  if(result.range[i] === result.current) {
                      html += '<li class="active page-item"><a class="page-link" href="' + prelink + result.range[i] + '">' + result.range[i] + '</a></li>';
                  } else {
                      html += '<li class="page-item"><a class="page-link" href="' + prelink + result.range[i] + '">' + result.range[i] + '</a></li>';
                  }
              }
          }
          if(result.next) {
              html += '<li class="page-item"><a class="page-link" href="' + prelink + result.next + '" class="paginator-next">' + this.options.translator('NEXT') + '</a></li>';
          }
          html += '</ul></div>';
          return html;
      }
    });
    $('.invoices-view').prepend(boostrapPaginator.render());
  }
}



