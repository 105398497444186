export class ParkingMapView {

  constructor(ui) {

    this.polygons = []
    this.parkings = []
    this.ui = ui
    
    this.parkings = $(this.ui).data('polygons')
    this.filterForm = $(this.ui).find('#parking_filter')
    this.parkingList = $(this.ui).find('#polygon-container')

    O.on('MapView.clicked', (data) => {
      var lat = data.event.latLng.lat();
      var lng = data.event.latLng.lng();
      var location = `${lat},${lng}`
      // console.log(location);
      this.filterForm.find('#parking_filter_location').val(location)
      this.filterForm.find('#parking_filter_code').val('')
      this.filterForm.submit()
    });

    O.on('MapView.created', (mapView) => {
      this.mapView = mapView
      this.drawParkings()
    })
  }

  loadPolygons(polygons) {
    this.removeInfoWindow()
    this.removeAllPolygons()
    this.parkings = JSON.parse(polygons)
    this.drawParkings()
    this.loadAnchors()
  }

  removeAllPolygons() {
    this.polygons.forEach((it) => { it.setMap(null) })
  }

  removeInfoWindow() {
    this.mapView.singleInfoWindow.close()
  }

  drawParkings() {

    this.parkings.forEach(it => {
      this.polygons.push(this.mapView.drawPolygon(it.poly, it.parking));
    })

    if(this.parkings.length != 0) this.mapView.fitAllLocations(this.parkings.flatMap((it) => { return it.poly }))
  }

  loadAnchors() {
    this.parkingList.html('')
    this.parkings.forEach(it => {
      console.log(it);
      var a = `<a class="list-group-item list-group-item-action" href="/admins/parkings/${it.parking.id}">${it.parking.code}</a>`
      this.parkingList.append(a)
    })
  }

}