module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("\n<h3>Current trending in ")
    ; __append(escapeFn( city_name ))
    ; __append("</h3>\n")
    ;  response.trending_items.forEach(function(item) { 
    ; __append("\n  <div class=\"row mt-2 justify-content-center\">\n    <div class=\"col-2\">\n      <img src=")
    ; __append(escapeFn( item.image_url ))
    ; __append(" class=\"rounded img-fluid\" />\n    </div>\n    <div class=\"col-9\">\n      <div class=\"item-img-desc\">\n        <p>")
    ; __append(escapeFn( item.shop_name ))
    ; __append("</p>\n        <p>")
    ; __append(escapeFn( item.name ))
    ; __append(" id: ")
    ; __append(escapeFn( item.id ))
    ; __append("</p>\n        ")
    ;  if(!item.active) { 
    ; __append("\n          <p class=\"text-danger\">")
    ; __append(escapeFn( "Deacticated" ))
    ; __append("</p>\n        ")
    ;  } 
    ; __append("\n      </div>\n      <div>\n        <p>history_volume ")
    ; __append(escapeFn( item.history_volume.toFixed(2) ))
    ; __append("</p>\n        <p>current_volume ")
    ; __append(escapeFn( item.current_volume.toFixed(2) ))
    ; __append("</p>\n        <p>trend_score ")
    ; __append(escapeFn( item.trend_score.toFixed(2) ))
    ; __append("</p>\n      </div>\n    </div>\n  </div>\n  <hr/>\n")
    ;  }); 
    ; __append("\n  \n")
  }
  return __output;

}