module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<li class=\"list-group-item filteredBranch\">\n  <div class=\"row\">\n    <div class=\"col-sm-1\"><img width=\"50\" src=\"")
    ; __append(escapeFn( branch.thumb_url ))
    ; __append("\" alt=\"Thumb logo\"></div>\n    ")
    ;  var width = branch.category ? 4 : 9 
    ; __append("\n\n    <div class=\"col-sm-")
    ; __append(escapeFn( width ))
    ; __append("\">\n      <div class=\"row\"><span style=\"font-weight: bold\">")
    ; __append(escapeFn( branch.shop_name ))
    ; __append("</span></div>\n      <div class=\"row\"><span style=\"color:#d2a54e;\">")
    ; __append(escapeFn( branch.name ))
    ; __append("</span></div>\n    </div>\n\n    ")
    ;  if(branch.category) { 
    ; __append("\n      <div class=\"col-sm-5\">\n        ")
    ;  if(branch.item) {
    ; __append("\n          <div class=\"row\">\n            <div class=\"col-sm-1\"><img width=\"30\" src=\"")
    ; __append(escapeFn( branch.item.image.url ))
    ; __append("\" alt=\"item logo\"></div>\n            <span style=\"color:#00a65a;\">")
    ; __append(escapeFn( branch.item.name ))
    ; __append("</span>\n          </div>\n        ")
    ;  } 
    ; __append("\n        <div class=\"row\"><span style=\"font-weight: bold\">")
    ; __append(escapeFn( branch.category.name ))
    ; __append("</span></div>\n      </div>\n    ")
    ;  } 
    ; __append("\n\n    <div class=\"col-sm-2\">\n      <span class=\"badge badge-orange\">Distance: ")
    ; __append(escapeFn( parseInt(branch.distance) ))
    ; __append(" M</span>\n    </div>\n  </div>\n</li>")
  }
  return __output;

}