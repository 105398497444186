import { Model } from "./model";
import { SubExtra } from "./sub_extra";

export class Extra extends Model {
  // name; subExtras

  constructor(options) {
    super(options)
    this.subExtras = options.sub_extras.map((sub_extra) => { return new SubExtra(sub_extra) })
  }
}