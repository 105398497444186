import _, { after } from "underscore";
import { LocationManager } from "./location_manager";
import { Order } from "./order";
import { OrderItem } from "./order_item";
import { Navigation } from "./testing_order"

const itemModalTemplate = require('../templates/item_modal.ejs');
const categoryItemsTabTemplate = require("../templates/category_items_tabs");
const orderDetailsTemplate  = require("../templates/order_details");
const devicesTemplate = require('../templates/devices');
const branchPickuPTimeTemplate = require('../templates/order_pickup_time');
var BranchCategory = function(options){

  var _this = this;
  this.lastKnownLocation = undefined;
  this.item_modal_id = options.item_modal_id;
  this.order_feed_back_element =  options.order_feed_back_element;
  this.item = {};
  this.order = {};
  this.branch_id;
  this.user_id = options.user_id;
  this.current_link_element = undefined;
  this.shopMarker = undefined;
  this.navigation = undefined;
  this.google_map = new Packs.application.GoogleMap({
    elementId: 'create_order_map',
    latitude_box: $('#order-latitude'),
    longitude_box: $('#order-longitude'),
  });

  this.socketio = new Packs.application.DrivuSocketIO({
    user_id: options.user_id
  });

  this.google_map.set_map('Dubai', 'UE');

  $(window).on('load', function(){
    localStorage.clear();
  });

  $('#branch_id').on('change',function(e){
    _this.get_branch($(e.currentTarget).val());
  });

  $('#category-items').on("ajax:success", '.show_item' ,function(e, data, status, xhr){
    e.stopImmediatePropagation();
    _this.draw_item_modal(data.menu_item, $(this));
  });

  $(this.item_modal_id).find(".modal-dialog").on('click', ".item_done", function(e) {
    
    var find_pre_order_item = _.find(_this.get_all_storage_item(), function(item){
      return  item.pre_order
    });
  
    if(find_pre_order_item != undefined && _this.item.pre_order == false ){
      alert("Pre order should have only pre order items, this item is not a pre order item");
      $(_this.item_modal_id).modal('hide');
      return false
    }

    if( _this.item.pre_order ) {  
      $('.branch-pickup-time').html("");
      $('.branch-pickup-time').append(branchPickuPTimeTemplate( { branch: _this.branch }));
    }
 
    _this.item.notes = $(e.currentTarget).parents().find('#item-note').val();

    set_selected_option(_this, function(){
      _this.current_link_element.find('span.badge').attr('hidden', false);
      _this.setObject(_this.item.id, _this.item, function(){
        append_order_preview(_this);
      });
    });

  });

  $(this.item_modal_id).find( ".modal-dialog" ).on("click", ".quantity-right-plus", function(e) {
    _this.item.quantity_plus(e);
  });

  $(this.item_modal_id).find( ".modal-dialog" ).on("click", ".quantity-left-minus", function(e) {
    _this.item.quantity_minus(e);
  });

  $('body').on('click', '.close-badge', function(e){
    var id = $(this).attr("id").split('-').pop()
    if(_.contains(_this.local_storage_keys(), id)){
      _this.removeObject(id);
      $(this).parents('.order-item').remove();
    }
  });

  $('#create_remote_order').on('click',function(){
     on_create_remote_order_click.bind(_this)();
  });

  this.order_feed_back_element.hide();

  this.order_feed_back_element.keypress(function(e){
    if(e.which == 13) {
      get_current_order().create_order_feed_back($(e.currentTarget).val(), function(){
        $(e.currentTarget).val(" ");
        $(e.currentTarget).parent().append("<p>feedback sent</p>");
      });
    }
  }.bind(this));

  this.orderLocation();
}

BranchCategory.prototype.draw_item_modal = function(item, e){
  this.find_or_create_item(item);
  this.current_link_element = e;
  $(this.item_modal_id).find('.modal-dialog').empty();
  var item_tmp = itemModalTemplate({item: this.item});
  $(this.item_modal_id).find('.modal-dialog').append(item_tmp);
  $(this.item_modal_id).modal('show');
}

BranchCategory.prototype.find_or_create_item = function(item, cb){
  this.item = (this.getObject(item.id) == null) ? new OrderItem(item, {item_modal_id: this.item_modal_id}) : new OrderItem(this.getObject(item.id), {item_modal_id: this.item_modal_id})
}

BranchCategory.prototype.draw_branch_location = function(order, cb){
  // this.add_marker_and_change_camera_location(order.branch.parking_latitude,order.branch.parking_longitude);
  this.google_map.draw_custom_circle(100, order.branch.parking_latitude, order.branch.parking_longitude);
  this.google_map.draw_custom_circle(300, order.branch.parking_latitude, order.branch.parking_longitude);
  cb();
}

BranchCategory.prototype.add_marker_and_change_camera_location= function(lat, lng){
  var marker = this.google_map.generate_marker(lat, lng);
  this.google_map.pin_marker_to_current_map(marker);
  this.google_map.move_camera_to_location(lat, lng);
}

BranchCategory.prototype.emit_new_location = function(event, marker){
  var order = get_current_order();

  var new_location = new google.maps.LatLng(event.latLng.lat(),event.latLng.lng());
  this.lastKnownLocation = this.lastKnownLocation || new google.maps.LatLng(parseFloat(order.latitude), parseFloat(order.longitude));
  var heading =_getPositiveHeadingDegrees(google.maps.geometry.spherical.computeHeading(this.lastKnownLocation, new_location));
  this.lastKnownLocation = new_location;

  var duration = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(order.branch.parking_latitude, order.branch.parking_longitude), new google.maps.LatLng(event.latLng.lat(),event.latLng.lng()))
  this.socketio.client.emit("stream_location", {
    latitude: event.latLng.lat(), longitude: event.latLng.lng(), duration: parseInt(duration), orientation: heading
  });

  $.ajax({
    type: 'GET',
    url: '/admins/navigations/navigation',
    data: {
      origin: `${event.latLng.lat()},${event.latLng.lng()}`,
      destination: `${order.branch.parking_latitude},${order.branch.parking_longitude}`
    },
    success: function(data, status, xhr) {
      var res = xhr.responseJSON.navigation
      this.navigation.polyline.setMap(null);
      this.navigation = new Navigation(res);
      this.navigation.polyline.setMap(this.google_map.map);
    }.bind(this)
  });
  update_current_location_order(event.latLng.lat(),event.latLng.lng(), order);
  get_current_order_status(order.id);
}

BranchCategory.prototype.setObject = function(key, value, cb) {
  localStorage.setItem(key, JSON.stringify(value));
  cb(); 
}

BranchCategory.prototype.getObject = function(key) {
  var retrievedObject = localStorage.getItem(key);
  return JSON.parse(retrievedObject)
}

BranchCategory.prototype.removeObject = function(key) {
  localStorage.removeItem(key);
}

BranchCategory.prototype.local_storage_keys = function(){
  var  keys = []
  for (var i = 0; i < localStorage.length; i++) {
    keys.push(localStorage.key(i));
  }
  return keys;
}

BranchCategory.prototype.get_all_storage_item = function(){
  var keys = Object.keys(localStorage);
  var all_storage_item = _.map(keys, function(key){
    var item = JSON.parse(localStorage.getItem(key));
    return {
      item_id: item.id,
      name: item.name,
      quantity: item.quantity,
      notes: item.notes,
      options: item.option_ids,
      pre_order: item.pre_order,
      price: item.price,
      image_url: item.image_url
    }
  });
  return all_storage_item;
}

BranchCategory.prototype.after_branch_changed = function(branch){
  this.branch_id = branch.id;
  this.branch =  branch
  this.shop_id = branch.shop_id;
  this.reset_markers();
  localStorage.clear();
  append_category_items_list(branch);
  get_shop_devices(this);
  // this.add_marker_and_change_camera_location(branch.parking_latitude, branch.parking_longitude, 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png');
  this.shopMarker = this.google_map.generate_restaurant_marker(branch.parking_latitude, branch.parking_longitude, branch.white_thumb)
  this.google_map.pin_marker_to_current_map(this.shopMarker);
  this.google_map.pin_marker_to_current_map(this.shopMarker);
  this.google_map.move_camera_to_location(branch.parking_latitude, branch.parking_longitude);
  $('#plate_number').removeAttr("hidden");
  $('#cash_amount').removeAttr("hidden");
  $('#create_remote_order').removeAttr("hidden");
  $('#target_branch_id').val(branch.name);

}

BranchCategory.prototype.reset_markers = function(){
  this.google_map.lat =  0;
  this.google_map.lng = 0;
  this.google_map.delete_markers();
}

BranchCategory.prototype.get_branch = function(branch_id, shop_id){
  $.ajax({
    url: `/moderator/shop/${shop_id}/branches/${branch_id}`,
    method: "get",
    dataType: "json",
    success: function(data, status, xhr){
      this.after_branch_changed(xhr.responseJSON.branch);
    }.bind(this),
    error: function(){}
  });
}

// function shop_close_badge_on_load(){  
//   _.each($('.close-badge'), function(e){
//     var id = $(e).attr("id").split('-').pop();
//     if(_.contains(this.local_storage_keys(), id)){
//       $(e).attr('hidden', false);
//     }
//   }.bind(this));
// }

function set_selected_option(_this, cb){
  
  var price = [_this.item.price]
  var ids = []

  $("#create-item-form input[type='radio']:checked").map(function(){ 
    price.push($(this).data("price"));
    ids.push($(this).val());
  });

  $("#create-item-form input[type='checkbox']:checked").map(function(){
    price.push($(this).data("price"));
    ids.push($(this).val());
  });
  
  _this.item.price = _.reduce(price, function(memo, num){ return memo + num; }, 0);
  _this.item.option_ids = ids 
  cb();
}

function on_create_remote_order_click(){

  var order = new Order({
    branch_id: this.branch_id,
    user_id: this.user_id,
    plate_number: $('#plate_number').val(),
    cash_amount: $('#cash_amount').val(),
    latitude: $('#order-latitude').val(),
    longitude: $('#order-longitude').val(),
    from_loyalty: $('#from_loyalty').is(":checked"),
    pickup_time: $('.pickup-time-selector').find(':selected').val(),
    menu_items_attributes: this.get_all_storage_item()
  });

  order.create(function(order){
    show_order_status(order.id, order.status);
    set_current_order(order);
    show_feed_back_form(this);
    this.google_map.set_click_listener(this.emit_new_location.bind(this));
    draw_order_details();
    this.navigation = new Navigation(order.navigation);
    this.navigation.polyline.setMap(this.google_map.map);
    this.draw_branch_location(order,function(){
      this.google_map.set_click_listener(this.emit_new_location.bind(this));
      // draw_order_details();
    }.bind(this));
  }.bind(this));
}

function draw_order_details(){
  var order = get_current_order()
  var items_template = orderDetailsTemplate({order: order});
  $('.order-details').append(items_template);
}

function append_category_items_list(branch){
  $('#category-items').empty();
  var category_items = categoryItemsTabTemplate({ categories: branch.categories, branch: branch });
  $('#category-items').append(category_items);
}

function get_current_order(){
  return new Order(JSON.parse(localStorage.getItem('current_order')));
}

function set_current_order(order){
  localStorage.setItem('current_order', JSON.stringify(order));
}

function update_current_location_order(lat,lng,order){
  order.branch.latitude = lat;
  order.branch.longitude = lng;

  set_current_order(order);
}

function update_current_order_status(new_status,cb){
  var order = get_current_order();
  order.status =new_status;
  set_current_order(order);
  if(cb) cb();
}

function get_current_order_status(order_id){
  $.ajax({
    url: "/admins/order_statuses/"+order_id,
    method: "get",
    dataType: "json",
    success: function(data,status,xhr){
      update_current_order_status(xhr.responseJSON.order_status,function(){
        show_order_status(order_id,xhr.responseJSON.order_status);
      });
    },
    error: function(xhr,status,error){}
  });
}

function show_order_status(order_id,status){
  $('.order-status').html("<p> order_number:"+ order_id + ", status:" + status +  "</p>")
}

function _getPositiveHeadingDegrees(heading){
  return (heading < 0) ? (360+heading) : heading;
}

function show_feed_back_form(_this){
  _this.order_feed_back_element.show();
}

function get_shop_devices(_this){
  $.ajax({
    url: `/moderator/shop/${_this.shop_id}/branches/${_this.branch_id}/branch_devices`,
    method: "get",
    dataType: "json",
    success: function(data, status, xhr){
      $('.devices-table').html("");
      var devices_template = devicesTemplate({devices: data.devices});
      $('.devices-table').append(devices_template);
    }, 
    errors: function(status, data, errors){
    }
  })
}

function append_order_preview(_this){
  $('.order-items-preview').html("");
  $('.order-items-preview').append("<p><strong>Your order</strong></p>");

  _.each(_this.get_all_storage_item(), function(item){
    $('.order-items-preview').append(
      `
       <div class="d-flex flex-row bd-highlight mb-3 justify-content-between align-items-center order-item">
         <div class="p-2 bd-highlight">
            <span id="item-${item.item_id}" class="fa fa-trash red-text close-badge"></span>
            <img src="${item.image_url}" width="50"/>
          </div>
         <div class="p-2 bd-highlight">${item.name}</div>
         <div class="p-2 bd-highlight">${item.price} x ${item.quantity}</div>
       </div>`
    )
   
  })
}
BranchCategory.prototype.orderLocation =  function(params) {
 
  var didMove = false;
  var _this = this;
  var liveMarker = this.google_map.generate_restaurant_marker(0, 0, "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png")
  this.google_map.pin_marker_to_current_map(liveMarker);

  var geoSuccess = function(position) {
    if(!didMove) {
      liveMarker.setPosition(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
      _this.google_map.move_camera_to_location(position.coords.latitude, position.coords.longitude)
      $('#currentLat').val(position.coords.latitude);
      $('#currentLng').val(position.coords.longitude);
      didMove = true;
    }
  };

  var geoError = function(error) {
    if (error.code == error.PERMISSION_DENIED){
      alert(`Error occurred. Error code: ${error.message}`)
    }
  };


  var location_manager = new LocationManager();
  location_manager.watchPosition(geoSuccess, geoError);
  
  if (!location_manager.browserSupportGeoLocation()) {
    alert('Geolocation is not supported for this Browser/OS.');
  }

}

export { BranchCategory }