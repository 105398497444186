
import _ from "underscore";
import { Cart } from "../models/cart"
import { OrderSource } from "./indoor/order_source";

export class Indoor {
  static cart = Cart;
  static TYPES = {'CustomerLane':  'CustomerLane' , 'OrderServing': 'OrderServing'};
  static currentType(){
    switch ( OrderSource.getType() ) {
      case 'CustomerLane':
        return Packs.application.Indoor.CustomerLane
      case 'OrderServing':
        return Packs.application.Indoor.OrderServing
      default:
        return Packs.application.Indoor
    }
  }
}

Indoor.CustomerLane = class {}
Indoor.OrderServing = class {}

require("./indoor/menu")

require("./indoor/recommendation_item_view")
require("./indoor/cart_view")
require("./indoor/item_view")
require("./indoor/registration_form")

require("./indoor/place_order_view")
require("./indoor/order_preview")
require("./indoor/order_details_view")

