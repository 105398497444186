import { ImageFile } from "../../utilits/menu_item";
import { ShopBranchesView } from "../shop_branches_view";
import { PickUpHourView } from "./pickup_hour_view";
import { workingHoursView } from "./working_hours_view";

export class ModeratorBranchView{

  constructor(options){
    this.predectedAddress = options.predectedAddress;
    this.branch = options.branch;
    this.userUpdatePath = options.userUpdatePath;
    this.confirmParkingLocationPath = options.confirmParkingLocationPath || "";
    this.confirmShopLocationPath = options.confirmShopLocationPath || "";
    this.user_id = options.user_id;
    this.daysOfWeek = options.daysOfWeek;
    this.onHours = options.onHours;
    this.pickUpHours = options.pickUpHours;
    this.newBranch = options.newBranch;
  }

  initLoad(){
    new ShopBranchesView({
      branch_map_id: 'branch-map',
      parking_map_id: 'parking-map',    
      branch: this.branch,
      predected_address: this.predectedAddress,
      branch_latitude_box: 'branch_latitude',
      branch_longitude_box: 'branch_longitude',
      branch_parking_latitude_box: 'branch_parking_latitude',
      branch_parking_longitude_box:  'branch_parking_longitude',
    }).initListener();

    this.bindUploadBestSellingImage();
    this.bindUploadBranchLogoImage();
    this.drawWorkingHours();
    this.drawPickupHours();
    this._confirmParkingLocationRequest();
    this._confirmShopLocationRequest();
    this.onSubmit();
  }

  onSubmit(){
   var branchFormId = (this.newBranch !== true) ? `#branch-form-${this.branch.id}` : '#branch-form-new';
    $("#branch-view-con").on("submit", branchFormId, function(e){
      $(e.currentTarget).find(`#${this.branchWorkingHoursView.branchOnHoursFiledId}`).val(JSON.stringify(this.branchWorkingHoursView.serializeWorkingHours()));
      $(e.currentTarget).find(`#${this.branchPickUpHourView.branchOnHoursFiledId}`).val(JSON.stringify(this.branchPickUpHourView.serializeWorkingHours()));
    }.bind(this));
  }

  bindUploadBestSellingImage(){
    $(".best-selling-image").on('click', function(){
      $("#branch_image").trigger("click");
    });

    $("#branch_image").on("change", function(event){
      var input = event.target;
      if (this.files && this.files[0]) {
        var reader = new FileReader();
        reader.onload = function(){
          $('.best-selling-image img').attr('src', reader.result);
        };
        reader.readAsDataURL(input.files[0]);
      }
    });
  }

  bindUploadBranchLogoImage(){
    var _this = this;
    $('.branch-user-logo-form').on('click', function(e){
      console.log("click")
      $('#user-logo-file-upload').trigger('click');
    });



    $('#user-logo-file-upload').on('change', function(){
      var file = $(this).get(0).files[0];
      console.log(file);
      var imageFile = new ImageFile(file);
      imageFile.enhanceFile(function(file){ 
        $('.user-logo').attr('src', URL.createObjectURL(file));
        _this._update_user_profile(imageFile);
      });
    });
  }

  drawWorkingHours(){
    this.branchWorkingHoursView = new workingHoursView({
      containerElement: $("#branch-view-con"),
      branchFormId: (this.newBranch !== true) ? `#branch-form-${this.branch.id}` : '#branch-form-new',
      branchOnHoursFiledId: (!this.newBranch) ? `${this.branch.id}-branch_on_hours` : 'new-branch_on_hours',
      branch_id: this.branch.id,
      dayElementClassName: '.singleDay',
      on_hours: this.onHours, 
      daysOfWeek: this.daysOfWeek,
      workingHourContainerClassName: '.working-hours',
    });

    this.branchWorkingHoursView.delegateEvents();
    this.branchWorkingHoursView.renderUI();
    this.branchWorkingHoursView.appendBranchOnHoursHiddenFiled();
    this.branchWorkingHoursView.setCurrentWorkingHours();
  }

  drawPickupHours(){
    this.branchPickUpHourView = new PickUpHourView({
      containerElement: $("#branch-view-con"),
      branchFormId: (this.newBranch !== true) ? `#branch-form-${this.branch.id}` : '#branch-form-new',
      branchOnHoursFiledId: (!this.newBranch) ? `${this.branch.id}-branch-pickup-hours` : 'new-branch-pickup-hours',
      branch_id: this.branch.id,
      dayElementClassName: '.singleDay',
      on_hours: this.pickUpHours, 
      daysOfWeek: this.daysOfWeek,
      workingHourContainerClassName: '.pickup-hours',
    });

    this.branchPickUpHourView.delegateEvents();
    this.branchPickUpHourView.renderUI();
    this.branchPickUpHourView.appendBranchOnHoursHiddenFiled();
    this.branchPickUpHourView.setCurrentWorkingHours();

  }

  _readFile(event, file, cb){
    var input = event.target;
    if (file.files && file.files[0]) {
      var reader = new FileReader();
      reader.onload = function(){
        $('.user-logo').attr('src', reader.result);
      };
      reader.readAsDataURL(input.files[0]);
      cb(reader.result);
    }
  }

  _update_user_profile(imageFile){
    var formData = new FormData();
    formData.append("user[image]", imageFile.file, imageFile.getName());
    formData.append("user_id", this.user_id);
    $.ajax({
      url: this.userUpdatePath,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      method: 'put',
      success: function(data){
      }
    })
  }

  _confirmShopLocationRequest(){
    $('#confirm_shop_location_request').on('click',function(e){ 
      $.ajax({
        url: `${this.confirmShopLocationPath}.js`,
        method: "put",
        data: {"branch": {"latitude": $('#branch_latitude').val(), "longitude": $('#branch_longitude').val()  }},
      });  
    }.bind(this));
  }
  _confirmParkingLocationRequest(){
    $('#confirm_parking_location_request').on('click', function(){
      $.ajax({
        url: `${this.confirmParkingLocationPath}.js`,
        method: "put",
        data: {
          "branch": {
           "parking_latitude": $('#branch_parking_latitude').val(),
           "parking_longitude": $('#branch_parking_longitude').val() 
          }
        },
      });
    }.bind(this));
  }
 
}