export class WorkingHoursPlan {

  form = '.plans-form'

  constructor(ui) {
    this.ui = ui
    this.workingHours = $(this.ui).data('wh')
    
    this.workingHours.forEach(hour => {
      $("#plan-" + hour.plan_id + "-day-" + hour.day + "-hour-" + hour.hour.split(":")[0]).attr("checked", true)
    })

    $(this.ui).find(this.form).submit( event => {

      var serializeWorkingHours = _.map($(this.ui).find(".hour-check:checkbox:checked"), function(element) {
        var obj = {}
        var ids = $(element).attr("id").split('-')
        obj.working_hours_plan_id =  ids[1]
        obj.day= ids[3]
        obj.hour= ids[5]
        return obj
      })

      $(this.ui).find(".plan_working_hour").val(JSON.stringify(serializeWorkingHours))
      return true
    });

    $(this.ui).find("input.working_hours_plans_active").on('change', function(it) {
      $("input.working_hours_plans_active").prop('checked', false)
      $(it.currentTarget).prop('checked', true)
    });
  }

}