const csrfToken = document.getElementsByName("csrf-token")[0].content;
window.vapidPublicKey = new Uint8Array([4, 124, 91, 41, 85, 172, 201, 125, 231, 152, 8, 240, 217, 172, 214, 131, 56, 112, 26, 160, 0, 177, 3, 88, 186, 187, 74, 102, 9, 39, 206, 25, 175, 136, 79, 103, 168, 2, 1, 109, 98, 205, 29, 131, 52, 97, 206, 206, 207, 142, 205, 220, 232, 80, 175, 90, 179, 79, 113, 240, 69, 59, 77, 106, 161]);
window.drivu = {}
window.drivu.saveNotificationToken = (info) => {
	$.ajax({
		type: 'POST',
		url: '/push_notifications',
		beforeSend: function(request) {
		},
		data: {
			'notification[end_point]': info.endpoint,
			'notification[token]': `${info.keys['p256dh']}::${info.keys['auth']}`
		},
		dataType: "JSON"
	});
}