export class View {

  constructor(ui) {

    this.ui = ui
    this.ui.querySelectorAll('[attr]').forEach((e) => { 
      this[e.getAttribute("attr").camelCase()] = e
    })
  }

  destroy() {
    $(this.ui).fadeOut()
    Packs.application[this.constructor.name].pool.delete(this)
  }
}