// aps: {alert: 'New Drive Thru Order!', sound: 'out_car_horn.caf'}
// body: "New Drive Thru Order!"
// order_id: 1098484
// sound: "out_car_horn.caf"
// title: "Drivu notifications"
// type: "new_order"

'use strict';

// var mediaUrl: String? = null
// var mediaType: String? = null
// var text: String? = null
// var orderId: Long? = null
// var sound: String? = null
// var refund: String? = null
// var branchId: Long? = null

export class PushNotification {

  constructor(options, delegate) {
    this.title = options.aps.alert;
    this.payloadTitle = options.title;
    this.body = options.body;
    this.type = options.type;
    this.action = options.action;
    this.orderId = options.order_id;
    this.status = options.status;
    this.delegate = delegate || {};
    this.text = options.text;
    this.handle()
  }

  handle() {
    
    if(this.isNewOrder() && this.delegate['new_order']) this.delegate['new_order'](this.orderId);
    if(this.isFeedback() && this.delegate['feedback']) this.delegate['feedback'](this.orderId, this.text)
    if(this.OrderStatusChange() && this.delegate['order_status_changed']) this.delegate['order_status_changed'](this.orderId, this.status)
    if(this.newComment() && this.delegate['new_comment']) this.delegate['new_comment'](this.payloadTitle, this.body)

  }

  isNewOrder() { return this.type == 'new_order' } 
  isFeedback(){ return this.type == 'feedback' }
  OrderStatusChange(){ return this.type == 'order_status_change' }
  newComment(){return this.type == 'new_comment'}
}

PushNotification.Sound = class {

  constructor() {
    this.track = new Audio('/out_car_horn.mp3');
  }

  static manager;

  static load() { this.getManager(); }

  static getManager() {
    if(!this.manager) {
      this.manager = new PushNotification.Sound();
    }
    return this.manager;
  };

  static play() { 
    this.getManager().track.play(); 
    setTimeout(this.onSoundDone.bind(this), this.duration());
  }

  static playFor(duration) { 
    this.getManager().track.play();
    setTimeout(this.stop.bind(this), duration);
  }

  static stop() { this.getManager().track.pause(); }
  static duration() { return this.getManager().track.duration * 1000; }
  static playForever() { setInterval(this.play, this.duration()); }
  static onSoundDone() {}

}