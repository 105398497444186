import { add_search } from '../utilits/drivu_global_functions';

 export class dashboardMessage {

	constructor(ui) {

		this.ui = ui

		// $('#map-location-div').toggle();

		this.google_map = new Packs.application.GoogleMap({ latitude_box: $("#latitude_box"), longitude_box: $('#longitude_box') });

		$('#add-user-location').on('click', function (e) {
			e.preventDefault();
			$('#map-location-div').toggle();
			this.google_map.set_map('Dubai', 'UE');
		}.bind(this));

		$('#radius').on("keyup", function (e) {
			if ($("#latitude_box").val() != "" && $('#longitude_box').val() != "") {
				var radius_in_miles = this.getMiles(parseFloat(e.currentTarget.value));
				this.google_map.draw_circle(radius_in_miles);
				this.get_user_count_in_region(this);
			} else {
				alert('add marker');
			}
		}.bind(this));

		this.load_search_by_user_name();
		this.load_search_by_shop_name();
		this.load_branch_search_by_shop_name();
		this.load_branch_search_by_shop_name_swipe_to_location(this);
		this.loadItemsSearch()
		$("#create-notification_gallery").on("ajax:success", function(event, data, status, xhr){ window.location.reload() })


    $(".radio-button-type").on('change', function(){

      const el = {
        location: $("#users-search"),
        menu_item: $("#items-search")
      }
      $('.search-field').hide()
      if(el[this.value]) el[this.value].show()
      
    }); 

    $("#dashboard_message_purchasing_users_ids").bind('change', function(){
      var drop_down = $("#dashboard_message_purchase_target_branch_id");
      $(this).is(':checked') ? drop_down.show() : drop_down.hide()
    });

    $('.list-group').delegate('.fa-remove', 'click', function(){ $(this).parent().remove(); });

    $('#push-submit').on('click', function(){
      var ids = $.map($('#selected-users').children(), function(li){ return parseInt($(li).val()) });
      var branch_ids = $.map($('#selected-branches').children(), function(li){ return parseInt($(li).val()) });
      $('#dashboard_message_selected_user_ids').val(JSON.stringify(ids));
      $('#dashboard_message_selected_branch_ids').val(JSON.stringify(branch_ids));
    });

	}

	get_user_count_in_region(_this) {

		if ($('#radius').val().length > 0) {
			$.ajax({
				type: 'GET',
				url: '/admins/region_users',
				data: {
					'user_in_region[radius]': $('#radius').val(),
					'user_in_region[lat]': $("#latitude_box").val(),
					'user_in_region[lng]': $('#longitude_box').val()
				},
				success: function (data, textStatus, xhr) {
					_this.google_map.delete_markers();
					$('.users-count').html(xhr.responseJSON.count);
					_.each(xhr.responseJSON.locations, function (location) {
						var marker = _this.google_map.generate_marker(location.latitude, location.longitude);
						_this.google_map.pin_marker_to_current_map(marker);
					});
				}
			});
		}
	}
	
	getMiles(radius) { return radius * 1600 }
	
	load_search_by_user_name(){
		add_search('#users-search-txt', '/admins/search_users', 'users', '#users-search-results', function (data, response) {
			response($.map(data, function (value, key) {
				return { label: value.name, value: value.id, type: value.type }
			}));
		}, function (e, ui) {
			$(this).val("");
			if (ui.item.type == "branch") $('#selected-branches').append('<li class="list-group-item" value="' + ui.item.value + '">' + ui.item.label + '<i class="fa fa-remove fa-2x pull-right" style="color: red;"></i></li>');
			if (ui.item.type == "user") $('#selected-users').append('<li class="list-group-item" value="' + ui.item.value + '">' + ui.item.label + '<i class="fa fa-remove fa-2x pull-right" style="color: red;"></i></li>');
		});
	}
	
	load_search_by_shop_name() {
		add_search('#dashboard_message_shop_id', '/moderator/search_shops', 'shops', '#shop-customer_search-results', function (data, response) {
			response($.map(data, function (value, key) {
				return { label: value.username, value: value.id }
			}));
		}, function (e, ui) {
			$(this).val("");
		});
	}
	
	load_branch_search_by_shop_name() {
		add_search('#dashboard_message_purchase_target_branch_id', '/admins/search_branches', 'branches', '#branch-customer_search-results', function (data, response) {
			response($.map(data, function (value, key) {
				return { label: value.name, value: value.id }
			}));
		}, function (e, ui) {
			$(this).val("");
		});
	}
	
	load_branch_search_by_shop_name_swipe_to_location(_this) {
		add_search('#dashboard_message_location_branch_id', '/admins/search_branches', 'branches', '#branch-swipe-to-location-search', function (data, response) {
			response($.map(data, function (value) {
				return { label: value.name, value: value.id, longitude: value.longitude, latitude: value.latitude }
			}));
		}, function (e, data) {
			var position = new google.maps.LatLng(data.item.latitude, data.item.longitude)
			$("#latitude_box").val(data.item.latitude);
			$('#longitude_box').val(data.item.latitude);
			_this.google_map.lat = position.lat();
			_this.google_map.lng = position.lng();
			_this.google_map.marker.setPosition(position);
			_this.google_map.move_camera_to_marker(_this.google_map.marker);
			$(this).val("");
		});
	}

	loadItemsSearch() {
		add_search('#dashboard_message_menu_id', '/admins/search_items', 'items', '#swipe-to-item-search', function (data, response) {
			response($.map(data, function (value, key) {
				return { label: value.name, value: value.id }
			}));
		}, function (e, ui) {
			$(this).val("");
		});
	}

}