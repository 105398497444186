module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"row\">\n  <div class=\"col-sm-3\">\n    <img class=\"img-thumbnail img-responsive small-image\" src='")
    ; __append(escapeFn( menu_item.image.thumb.url ))
    ; __append("' alt=\"Thumb 79ce249f5\">\n  </div>\n  <div class=\"col-sm-9\">\n    <ul class=\"list-inline\">\n      <li class=\"list-inline-item\"><a href=\"/admins/orders/")
    ; __append(escapeFn( order_item.order_id))
    ; __append("/order_items/")
    ; __append(escapeFn( order_item.id ))
    ; __append("/edit\">")
    ; __append(escapeFn( order_item.quantity))
    ; __append(" x ")
    ; __append(escapeFn( menu_item.name ))
    ; __append("  </a></li>\n      <li class=\"list-inline-item\"><input type=\"text\" name=\"order_item[price]\" id=\"order_item_price\" value=\"")
    ; __append(escapeFn(order_item.price))
    ; __append("\" data-remote=\"true\" data-type=\"json\" data-url=\"/admins/orders/")
    ; __append(escapeFn( order_item.order_id))
    ; __append("/order_items/order_item.id\" data-method=\"put\"></li>\n      <li class=\"list-inline-item\"><a class=\"delete-order-item\" data-remote=\"true\" rel=\"nofollow\" data-method=\"delete\" href=\"/admins/orders/")
    ; __append(escapeFn( order_item.order_id ))
    ; __append("/order_items/")
    ; __append(escapeFn( order_item.id ))
    ; __append("\">Delete</a></li>\n    </ul>\n    <p class=\"gray-color\">Special Request:  ")
    ; __append(escapeFn( order_item.notes ))
    ; __append("</p>\n  </div>\n</div>\n")
  }
  return __output;

}