import "dropzone"
Dropzone.autoDiscover = false;
Dropzone.options.myAwesomeDropzone = false;

class BranchMenuImageView{
  constructor(options){
    this.files = options.files;
    this.restaurant_id= options.restaurant_id;
    this.branch_id = options.branch_id
    this.initLoad();
  }

  initLoad(){
   var _this = this
    $("#registration-document").dropzone({
      maxFiles:1,
      maxFilesize: 4, // MB
      addRemoveLinks: true,
      dictRemoveFile: "Delete",
      dictCancelUploadConfirmation: "Are you sure to cancel upload?",
      dictDefaultMessage: "Drop files here or click to upload.",
      init: function () {
        var thisDropzone = this;
        var data = _this.files;
        if (data == null) {
          return;
        }
        _.each(data, function (file) {
          thisDropzone.emit("addedfile", file);
          thisDropzone.options.thumbnail.call(thisDropzone, file, file.url);
          thisDropzone.emit("complete", file);
        });

        this.on("removedfile", function (file) {
          $.ajax({
            url: `/admins/restaurants/${_this.restaurant_id}/branches/${this.branch_id}/branch_menu_images/${file.id}`, //"/admins/restaurants/" + "<%= params[:restaurant_id]%>" + "/branches/"+ "<%= params[:branch_id]%>" +"/branch_menu_images/" + file.id,
            method: "delete",
            dataType: "JSON",
            success: function(data){
              console.log("removed!");
            }
          })
        });
      }
    });
  }
}

export {BranchMenuImageView}