module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"bg-white py-3\">\n\n  ")
    ;  if(order.status == "accepted") {
    ; __append("\n    <div class=\"row section\">\n      <div class=\"col-12 btn-danger\">\n        <div class=\"d-flex  user-info  flex-sm-row flex-column \">\n          <div>\n            <p>\n              <span>\n                <button type=\"button\" class=\"back-arrow\" data-method-name=\"back-arrow\">\n                  <i class=\"fa fa-angle-left fa-lg \" aria-hidden=\"true\"></i>\n                </button>\n              </span>\n            </p>\n          </div>\n          <p>Accept order to view user info</p>\n        </div>\n      </div>\n    </div>\n  ")
    ; } else {
    ; __append("  \n    <div class=\"row section\">\n      <div class=\"col-12\">\n        <div class=\"d-flex  user-info justify-content-sm-between flex-sm-row flex-column \">\n\n          <div>\n            <p><span><button type=\"button\" class=\"back-arrow\" data-method-name=\"back-arrow\"><i class=\"fa fa-angle-left fa-lg \" aria-hidden=\"true\"></i></button>  </i><i class=\"fa fa-car ps-2\" aria-hidden=\"true\"></i> </span>")
    ; __append(escapeFn( order.plate_number))
    ; __append("</p>\n          </div>\n\n          <div>\n            <span><i class=\"fa fa-user-circle-o\" aria-hidden=\"true\"></i></span>\n            ")
    ; __append(escapeFn( order.user.username))
    ; __append("\n          </div>\n\n          <div>\n            <span><i class=\"fa fa-phone\" aria-hidden=\"true\"></i></span>\n            ")
    ; __append(escapeFn( order.user.phone_number))
    ; __append("\n          </div>\n\n          <div>\n            <a class=\"btn btn-outline-primary\" data-remote=\"true\" href= \"/multi_shop_agent/shop_orders/")
    ; __append(escapeFn( order.id ))
    ; __append("/messages\">messages</a>\n          </div>\n          \n        </div>\n      </div>\n    </div>\n  ")
    ; }
    ; __append("\n  <hr class=\"py-2\">\n\n  <div class=\"order-item-list section \">\n    ")
    ;  order.items.forEach(function(item){ 
    ; __append("\n      <div class=\"row align-items-center order-item\">\n        <span>\n          <button data-order-item-id =\"")
    ; __append(escapeFn( item.id))
    ; __append("\" data-order-id=\"")
    ; __append(escapeFn( order.id ))
    ; __append("\" data-method-name=\"delete-item\" type=\"button\" class=\"btn btn-link\">\n            <i class=\"fa fa-trash red-text\" aria-hidden=\"true\" ></i>\n          </button>\n        </span>\n\n        <div class=\"col-3 col-sm-1\"> \n          <div class=\"sequar-image\">\n            <img class=\"rounded img-responsive small-image\" src='")
    ; __append(escapeFn( item.thumb_url ))
    ; __append("' alt=\"Thumb 79ce249f5\">\n          </div>\n        </div>\n\n        <div class=\"col-sm-9\">\n          <p class=\"text-secondary\"><small>")
    ; __append(escapeFn( item.category.name ))
    ; __append("</small></p> \n          <p class=\"title\">")
    ; __append(escapeFn( item.quantity ))
    ; __append("X ")
    ; __append(escapeFn( item.name))
    ; __append("</p>\n          ")
    ;  item.order_extras.forEach(function(extra){ 
    ; __append("\n\n            <div class=\"d-flex\">\n              <div>\n                <p class=\"pr-1\">")
    ; __append(escapeFn(extra.name ))
    ; __append(": </p>\n              </div>\n\n              <div>\n                <p><strong>")
    ; __append(escapeFn( extra.options.toString() ))
    ; __append("</strong></p>\n              </div>\n            </div>\n          ")
    ;  }) 
    ; __append("\n\n          ")
    ;  if(item.notes) { 
    ; __append("\n          <div class=\"d-flex\">\n            <div>\n              <p class=\"pr-1\">Special request: </p>\n            </div>\n            <div>\n              <p><strong>")
    ; __append(escapeFn( item.notes ))
    ; __append("</strong></p>\n            </div>            \n          </div>\n          ")
    ;  } 
    ; __append("\n\n        </div>\n\n        <div class=\"col-sm \">\n          <p class=\"title float-end\"> AED ")
    ; __append(escapeFn( item.price ))
    ; __append("</p>\n        </div>\n      </div>\n    ")
    ;  }) 
    ; __append("\n  </div>\n\n  <hr class=\"py-3\">\n\n  <div class=\"section\">\n    <div class=\"row justify-content-end text-end\">\n      <div class=\"col-3\">\n        <p >VAT</p>\n      </div>\n      <div class=\"col-3\">\n        <p>AED ")
    ; __append(escapeFn( order.vat ))
    ; __append("</p>\n      </div>\n    </div>\n    \n    <div class=\"row justify-content-end text-end\">\n      <div class=\"col-3\">\n        <p class=\"title\">Total Amount</p>\n      </div>\n      <div class=\"col-3\">\n        <p class=\"title\">AED ")
    ; __append(escapeFn( order.total ))
    ; __append("</p>\n        ")
    ;  if(order.payment_method == "cash" && order.from_loyalty ) { 
    ; __append("\n          <p class=\"text-danger title\">Paid from Loyalty</p>\n        ")
    ; }else if(order.payment_method == "cash"){
    ; __append("\n          <p class=\"title\">Paid by Cash</p>\n\n        ")
    ; }else{
    ; __append("\n          <p class=\"title\"><img height=\"20\" width=\"20\" src=\"/images/credit_card.png\"> paid online to Drivu</p>\n        ")
    ; }
    ; __append("\n        \n      </div>\n    </div>\n\n    <div class=\"d-flex justify-content-end align-items-center\">\n      <img width=\"50\" class=\"rounded img-responsive small-image\" src='")
    ; __append(escapeFn( order.branch.logo_url ))
    ; __append("' alt=\"Thumb 79ce249f5\"/>\n      <p class=\"ps-2 title\">")
    ; __append(escapeFn( order.branch.shop_name ))
    ; __append("(")
    ; __append(escapeFn( order.branch.name ))
    ; __append(")</p>\n    </div>\n  </div>\n\n\n  <div class=\"row justify-content-center section\">  \n    <div class=\"col-6\">\n      <button type=\"button\" class=\"btn btn-outline-secondary w-100\" data-order-id=\"")
    ; __append(escapeFn( order.id ))
    ; __append("\" data-method-name=\"reject\">Reject Order</button>\n    </div>\n    <div class=\"col-6\">\n      <button type=\"button\" class=\"btn btn-orange w-100 pickup-btn ")
    ; __append(escapeFn( (order.status === 'accepted') ? 'hidden' : '' ))
    ; __append("\" data-order-id=\"")
    ; __append(escapeFn( order.id ))
    ; __append("\" data-method-name=\"ready\" >Picked Up</button>\n      <button type=\"button\" class=\"btn btn-danger w-100 accept-btn ")
    ; __append(escapeFn( (order.status !== 'accepted') ? 'hidden' : '' ))
    ; __append("\" data-order-id=\"")
    ; __append(escapeFn( order.id ))
    ; __append("\" data-method-name=\"accept\">Accept Order</button>\n    </div>\n  </div>\n\n  ")
    ;  if(order.feedbacks.length > 0){ 
    ; __append("\n    <div class=\"row justify-content-center section \">\n      <div class=\"col-12 \">\n        <div class=\"feedback text-center p-2\">\n            ")
    ; order.feedbacks.forEach(function(feedback) { 
    ; __append("\n              <p class=\"\">")
    ; __append(escapeFn( feedback))
    ; __append(" </p> \n            ")
    ;  }) 
    ; __append("\n        </div>\n      </div>\n    </div>\n  ")
    ;  } 
    ; __append("\n\n  <div class=\"row section pt-2\">\n    <div class=\"col-sm-12\">\n      <div style='position: relative; padding-bottom: 40%; height: 0; overflow: hidden;'>\n        <div id=\"current-order-map\" style='position: absolute; top: 0; left: 0; width: 100% !important; height: 100% !important;'></div>\n        <div style=\"position: absolute; right: 70px; top: 0px;\"></div>\n      </div>\n    </div>\n  </div>    \n\n</div>\n")
  }
  return __output;

}