import { OrderSource } from "../utilits/indoor/order_source";
export class User{

  constructor(options){
    this.id = options.id
    this.username = options.username;
    this.phone_number = options.phone_number;
  }

  static isLoggedIn() { 
    return document.cookie.includes('in_drivu=true') || OrderSource.OrderAnonymous 
  }
  
}