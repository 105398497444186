import { itemViewRequest } from "../utilits/drivu_global_functions";

export class MenuPreview{

  itemRow = '.item-row'
  constructor(ui){

    $('.your-order').hide();
    $('.nav-tabs li:first-child a').tab('show');
    $(this.itemRow).on('click', function(e) {
      e.stopPropagation();

      itemViewRequest($(e.currentTarget).attr("id").split('-').pop(), $(e.currentTarget).data('cart-id'), true)
      // window.location.href = `${window.location.origin}/indoors/menus/${$(e.currentTarget).attr("id").split('-').pop()}/item?q=${$(e.currentTarget).data('cart-id')}`
    }.bind(this));

  }

  
}