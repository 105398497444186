import { Indoor } from "../indoor";
import { CartItem } from "../../models/cart_item";

Indoor.ItemView = class  {

  minusButtonId = '#minus-button';
  plusButtonId = '#plus-button';
  saveButton = '#cart-button-item-view';
  quantityLabel = '#quantity-label';
  specialRequestField = '#special-request';
  optionButtons = '.options';
  backButton = '#item-back-button';
  modalId = '#item-view-modal';


  constructor(ui) {
    // $('#cart-button').on('click', () => { $('#registration-modal').modal('show'); })
    this.item = JSON.parse(ui.dataset.item);
    this.item.__id = ui.dataset.item__id
    this.only_view = JSON.parse(ui.dataset.onlyView);
    
    this.cartItem = new CartItem(this.item, this);

    this.loadView();
    
    $(this.minusButtonId).on('click', () => { 
      this.setSpecialRequest();    
      this.cartItem.quantityMinus() 
    })
    
    $(this.plusButtonId).on('click', () => { 
      this.setSpecialRequest();
      this.cartItem.quantityPlus(); 
    })

    $(this.saveButton).on('click', (e) => { 
      this.setSpecialRequest(); 
      if(! this.only_view ){
        const item = this.cartItem.save(); 
        // $('#item-view-modal').modal('dispose');
        var currentType = Packs.application.Indoor.currentType();
        console.log(currentType);
        currentType.Menu.pool[0].cartView.appendItem(item)
        currentType.Menu.pool[0].cartView.appendRecommendationItem(item.__id)
        currentType.Menu.pool[0].cartView.setVisibility();
        currentType.Menu.pool[0].loadNextButton();
      } 

      this.hide()
      // window.location.href = document.referrer
    })

    $(this.backButton).on('click', ()=>{
      this.hide()
    });

    O.on('ItemOptionWasClicked', (e) => {
      var isAdded = $(e).hasClass('toggled');
      var id = parseInt($(e).attr('id').split('-').pop());
      isAdded ? this.cartItem.addOption(id) : this.cartItem.removeOption(id);
    });

    $(this.specialRequestField).on('click', function(e){
      e.preventDefault();
      $(e.currentTarget).parents('.modal').animate({
        scrollTop: $(e.currentTarget).offset().top
      }, 2000);
    });

  }

  orderItemWasChanged() {this.loadView(); }

  loadView() {
    $(this.quantityLabel).html(this.cartItem.orderItem.quantity.toString());
    $(this.saveButton).children().remove();
    (this.only_view) ? $(this.saveButton).append(this.onlyViewBtnTag()) :  $(this.saveButton).append(this.saveBtnTag());
    $(this.specialRequestField).val(this.cartItem.orderItem.notes);
    _.each(this.cartItem.orderItem.option_ids, (optionId) => { $(`#option-${optionId}`).addClass('toggled') });
  }

  setSpecialRequest() { this.cartItem.setSpecialRequest($(this.specialRequestField).val()) }
  hide() { $(this.modalId).modal("hide"); }

  saveBtnTag(){
    return this.cartItem.orderItem.active_promotion == true ? `<span> Done <s>(${this.cartItem.orderItem.originalGrandTotalWithCurrency()})</s> ${this.cartItem.orderItem.grandTotalWithCurrency()} </span>` : `<span>Done (${this.cartItem.orderItem.grandTotalWithCurrency()})</span>`
  }

  onlyViewBtnTag(){
    return this.cartItem.orderItem.active_promotion == true ? `<span><s>(${this.cartItem.orderItem.originalGrandTotalWithCurrency()})</s> ${this.cartItem.orderItem.grandTotalWithCurrency()} </span>` : `<span>(${this.cartItem.orderItem.grandTotalWithCurrency()})</span>`

  }
}