class ExtraOption{
  constructor(options){
    this.id = options.id;
    this.extra_id = options.extra_id;
    this.active = options.active;
    this.name = options.name;
    this.price = options.price;
  }  
}

export  class ExtraOptionView{
  constructor(options){
    this.extraOption = new ExtraOption(options.extraOption);
    this.extra =  options.extra;
    this.isNewRecord = options.isNewRecord;
    this.container = $(options.element);
    this.shopId = options.shopId;
    this.branchId = options.branchId;
    this.reservation_id = options.reservation_id;
    this.nameField = $(this.container).find('.name');
    this.activeField = $(this.container).find('.active');
    this.priceField = $(this.container).find('.price');
    this.isRemoved = false;
    this.bindDelete();
  }


  submit(cb){
    console.log("options submit");
    var _this = this;
    this.hideErrors();
    this.showLoadingWheel();
    $.ajax({
      url: this.isNewRecord ? _this.insertUrl() : _this.updateUrl(),
      method: this.isNewRecord ? "post" : "put",
      data: this.data(),
      async: true,
      cache: false,
      contentType: false,
      processData: false,
      timeout: 60000,
      success: function(status, data, xhr){
        this.showCheckMark();
        this.reload(xhr.responseJSON.option);
        cb();
      }.bind(this),
      error: function(res, status, errors){
        this.showErrors(res.responseJSON.errors);
        this.hideLoadingWheel();
        cb();
      }.bind(this)
    })
  }

  data(){
    var formData = new FormData();
    if(this.nameField.val()) formData.append("option[name]", this.nameField.val());
    if(this.priceField) formData.append("option[price]", this.priceField.val());
    if(this.activeField) formData.append("option[active]", this.activeField.is(':checked'));
    return formData;
  }

  checkChangedFields(){
    return (this.isNewRecord || 
            this.priceField.val() != this.extraOption.price ||
            this.nameField.val() != this.extraOption.name ||
            Boolean(this.activeField.is(':checked')) !=    this.extraOption.active
      )
  }

  baseUrl(){
    return `/moderator/shop/${this.shopId}/branches/${this.branchId}/menu_items/${this.menuItemId}/extras/${this.extra.id}/options/`
  }

  insertUrl(){
    return `${this.baseUrl()}${this.queryParams()}`
  }

  updateUrl(){
    return `${this.baseUrl()}${this.extraOption.id}${this.queryParams()}`
  }

  queryParams(){
    return `?reservation_id=${this.reservation_id}&update_by_reference=${$('#update_by_reference').is(":checked")}`
  }

  reload(option){
    this.isNewRecord = false;
    this.extraOption = new ExtraOption(option);
  }

  bindDelete(){
    $(this.container).find('.delete_option').on('click', function (e) {
      e.preventDefault();
      this.isNewRecord ? this.removeRow() : this.remoteDelete();
    }.bind(this));

  }

  remoteDelete(){
    var confirmed = confirm("Are you sure you want to delete this option?");
    if(!confirmed) return;
    $.ajax({
      url: this.updateUrl(),
      type: "delete",
      dataType: "json",
      data: { "update_by_reference": $('#update_by_reference').is(":checked") },
      success: function () { this.removeRow(); }.bind(this),
      error: function () { $(".alert-danger.error").show(); }
    })    
  }

  //// to do move to ui helper 
  removeRow(){
    this.container.fadeOut();
    this.isRemoved = true;
  }


  showErrors(errors){
    this.hideLoadingWheel();
    console.log("show errors");
    this.container.find('.errors-container').removeClass('hidden');
    errors.forEach(function(err){
      var element = `<div class="alert alert-danger" role="alert">${err}</div>`;
      this.container.find('.error-list').append(element);
    }.bind(this));
  }

  hideErrors(){
    this.container.find('.error-list').html('');
    this.container.find('.errors-container').addClass('hidden');
  }

  showLoadingWheel(){
    this.hideLoadingWheel()
    this.container.find('.circle-loader').show();
    this.container.find('.circle-loader').removeClass('load-complete');
    this.container.find('.checkmark').hide();
  }

  showCheckMark(){
    this.container.find('.circle-loader').addClass('load-complete');
    this.container.find('.checkmark').show();
    setTimeout(function(){ this.hideLoadingWheel() }.bind(this), 3000);
  }

  hideLoadingWheel(){
    this.container.find('.circle-loader').hide();
    this.container.find('.circle-loader').removeClass('load-complete');
    this.container.find('.checkmark').hide();
  }

}