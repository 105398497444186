module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"row\">\n  <div class=\"col-sm-12\" >\n      <div class=\"nav-parent\">\n        <ul class=\"nav nav-tabs categories-tab\" role=\"tablist\">\n          ")
    ;  branch.categories.forEach(function(category){
    ; __append("\n          <li \n             data-bs-toggle=\"collapse\" \n             data-bs-target=\"#")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-category-")
    ; __append(escapeFn( category.id ))
    ; __append("\" \n             class=\"nav-item\"\n             >\n            <input class=\"category_id\" id=\"")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-category-checkbox-")
    ; __append(escapeFn( category.id))
    ; __append("\" type=\"checkbox\" name=\"category\" value=\"")
    ; __append(escapeFn( category.id ))
    ; __append(" \">\n            <button type=\"button\" class=\"btn category\" >")
    ; __append(escapeFn( category.name ))
    ; __append("</button>\n          </li>\n          ")
    ; })
    ; __append("\n        </ul>\n      </div>\n\n      <div class=\"tab-content\">\n              ")
    ;  branch.categories.forEach(function(category){
    ; __append("\n              <div id=\"")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-category-")
    ; __append(escapeFn( category.id ))
    ; __append("\" class=\"collapse\">\n                <table class=\"table table-striped \" cellspacing=\"0\" width=\"100%\">\n                  <thead>\n                    <tr>\n                      <th class=\"th-sm\">select</th>\n                      <th class=\"th-sm\">image</th>\n                      <th class=\"th-sm\">menu item</th>\n                      <th class=\"th-sm\">Extras</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ")
    ;  category.menu_items.forEach(function(item){ 
    ; __append("\n                    <tr>\n                      <td><input type=\"checkbox\" id=\"")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-menu-item-id-")
    ; __append(escapeFn( item.id ))
    ; __append("\" class=\"menu-item\"\n                          value=")
    ; __append(escapeFn( item.id ))
    ; __append("> </td>\n                      <td><img src=\"")
    ; __append(escapeFn( item.thumb_url ))
    ; __append("\" height=\"42\" width=\"42\"> </td>\n                      <td>")
    ; __append(escapeFn( item.name ))
    ; __append(" </td>\n                      ")
    ; if(item.extras.length > 0) { 
    ; __append("\n                      <td>\n                        <button type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-extras-")
    ; __append(escapeFn( item.id))
    ; __append("\"\n                          class=\" btn-xs btn-info\">Extras</button>\n              \n                        <div id=\"")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-extras-")
    ; __append(escapeFn( item.id))
    ; __append("\" class=\"collapse\">\n                          ")
    ; item.extras.forEach(function(extra){ 
    ; __append("\n                          <ul class=\"nonstyled_list\">\n                            <li>")
    ; __append(escapeFn( extra.name ))
    ; __append("<input type=\"checkbox\" name=\"extra\"\n                                id=\"")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-menu-item-extra-")
    ; __append(escapeFn( extra.id ))
    ; __append("\" class=\"menu-item-extras\"\n                                value=\"")
    ; __append(escapeFn( extra.id ))
    ; __append("\"></li>\n                            ")
    ;  if(extra.options.length > 0) { 
    ; __append("\n                            <button type=\"button\" data-bs-toggle=\"collapse\"\n                              data-bs-target=\"#")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-options-")
    ; __append(escapeFn( extra.id ))
    ; __append("\"\n                              class=\"btn-xs btn-info\">Options</button>\n                            <div id=\"")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-options-")
    ; __append(escapeFn( extra.id ))
    ; __append("\" class=\"collapse\">\n                              <strong>Options</strong>\n                              <ul class=\"nonstyled_list\">\n                                ")
    ;  extra.options.forEach(function(option){ 
    ; __append("\n                                <li>")
    ; __append(escapeFn( option.name ))
    ; __append("<input type=\"checkbox\" id=\"")
    ; __append(escapeFn( data_target_prefix ))
    ; __append("-option-")
    ; __append(escapeFn( option.id ))
    ; __append("\"\n                                    name=\"option\" class=\"options_ids\" value=\"")
    ; __append(escapeFn( option.id ))
    ; __append("\" </li>\n                                  ")
    ;  })
    ; __append("\n                              </ul>\n                            </div>\n                            ")
    ; }
    ; __append("\n                                        </ul>\n                                      ")
    ;  })
    ; __append("\n                        </div>\n              \n                      </td>\n                      ")
    ; }
    ; __append("\n                              </tr>\n                            ")
    ; })
    ; __append("\n                  </tbody>\n                </table>\n              </div>\n              ")
    ; })
    ; __append("\n      </div>\n\n  </div>\n</div>\n")
  }
  return __output;

}