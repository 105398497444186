import { options } from "toastr";
import { ImageFile } from "./menu_item";

export class uploadShopLogo{

  constructor(options){
    this.userUpdatePath = options.userUpdatePath;
  }
  
  bindUploadBranchLogoImage(){
    var _this = this;
    $('.branch-user-logo-form').on('click', function(e){
      $('#user-logo-file-upload').trigger('click');
    });

    $('#user-logo-file-upload').on('change', function(){
      var file = $(this).get(0).files[0];
      var imageFile = new ImageFile(file);
      imageFile.enhanceFile(function(file){ 
        $('.user-logo').attr('src', URL.createObjectURL(file));
        _this._update_user_profile(imageFile);
      });
    });
  }
 
  _update_user_profile(imageFile){
    var formData = new FormData();
    formData.append("user[image]", imageFile.file, imageFile.getName());
    formData.append("user_id", this.user_id);
     $.ajax({
      url: `${this.userUpdatePath}`,
      data: formData,
      dataType: "JSON",
      cache: false,
      contentType: false,
      processData: false,
      method: 'put',
      success: function(data){},
      errors:function(status, data, xhr){}
    })
  }
}