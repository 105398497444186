import { WorkingDay } from '../../utilits/working_day';
  
const workingHoursTemp = require('../../templates/working_hours')
export class workingHoursView{

  constructor( options = {} ){
    this.type = "on-hours"
    this.containerElement = options.containerElement;
    this.branchFormId = options.branchFormId;
    this.branch_id = options.branch_id;
    this.branchOnHoursFiledId = options.branchOnHoursFiledId || `${this.branch_id}-branch_on_hours`
    this.on_hours = options.on_hours;
    this.dayElementClassName = options.dayElementClassName;
    this.daysOfWeek = options.daysOfWeek;
    this.workingHourContainerClassName = options.workingHourContainerClassName || '.working-hours';
    this.onSubmit = options.onSubmit || this.onSubmit;
  } 

  renderUI(){
    this.containerElement.find('form').find(this.workingHourContainerClassName).append(    
        workingHoursTemp({daysOfweek: this.daysOfWeek, branch_id: this.branch_id, type: this.type, hideTillDropDown: false})
    );
  }
  
  bindOnSubmit(cb){
    $(this.containerElement).on("submit", this.branchFormId, function(e){      
      cb(e);
    }.bind(this));
  }

  delegateEvents(){
    var _this = this;
    $(this.containerElement).on("change", `#sun-${this.type}-timeFrom.timeFrom`, function(e){
      var new_val = $(e.currentTarget).val();
      $(_this.workingHourContainerClassName).find(".timeFrom").val(new_val);
    });

    $(this.containerElement).on('change', `#sun-${this.type}-timeTill.timeTill`, function(e){
      var new_val = $(e.currentTarget).val();
      $(_this.workingHourContainerClassName).find(".timeTill").val(new_val);
    });

  }

  workingDays(){
    return _.map($(this.containerElement).find(this.workingHourContainerClassName).find(this.dayElementClassName), function(element){
      var day = new WorkingDay(element);
      return day;
    });
  }

  setCurrentWorkingHours(){
    _.each(this.on_hours, function(day){
     _.find(this.workingDays(), function(dayObject){
        return dayObject.__id == day.day;
      }).renderUI(day);
    }.bind(this));
  }

  serializeWorkingHours(){
    return _.map(this.workingDays(), function(day){
      return day.serialize();
    });
  }

  appendBranchOnHoursHiddenFiled(){
    $(this.containerElement).find('form').append(`<input type="hidden" name="branch[on_hours]" id=${this.branchOnHoursFiledId} value="[]">`)
  }
}

