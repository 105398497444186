import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { Tooltip } from 'bootstrap';


class CalenderEvents {
  // [{ id: "event-task-id", title: 'new event change size', start: '2022-08-29', end:'2022-08-29', allDay: true  }]
   constructor(options){
    this.start = options.date || options.created_at;
    this.end = options.date || options.created_at;
    this.title = options.title;
    this.AllDay = true;
    this.id = `${options.id}-task`;
    this.taskId = options.id;
    this.ticketId = options.ticket_id;
    this.editPath = options.editPath;
  }
}

export class AgentTaskCalender{

  constructor(options){
    this.events = _.map(options.events, function(event){ return new CalenderEvents(event) });
    this.calendarEl = document.getElementById(options.calendarEl);
  }

  start(){

    let calendar = new Calendar(this.calendarEl, {
      timeZone: 'UTC',
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      themeSystem: 'bootstrap',
      editable: true,
      dayMaxEvents: true, // when too many events in a day, show the popover
      initialView: 'listWeek',
      slotDuration: "00:15:00",
      headerToolbar: {
        left: 'prev next today',
        center: 'title',
        right: 'listWeek timeGridDay timeGridWeek dayGridMonth'
      },
      dateClick: function(info){
        this.changeView('timeGridDay', info.dateStr);
      },
      events: this.events, //[{ id: "event-task-id", title: 'new event change size', start: '2022-08-29', end:'2022-08-29', allDay: true  }],
      eventDrop: function(info){
          this._updateEvent(info.oldEvent.extendedProps.ticketId, info.oldEvent.extendedProps.taskId, info.event.start.toISOString())
      }.bind(this),
      eventDidMount: function(info) {
        var tooltip = new Tooltip(info.el, {
          title: info.event.title,
          placement: 'top',
          trigger: 'hover',
          container: 'body'
        });
      },
      eventClick: function(info) {
        $.ajax({
          url: info.event.extendedProps.editPath, //`/moderator/tickets/${info.event.extendedProps.ticketId}/agent_tasks/${info.event.extendedProps.taskId}/calendar_task_edit.js`,
          data: {without_date: false},
          method: "get",
          success: function(){},
          errors: function(){}
        });
      },

    });
  
    calendar.render();
    
    $('body').on('ajax:success','.edit_agent_task', function(e, data, xhr){
      var event = calendar.getEventById(`${data.task.id}-task`)
      if(data.task.status == 1) {
        event.remove() 
        $('#add-task').modal('hide');
      }else{
        window.location.reload();
      }    
    }.bind(this));
  }

  _updateEvent(ticketId, taskId, new_date){
    $.ajax({
      url: `/moderator/tickets/${ticketId}/agent_tasks/${taskId}.json`,
      dataType: "json",
      method: "patch",
      data: {"agent_task[date]": new_date},
      success: function(){},
      errors: function(){}
    });
  }  
}