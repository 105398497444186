import { concatSeries, timesSeries } from "async";
import { options, success } from "toastr";
import _, { min } from "underscore";
import { DrivuMap } from "../../utilits/drivu_map";
import { DrivuSocketIO } from "../../utilits/drivu_socket_io";
import { Order } from "../../utilits/order";
import { Navigation, TestingOrder } from "../../utilits/testing_order";
import { OrderView } from "../order_view";
import { PushNotification } from "../../utilits/push_notification";

const orderPreViewTemp = require("../../templates/order_preview");
const orderListTemp = require("../../templates/pending_shop_order_list");

const LIST_TYPE = ['PendingAcceptance', 'OnTheWay', 'Arrived', 'Left', 'PreOrder' ];

// to use return navigation and socket_url with orders list
class SimulateUserMovement{
  start(orderView, map){
    orderView.navigation = new Navigation(orderView.order.navigation);
    orderView.navigation.polyline.setMap(map);
    var moveOrder = new TestingOrder({branch: orderView.order.branch, user: orderView.order.user, distance: 40, map: map });
    moveOrder.navigation = orderView.navigation;
    moveOrder.marker = orderView.marker;
    moveOrder.connect();
    moveOrder.startDriving();
  }
}

class DriverLocation {
 
  constructor(options){
    this.orderView = options.orderView;
    this.distance = options.distance;
    this.orderView.listType = this.getDriverType();
  }

  //PendingAcceptance, OnTheWay, Left, Arrived, PreOrder
  getDriverType(){

    if(this.orderView.order.status == "accepted") {
      return "PendingAcceptance";
    }

    if(this.distance == undefined){
      this.calculateDistance();
    }

    if(this.orderView.distance >= 300 && this.orderView.userArrived) {
      this.orderView.left_at = this.orderView.left_at ? this.orderView.left_at : new Date().getTime() 
      return "Left"
    }

    if(this.orderView.distance <= 100 || this.orderView.userArrived) { 
      this.orderView.userArrived = true;
      this.orderView.entered_at = this.orderView.userArrived ? this.orderView.entered_at : new Date().getTime();
      return 'Arrived'
    }

    return "OnTheWay";
  }

  calculateDistance(){
    // from payload location to branch location
    var  distanceMeter = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng({
        lat: parseFloat(this.orderView.order.branch.parking_latitude),
        lng: parseFloat(this.orderView.order.branch.parking_longitude) }),
      this.orderView.marker.getPosition()
    );

    this.orderView.distance = distanceMeter;
    this.distance = distanceMeter;
  }  
}

class OrderListView{
  constructor(options){
    this.order = new Order(options.order);
    this.id = this.order.id;
    this.duration = options.duration;
    this.navigation = options.navigation;
    this.elementText = this.duration ? `${parseInt(this.duration / 60)} Minutes away` : "- mins away";
    this.userArrived = options.userArrived;
    this.currentOrderMap;
    this.marker = options.marker;
    this.branchMarker;
    this.distance = undefined;
    this.elementClass;
    this.listType = options.listType;
    this.cancelledStatus = 3;
    this.readyStatus = 2;
    this.inProgressStatus = 1;
  }

  appendView(){
    $(`.${this.listType}-list`).parents().show();
    $(`.${this.listType}-list`).show();
    $(`.${this.listType}-list`).append(orderListTemp({order: this.order, listType: this.listType, elementClass: this.getElementClasses(), elementText: this.getElementText()}));
    this.element =  $(".orders-list").find(`[data-order-id=${this.order.id}]`);
  }

  updateDuration(){
    this.element.find('.duration').html(`${parseInt(this.duration / 60)} Minutes away`)
  }

  updateWaitingOutSide(waitTime){
    this.element.find('.duration').html(`${this.waitingTime} mins waiting outside`);
  }

  updateLeftSinceTime(){
    this.element.find('.duration').html(`Left ${this.leftSinceTime} mins ago`);
  }

  showOrderDetails(){
    $('.order-preview').html("");
    $('.order-preview').append(orderPreViewTemp({order: this.order}));
    var oldPosition = this.marker.getPosition();
    this.currentOrderMap = new DrivuMap({elementId: 'current-order-map', mapOptions: {mapTypeId: 'satellite', mapTypeControl: true }});
    this.branchMarker = this.currentOrderMap.generateMarker(parseFloat(this.order.branch.parking_latitude), parseFloat(this.order.branch.parking_longitude), "", this.order.branch.logo_url);
    this.currentOrderMapMarker  = this.currentOrderMap.generateMarker(oldPosition.lat(), oldPosition.lng(), "", {url: 'https://drivu.s3.eu-west-1.amazonaws.com/car_icon.png'});
    this.currentOrderMap.pinMarkerToCurrentMap(this.currentOrderMapMarker);
    this.currentOrderMap.pinMarkerToCurrentMap(this.branchMarker);
    this.currentOrderMap.bounds.extend(this.branchMarker.getPosition());
    this.currentOrderMap.bounds.extend(this.currentOrderMapMarker.getPosition());
    this.currentOrderMap.fitBounds();
    $('.order-preview').toggle(true);
    $('.order-preview').css('width', '100%');
  }

  _toReject(data, cb){
    this._showConfirmDialog('dialog', true, function(cancellation_note){
      $(`#dialog`).modal('hide');
      this._updateOrderStatus(data.orderId, {status: this.cancelledStatus, cancellation_notes: cancellation_note}, function(order){
        $('#notes').val('');
        this.element.remove();
        cb(order.id);
      }.bind(this));
    }.bind(this));
  }

  _toReady(data, cb){
    this._showConfirmDialog("pickedUpdialog", false, function(){
      $(`#pickedUpdialog`).modal('hide');
      this._updateOrderStatus(data.orderId, {status: this.readyStatus}, function(order){
        this.element.remove();
        cb(order.id);
      }.bind(this));
    }.bind(this));
  }

  _toAccept(data, element, cb){
    this._updateOrderStatus(data.orderId, {status: this.inProgressStatus}, function(order){
      element.hide();
      $('.pickup-btn').show();
      this.listType = "OnTheWay";
      this.order.status = order.status;
      cb(this);
    }.bind(this));
  }
  
  getElementText(){
    if(this.newOrder) return "New Order"
    if(this.listType == 'PendingAcceptance') return "accepted"; 
    if(this.listType == 'Arrived' && this.waitingTime > 0) return `${this.waitingTime} min waiting outside`; 
    if(this.listType == 'Arrived') return `waiting outside`; 
    if(this.listType == 'Left') return
    return this.duration ? `${parseInt(this.duration / 60)} Minutes away` : "- mins away"
  }

  getElementClasses(){
    if(this.newOrder) return `new-order`
  }

  _updateOrderStatus(orderId, data, successCb){
    $.ajax({
      url: `/multi_shop_agent/shop_orders/${orderId}`,
      type: "PUT",
      dataType: "JSON",
      data: {order: data},
      success: function(data){
        successCb(data.order);
      }.bind(this),
      errors: function(){
        alert("error");
      }
    })
  } 

  _updateCurrentOrderMarker(payload){
    if(payload.user_id != this.order.user.id) return;   
    // this.currentOrderMap.marker = this.marker;
    this.currentOrderMap.moveMarker(payload.latitude, payload.longitude, this.currentOrderMapMarker);
  }

  _showConfirmDialog(id, noteRequired ,cb){
    $(`#${id}`).modal('show');
    $('.confirm').on('click', function(){
      var note = $('input[name="note"]').val();
      if(noteRequired){
        (note == "" ) ?  alert("Add Note") : cb(note);
      }else{
        cb();
      }
    });
  }

  _closeOrder(){
    $('.order-preview').toggle(false);
  }

  _removeItem(data, successCb, errorCb){
    $.ajax({
      url: `/multi_shop_agent/shop_orders/${data.orderId}/order_items/${data.orderItemId}`,
      type: "delete",
      data: {},
      dataType: 'JSON',
      success:function(data, status){
        successCb(data);
      },
      error: function(data){
        errorCb(data.responseJSON.errors);
      }
    });
  }

  appendFeedback(text){
    const el = `<p class="text-center">${text}</p>`
    $('.feedback').append(el);
  }

}

export class PendingShopOrdersView{
  
  constructor(options){
    this.currentUserId = options.currentUserId;
    this.branchLocation =  options.branchLocation;
    this.ordersMapBounds = new google.maps.LatLngBounds();
    this.arrivedOrderIds = [];
    this.leftOrderIds = [];
    this.cancelledStatus = options.cancelledStatus;
    this.readyStatus = options.readyStatus;
    this.inProgressStatus = options.inProgressStatus;
    this.orders= options.orders;
    this.ordersMap = new DrivuMap({mapOptions: {
      mapTypeId: 'satellite',
      mapTypeControl: true,
    }});
  }

  onLoadActions() {

    $('.locationLists').hide();
    $('.Arrived-list').hide();
    $('.Left-list').hide();
    
    this.addBranchMarkers();
    this.ordersMap.fitBounds();
    this.ordersViews = this.initOrdersViewWithMarkers(this.orders);
    this.drawOrdersByListTypeDuration();
    this.connectSocket();
    this.delegateOrderClick();
    this.delegateOrderDetailsBtnClick();
    this.setEnteredAtInterval();

    $('#simulateUserDriving').on('click', function(){
      _.each(this.ordersViews.OnTheWay, function(orderView){
        new SimulateUserMovement().start(orderView, this.ordersMap.map);
      }.bind(this));
    }.bind(this));
        
  }
  
  addBranchMarkers(){
    _.each(this.branchLocation, function(location){
      var marker = this.ordersMap.generateMarker(parseFloat(location.lat), parseFloat(location.lng), "", location.icone);
      this.ordersMap.pinMarkerToCurrentMap(marker);
      this.ordersMap.bounds.extend(marker.getPosition())
    }.bind(this))
  }

  delegateOrderClick(){
    $(".orders-list").on('click', "li", function(e){
      $('.orders-list  li').removeClass('highlight');
      $(e.currentTarget).toggleClass( "highlight" );
      this.currentOrderView = this._findOrderByType($(e.currentTarget).data().orderId, $(e.currentTarget).data().listType);
      this.currentOrderView.showOrderDetails();
    }.bind(this));
  }

  delegateOrderDetailsBtnClick(){
    $(".order-preview").on('click', 'button', function(e){
      var data = $(e.currentTarget).data();
      switch(data.methodName){
        case 'accept':
          this.currentOrderView._toAccept(data, $(e.currentTarget), function(){
            this.redrawOrdersViews(true);
          }.bind(this));
          break;
        case "reject":
          this.currentOrderView._toReject(data, function(order_id){
            this._removeOrder(order_id);
            this._closeOrder();
          }.bind(this));
          break
        case "ready":
          this.currentOrderView._toReady(data, function(order_id){
            this._removeOrder(order_id);
            this._closeOrder();
          }.bind(this));
        break
        case "delete-item":
          this.currentOrderView._removeItem(data, function(){
            $(e.currentTarget).parents('.order-item').remove()
          }, function(errors){
            this._appendErrors(errors);
          }.bind(this));
          break
        case "back-arrow": 
          this._closeOrder();
        break;
      }
    }.bind(this));
  }

  connectSocket() {

    var locationCb = function(payload){
      this._currentOrderView() ? (this.currentOrderView._updateCurrentOrderMarker(payload)) : (this._updateUserOrdersList(payload));
    }.bind(this);

    var newOrderCb = function(order_id){ 
      PushNotification.Sound.play();
      this._getOrder(order_id, function(order){
        this._appendNewOrder(order, socket);
      }.bind(this))
    }.bind(this)

    var orderStatusChangeListener = function(orderId, status){
      var orderView = this._updateOrderView(orderId, status)
      orderView.element.remove();
      orderView.newOrder = false;
      this.redrawOrdersViews(true);
    }.bind(this);

    var onOrderFeedbackCb = function(args){
      const order_id = args.order_id;
      const body = args.text;
      const orderView = this._findOrder(order_id)
      orderView.appendFeedback(body);
      if(orderView.order.feedbacks.indexOf(body) == -1){ orderView.order.feedbacks.push(body) } 
    }.bind(this);

    var socket = new DrivuSocketIO({
      user_id: this.currentUserId, 
      order_owner_id: this.currentUserId, 
      new_location_callback: locationCb,
      newOrderListener: newOrderCb,
      orderStatusChangeListener: orderStatusChangeListener,
      onOrderFeedbackCb: onOrderFeedbackCb
    }, function(){
      socket.examine_url(this.currentUserId);
      this._watchOrdersLocation(socket);
    }.bind(this));
  }

  _updateUserOrdersList(payload) {
    _.each(this._whereUserOrders(payload.user_id), function(orderView){
      this.ordersMap.moveMarker(payload.latitude, payload.longitude, orderView.marker);
      this.ordersMap.rotateIcon(orderView.marker, payload.orientation);
      if(payload.distance != undefined){ orderView.distance = payload.distance }
      if(payload.duration != undefined){ orderView.duration = payload.duration};
      new DriverLocation({orderView: orderView, distance: payload.distance});
      // console.log(`ID ${orderView.id} type ${orderView.listType} distance ${orderView.distance} payload duration ${payload.duration} duration ${orderView.duration} entered_at ${orderView.entered_at}`);
      this.redrawOrdersViews(false);
    }.bind(this));    
  }

  initOrdersViewWithMarkers(orders){
    return _.map(orders, function(orderOb){
     return this._buildOrderListView(orderOb)
    }.bind(this));
  }

  drawOrdersByListTypeDuration(){
    this.ordersViews = _.groupBy(this.ordersViews, 'listType');
    this.sortByDuration();
    _.each(this.ordersViews, function(ordersViews, listType) {
      _.each(ordersViews, function(orderView){
        if(orderView.element != undefined){ orderView.element.remove() };
        orderView.appendView();
      });
    });
  }

  sortByDuration(){
    _.each(this.ordersViews, function(value, listType, list) {
      list[listType] = _.sortBy(value, 'duration');
    });
  }

  redrawOrdersViews(setListType = false){
    this.ordersViews = _.flatten(
       _.map(this.ordersViews, function(orderViews, keys){
          var a = []
          _.each(orderViews, function(orderView){
            if( ['ready', 'cancelled'].indexOf(orderView.order.status) == -1 ) {
              if(setListType) { new DriverLocation({orderView: orderView, distance: orderView.distance}) }
              a.push(orderView);
             }
          });
          return a
      })
    );

    this.drawOrdersByListTypeDuration();
  }

  _watchOrdersLocation(socket){
    _.each(this.ordersViews, function(ordersViews, key){ 
      _.each(ordersViews, function(orderView){
        socket.watch_user_location(orderView.order.user.id);
      })
    });
  }
 
  _whereUserOrders(user_id){
    var arrived = _.filter(this.ordersViews.Arrived, function(orderView){ 
      return orderView.order.user.id == user_id;
    });

    var OnTheWay = _.filter(this.ordersViews.OnTheWay, function(orderView){ 
      return orderView.order.user.id == user_id
    });

    return _.flatten([arrived, OnTheWay], 1);
  }

  _findOrderByType(id, type){ return _.findWhere(this.ordersViews[type], {id: id}); }

  _findOrder(id){
    var x
   _.each(this.ordersViews, function(orderViews, key){
      _.each(orderViews, function(orderView){
        if(orderView.id == id){ 
          x = orderView;
          return
       }
      })
    });
    return x;
  }

  _currentOrderView(){
    return (this.currentOrderView !== undefined)
  }

  _closeOrder(){
    $('.order-preview').toggle(false);
    this.currentOrderView.element.removeClass('highlight')
    this.currentOrderView = undefined;
  }

  _removeOrder(){ this.currentOrderView.element.remove(); }

  setEnteredAtInterval(){
    setInterval(function(){
      var arrived = this.ordersViews.Arrived ||= []
      var left = this.ordersViews.Left  ||= []
      _.each(arrived.concat(left), function(orderView){
        if(orderView != undefined && orderView.entered_at !== undefined){
          var minutes = Math.floor( (new Date().getTime() - orderView.entered_at) / 60000 );
          orderView.waitingTime = minutes;
          orderView.updateWaitingOutSide();
        }
        if(orderView != undefined && orderView.left_at !== undefined){
          var minutes = Math.floor( (new Date().getTime() - orderView.left_at) / 60000 );
          orderView.leftSinceTime = minutes;
          orderView.updateLeftSinceTime();
        }
      });
    }.bind(this),30000);
  }

  _getOrder(order_id, successCb){
    $.ajax({
      url: `/multi_shop_agent/shop_orders/${order_id}`,
      type: "GET",
      dataType: "JSON",
      success: function(data){
        successCb(data.order);
      }.bind(this),
      errors: function(){
        alert("error");
      }
    })
  }

  _buildOrderListView(orderOb){
    var order = new Order(orderOb);
    var orderView = new OrderListView({order: order}); ;

     var car = "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";

    orderView.marker = this.ordersMap.generateMarker(
      parseFloat(orderView.order.latitude), parseFloat(orderView.order.longitude), "" ,
      {
        path: car,
        fillOpacity: 1,
        strokeColor: "white",
        fillColor: '#dc3545',
        scaledSize: new google.maps.Size(60, 60),
        anchor: new google.maps.Point(10, 25)
      });

    new DriverLocation({orderView: orderView, distance: orderView.distance});
    this.ordersMap.pinMarkerToCurrentMap(orderView.marker);
    this.ordersMap.bounds.extend(orderView.marker.getPosition());
    return orderView;
  }

  _appendNewOrder(order, socket){
    var orderView = this._buildOrderListView(order)
    orderView.newOrder = true;
    this.ordersViews.newOrders = [orderView];
    socket.watch_user_location(orderView.order.user.id);
    this.redrawOrdersViews();
  }

  _updateOrderView(order_id, status){
    var orderView = this._findOrder(order_id)
    orderView.order.status = status;
    return orderView;
  }

  _appendErrors(errors){
    $('#errorsDialog').modal("show");
    $('.errors').html("");
    _.each(errors, function(error){
      $('.errors').append(error); 
    }.bind(this));

  }
}