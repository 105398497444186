import { Cart } from "../../models/cart";
import { Order } from "../../models/order";
import { CustomerLaneOrder } from "../customer_lane_order";
import { Indoor } from "../indoor";
import { PaymentMethod } from "../payment_method";
import { OrderSource } from "./order_source";
export var toast = require('toastr')

// Customer lane 
// Indoor serving 
// Queue 

//-------------

// Differant
// order  prepare end point
// placeOrderEndPoint  
// form validation 
// after create order

class PrepareOrderResponse {

  constructor(res) {
    this.errors = res.errors;
    this.order = new Order(res.order);
    this.paymentMethods = res.payment_methods;
  }

  hasErrors() { return this.errors.length != 0; }
}

Indoor.placeOrderView = class {

  prepOrderResponse;
  placeOrderButton = '#place-order-button';
  paymentMethodRadioButtons = '.golden-radio';
  addCCButton = '#add-cc-button'
  creditCardSection = '#credit-card-section'
  cashSection = '#cash-section'
  applePaySection = '#apple-pay-section'
  saveCardCheckbox = '#save-card-checkbox'
  changeCreditCardButton = '.change-cc-button'
  orderSection = '#order-section'
  checkedPaymentMethod = '.payment-method-radio-buttons:checked'
  plateNumber = '#plate-number'
  phoneNumber = '#phone-number'
  customerName = '#customer-name'
  preparationTime = '#preparation-time'

  editOrderPreview= "#edit-your-order"

  activeOrderServingPoint = ".order-serving-point-btn.active"


  static branch;
  static placeOrderModal = '#place-order-modal'

  static endPoint = function(){
    return '/indoors/orders/prepare';
  }

  static placeOrderEndPoint = function(){
    return '/indoors/orders.js';
  }

  static creditCardEndPoint = '/credit_cards'

  static show() {
    $(this.placeOrderModal).modal('show');
    this.render();
  }

  static ui() { return this.pool[0].ui; }
  static shared() { 
    console.log('share');
    console.log(this.pool);
    return this.pool[0]; 
  }

  static render() {
    $.ajax({
      url: this.endPoint(),
      type: 'POST',
      data: {
        'order[branch_id]': Cart.branch.id,
        'order[items_json]': JSON.stringify(Cart.params())
      }
    });
  }

  constructor(ui) {
    this.ui = ui;
    $(this.placeOrderButton).on('click', () => { this.placeOrderButtonPressed() });
    this.refId = undefined
    this.processCode = '123'
    O.on('PaymentMethod.ApplePay.created', (instance) => {
      instance.setDelegate({
        paymentWasAuthorized: (appleToken) => {
          this.applePayToken = appleToken;
          this.placeOrderButtonPressed()
        }
      })
    })
  }

  bindListeners() {
    $(this.paymentMethodRadioButtons).on('change', (e) => {    
      this.expandPaymentResource();
    });

    $(this.addCCButton).on('click', () => {
      var creditCardView = O(this.creditCardSection)

      creditCardView.setDelegate({
        gotPaymentUrl: (url, processCode) => { if(processCode) this.processCode = processCode },
        iframeWasCreated: (iframe) => {},
        paymentWasAuthorized: (refId, creditCard) => {
          this.refId = refId
          if(this.shouldSaveCreditCard()) this.saveCreditCard(creditCard)
          this.placeOrderButtonPressed()
        },
        paymentFailed: (error) => { toast.error(error) }
      })
      
      creditCardView.load('order', Cart.grandTotal(), Cart.currency())
    });

    $(this.changeCreditCardButton).on('click', () => { $('.flip-card-inner').toggleClass('flipped') })


    O.on('Order.posted', (data) => {
      if(data.errors.length == 0) {
        Cart.clear();
        $(this.constructor.placeOrderModal).modal('hide');
        toast.success('You order is created', '', {"positionClass": "toast-top-center"})
        this.order = data.order //new Order(data.order)
        this.afterCreate();
      } else {
        console.log(data.errors);
        toast.error(data.errors.join('\n'))
      }
    })



    // $(this.editOrderPreview).on('click', (e) => { 
    //   e.preventDefault();
    //   $(Indoor.placeOrderView.placeOrderModal).modal('hide'); 
    // });

  }

  expandPaymentResource() {
    $(this.paymentMethodRadioButtons).get().forEach((e) => {
      const events = ['hide', 'show'];
      $(e).parents('.payment-method-row').find('.collapse').collapse(events[+ e.checked]);
      $(e).parents('.payment-method-row').find('.golden')[events[+ e.checked]]()
      $(e).parents('.payment-method-row').find('.collapse').find('.solid')[events[+ !e.checked]]()
      $(e).parents('.payment-method-row').find('.cash-textfield').toggleClass('cash-textfield-checked');
    })
  }

  loadBranch(res, html) {
    this.prepOrderResponse = new PrepareOrderResponse(res);
    O.on('Indoor.OrderPreview.created', (instance) => { instance.loadOrder(this.prepOrderResponse.order) })
    $(this.placeOrderButton).attr('value', `Place Order (${this.prepOrderResponse.order.currency} ${this.prepOrderResponse.order.total})`)
    this.hideLoadingWheel();
    $(this.ui).find('.content').html(html);
    this.bindListeners();
    this.loadPlaceOrderButton();
    // this.drowAndSelectPaymentMethods();
  }

  hideLoadingWheel() { $(this.ui).find('.spinner').hide(); }

  loadPlaceOrderButton() {
    if(!this.prepOrderResponse.hasErrors()) {
      $(this.placeOrderButton).show();
    } else {
      toast.error(this.prepOrderResponse.errors.join('\n'));
      $(this.placeOrderButton).hide();
    }
  }

  placeOrderButtonPressed() {
    if(!this.isValidSession()) {
      // $(this.constructor.placeOrderModal).modal('hide');
      return;
    }

    var data = {
      "order[cash_amount]": this.getCashAmount(),
      "order[payment_method]": this.getPaymentMethod(),
      "order[branch_id]": Cart.branch.id,
      "order[items_json]": JSON.stringify(Cart.params()),
      "order[plate_number]": $(this.plateNumber).val(),
      "order[phone_number]": $(this.phoneNumber).val(),
      "order[customer_name]": $(this.customerName).val(),
      "order[branch_serving_point_id]": $(this.activeOrderServingPoint).data('id')
    }

    if(this.refId) {
      data['order[process_code]'] = this.processCode
      data['order[transaction_id]'] = this.refId
    }

    if(this.selectedPaymentMethodName() == 'credit_card') {
      var creditCardView = O(this.creditCardSection)
      if(creditCardView.creditCardId) data['order[card_id]'] = creditCardView.creditCardId
    }

    if(this.applePayToken) {
      data['order[apple_token]'] = this.applePayToken
    }

    $.ajax({
      url: this.constructor.placeOrderEndPoint(),
      type: 'POST',
      dataType: "script",
      data: data
    });
  }

  getCashAmount() { return O(this.cashSection).getCashAmount() }
  getPaymentMethod() { return this.selectedPaymentMethodName() != 'cash' }
  isValidSession() { 
    return this.isAbleToOrder() && this.isValidPaymentMethod() && this.isValidCashAmount() && this.isValidCreditCardPayment()
  }

  isValidCreditCardPayment() {
    if(this.selectedPaymentMethodName() == 'credit_card' && !O(this.creditCardSection).creditCardId && !this.refId) {
      toast.error('Please add a valid credit card')
      return false
    } else return true
  }

  isValidCashAmount() {
    const cashAmount = this.getCashAmount();
    if(this.selectedPaymentMethodName() && cashAmount != '' && !cashAmount.isNumeric()) {
      toast.error('Invalid cash amount')
      return false
    } else return true 
  }

  isAbleToOrder() {
    if(this.prepOrderResponse.hasErrors()) {
      toast.error(this.prepOrderResponse.errors.join('\n'));
      return false
    } else return true
  }

  isValidPaymentMethod() {
    if($(this.checkedPaymentMethod).data('type') == undefined) {
      toast.error('Please select a payment method')
      return false
    } else return true
  }

  shouldSaveCreditCard() { return $(this.saveCardCheckbox).get(0).checked }
  selectedPaymentMethodName() { return $(this.checkedPaymentMethod).data('type') }

  /*
    {
      "type": "Visa Credit",
      "last4": "1111",
      "country": "AE",
      "first6": "411111",
      "expiry": {
          "month": 12,
          "year": 2024
      }
    }
  */
  saveCreditCard(card) {
    
    const data = {
      'card[number]': card.last4,
      'card[card_type]': card.type,
      'card[ref_id]': this.refId,
      'card[country]': card.country,
      'card[expiry_month]': card.expiry.month,
      'card[expiry_year]': card.expiry.year,
      'card[in_use]': true
    }
    
    $.ajax({
      type: 'POST',
      url: Indoor.placeOrderView.creditCardEndPoint,
      data: data
    })
  }

  isPlateNumberPresent(){
    var letters = /^[A-Za-z]+$/;
    if( _.isEmpty( $(this.plateNumber).val() ) ){ 
      toast.error('Please add a plate number');
      this.appendError('Please add a plate number');
      return false
    } else if ($(this.plateNumber).val().match(letters)){
      toast.error('Please add a plate number with numbers');
      this.appendError('Please add a plate number with numbers');
      return false
    } else {
      return true
    }
  }

  isPhoneNumberPresent(){
    if( _.isEmpty( $(this.phoneNumber).val() ) ){ 
      toast.error('Please Add an Phone number');
      return false
    }else{
      return true
    }
  }

  appendError(message){
    $(this.ui).find('.errors-con').find('ul').empty();
    $(this.ui).find('.errors-con').removeAttr('hidden');
    $(this.ui).find('.errors').append(`<li>${message}</li>`);
  }

  afterCreate(){
    window.location.pathname = '/'
  }


  // drowAndSelectPaymentMethods(){
  //   // O.on('PaymentMethod.ApplePay.created', (instance) => { PaymentMethod.showPaymentMethod(instance); });
  //   // O.on('PaymentMethod.CreditCard.created', (instance) => { PaymentMethod.showPaymentMethod(instance); });
  //   // O.on('PaymentMethod.Cash.created', (instance) => {  PaymentMethod.showPaymentMethod(instance); });
  //   // if( PaymentMethod.defaultPaymentMethodNotSupported() ){
  //   //   O.on('PaymentMethod.Cash.created', (instance) => {  instance.select();});
  //   // }
  // }
}

Indoor.CustomerLane.PlaceOrderView = class extends Indoor.placeOrderView {

  static endPoint = function(){
    return `/customer_lane/branches/${this.branch.id}/orders/prepare`;
  }

  static placeOrderEndPoint = function(){
    return `/customer_lane/branches/${this.branch.id}/orders.js` 
  }

  static orderDetailsEndPoint = function(){
    return `/customer_lane/branches/${this.branch.id}/orders/${Indoor.CustomerLane.OrderDetailsView.getTraceToken()}`;
  }

  defaultFouceElement(){
    $(this.plateNumber).get(0).focus();
    $(this.plateNumber).get(0).click();
  }
 

  bindListeners(){
    super.bindListeners();
    this.defaultFouceElement();
  }

  isValidSession() { 
    return super.isValidSession() && this.isPlateNumberPresent() && this.isPhoneNumberPresent();
  }

  afterCreate(){
    Indoor.CustomerLane.OrderDetailsView.saveTraceToken(this.order.trace_token)
    window.location.pathname = this.constructor.orderDetailsEndPoint();
  }
}

Indoor.OrderServing.PlaceOrderView = class extends Indoor.placeOrderView {
 
  static endPoint = function(){
    return `/order_serving/branches/${this.branch.id}/orders/prepare`;
  }

  static placeOrderEndPoint = function(){
    return  `/order_serving/branches/${this.branch.id}/orders.js`
  }

  static orderDetailsEndPoint = function(){
    return `/order_serving/branches/${this.branch.id}/orders/${Indoor.OrderServing.OrderDetailsView.getTraceToken()}`;
  }

  defaultFouceElement(){
    $(this.phoneNumber).get(0).focus();
    $(this.phoneNumber).get(0).click();
  }
 
  bindListeners(){
    $('.order-serving-points').on('click', '.order-serving-point-btn', function(){
      $(".order-serving-points div").removeClass("active");
      $(this).addClass("active");
    });
  
    super.bindListeners();
    this.defaultFouceElement();
    $(this.preparationTime).text(`Avg.order preperation: ${this.prepOrderResponse.order.branch.preparationTime} mins `); 

  }

  isCustomerNamePresent(){
    if( _.isEmpty( $(this.customerName).val() ) ){ 
      toast.error('Please Add Your Name');
      return false
    }else{
      return true
    }
  }

  isServingPointPresent(){
    if( $(this.activeOrderServingPoint).length < 1 ){ 
      toast.error('Please Add Serving point');
      return false
    }else{
      return true
    }

  }
  isValidSession() { 
    return super.isValidSession() && super.isPhoneNumberPresent() && this.isCustomerNamePresent() && this.isServingPointPresent();
  }

  afterCreate(){
    Indoor.OrderServing.OrderDetailsView.saveTraceToken(this.order.trace_token)
    window.location.pathname = this.constructor.orderDetailsEndPoint();
  }
  
}

