module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"row pickup-time-selector\">\n  <div class=\"col-sm-12\">\n    <label>Select Order pickup time</label>\n    <select class=\"form-select form-select-sm mb-3\">\n      <option></option>\n      ")
    ;  branch.pickup_hours.forEach(function(pickup_hour){ 
    ; __append("\n        <option value=")
    ; __append(escapeFn( pickup_hour.date_time ))
    ; __append(">")
    ; __append(escapeFn( `${pickup_hour.day_name} ${pickup_hour.day_date}` ))
    ; __append("</option>\n      ")
    ;  })
    ; __append("\n    </select>\n  </div>\n</div>")
  }
  return __output;

}