import { times } from "underscore";
import { GoogleMap } from "../utilits/google_map";

// rename to branch location maps
export class ShopBranchesView{
  constructor(options){
    this.branch_map_id = options.branch_map_id;
    this.parking_map_id = options.parking_map_id;    
    this.branch = options.branch;
    this.predected_address = JSON.parse(options.predected_address);
    this.branch_parking_latitude_box = $(`#${options.branch_parking_latitude_box}`),
    this.branch_parking_longitude_box = $(`#${options.branch_parking_longitude_box}`);
    this.branch_latitude_box= $(`#${options.branch_latitude_box}`);
    this.branch_longitude_box =  $(`#${options.branch_longitude_box}`);
  }

  initListener(){
    this.google_map = this.generateBranchLocationMap();
    this.generateParkingMap((this.branch.parking_latitude || this.predected_address.lat || 0), (this.branch.parking_longitude || this.predected_address.lng || 0));
  }

  generateBranchLocationMap(){
    new GoogleMap({
      elementId: this.branch_map_id,
      latitude_box: this.branch_latitude_box,
      longitude_box: this.branch_longitude_box,
      lat: parseFloat(this.branch.latitude || this.predected_address.lat || 0) ,
      lng: parseFloat(this.branch.longitude || this.predected_address.lng || 0),
      zoomLevel: 19,
      mapTypeId: "hybrid",
      after_on_click_listener: function(event, marker){
        $(this.parking_map_id).show();
        if(this.parking_map === undefined){
          this.generateParkingMap(event.latLng.lat(), event.latLng.lng());
        }else{
          this.parking_map.marker.setPosition(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
        }
        this.branch_parking_latitude_box.val(event.latLng.lat());
        this.branch_parking_longitude_box.val(event.latLng.lng());
        this.parking_map.circle.setCenter(event.latLng);
        this.parking_map.move_camera_to_location(event.latLng.lat(), event.latLng.lng());
      }.bind(this)
    });
  }

  generateParkingMap(lat, lng){
    this.parking_map = new GoogleMap({
      elementId: this.parking_map_id,
      lat: parseFloat(lat || 0),
      lng: parseFloat(lng || 0),
      radius: 25,
      fillColor: '#00FF00',
      zoomLevel: 19,
      mapTypeId: "hybrid",
      click_listener: function(event, marker){
        this.#addCircle(event);
      }.bind(this),
      after_on_click_listener: function(event, marker){
        console.log('click lisener')
      }
    });

    google.maps.event.addListener(this.parking_map.circle, 'click', (event) => {
      console.log('on circle click');
      this.#addCircle(event);
    });
  }

  #addCircle(event){
    console.log('add cicle')
    this.branch_parking_latitude_box.val(event.latLng.lat());
    this.branch_parking_longitude_box.val(event.latLng.lng());
    var latlng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
    this.parking_map.marker.setPosition(latlng);
    this.parking_map.circle.setCenter(latlng);
  }
}

