
export class ModeratorBranchesView {
  constructor(options) {

    Object.keys(options).forEach(function (key) {
      this[key] = options[key]
    }.bind(this));

    this.bindListeners();
  }

  bindListeners() {
    var _this = this;

    $('.selectpicker-multi-agent').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      var option = $(this).find('option').eq(clickedIndex);
      var agentId = $(option).val();
      var branchId = $(option).parents()[0].id.split('-')[1];
      $.ajax({
        url: `/moderator/shop/${_this.restaurant_id}/branches/${branchId}.json`,
        type: 'put',
        data: {
          'branch[multi_shop_agent_id]': agentId
        },
        success: function () { Packs.application.toastr.success("update successfully"); },
        error: function () { Packs.application.toastr.error("Something went wrong"); }
      });
    });

    $('.selectpicker-loyalty').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      var option = $(this).find('option').eq(clickedIndex);
      var category_id = $(option).val();
      var branch_id = $(option).parents()[0].id.split('-')[1];
      $.ajax({
        url: `/branches/${branch_id}/categories/${category_id}.json`,
        type: 'put',
        data: {
          'category[loyalty]': isSelected
        },
        success: {},
        error: {}
      });
    });

    $('.selectpicker-tags').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      var selected = $(this).find("option:selected");
      var arrSelected = [];
      selected.each(function () {
        arrSelected.push($(this).val());
      });

      var branch_id = $(this).attr('id').split('-').pop();
      if (arrSelected.length == 0) arrSelected.push("");
      $.ajax({
        url: `/moderator/shop/${_this.restaurant_id}/branches/${branch_id}.json`,
        type: 'put',
        data: {
          'branch[tags]': arrSelected
        },
        success: function () {
          alert("success");
        },
        error: {}
      });
    });

    $('.branch-active').on('change', function (e) {
      var branch_id = $(e.currentTarget).attr('id').split('_').pop();
      this.update_branch(_this.restaurant_id, branch_id, { active: $(e.currentTarget).is(':checked') });
    }.bind(this));

    $('.branch-hidden').on('change', function (e) {
      var branch_id = $(e.currentTarget).attr('id').split('_').pop();
      this.update_branch(_this.restaurant_id, branch_id, { hidden: $(e.currentTarget).is(':checked') });
    }.bind(this));

    $('.branch-paused').on('change', function (e) {
      var branch_id = $(e.currentTarget).attr('id').split('_').pop();
      this.update_branch(_this.restaurant_id, branch_id, { paused: $(e.currentTarget).is(':checked') });
    }.bind(this));

    $('.branch-indoor').on('change', function (e) {
      var branch_id = $(e.currentTarget).attr('id').split('_').pop();
      this.update_branch(_this.restaurant_id, branch_id, { indoor: $(e.currentTarget).is(':checked') });
    }.bind(this));
  }

  update_branch(restaurant_id, branch_id, data) {
    console.log('update branch');
    $.ajax({
      url: `/moderator/shop/${restaurant_id}/branches/${branch_id}`,
      method: 'put',
      dataType: 'JSON',
      data: { branch: data },
      success: function () {
      },
      error: function () {
      },
    });
  }
}

