import { DrivuSocketIO } from "./drivu_socket_io";

export class OrderMarker{
  constructor(order, marker, map){
    this.id = order.user_id;
    this.order = order;
    this.marker = marker;
    this.map = map;
  }

  moveTo(orderLocation, orientation = 0, user_id=0){
    this.marker.setPosition(orderLocation);
    this.map.rotateIcon(this.marker, orientation);
  }
}

export class WatchOrdersOnMap{
  constructor(options){
    this.userId = options.userId;
    this.orders = options.orders;
    this.orderMarkers = [];
    this.delegate = options.delegate || {};
    this._connectUserSocket();
    O.on('MapView.created', (mapView) => {
      this.mapView = mapView
      this._drawOrders();
    })
  }

  appendNewOrder(order){
    this.orders.push(order);
    this.appendOrderToMap(order);
    this.socket.watch_user_location(order.user_id);
  }

  _drawOrders(){
    _.map(this.orders, function(order){
      this.appendOrderToMap(order)
    }.bind(this));
  }

  _connectUserSocket(){
   this.socket = new DrivuSocketIO({
      user_id: this.userId, 
      order_owner_id: this.userId, 
      new_location_callback: this.delegate['locationChange'],
      newOrderListener: this.delegate['newOrder'],
      orderStatusChangeListener: this.delegate['orderStatusChange'],
      onOrderFeedbackCb: this.delegate['newOrderFeedBack']
    }, function(){
      this.socket.examine_url(this.userId);
      this._watchOrderUsersLocation(this.socket);
    }.bind(this));
  }

  _watchOrderUsersLocation(socket){
    _.map(this.orders, function(order){
      socket.watch_user_location(order.user_id);
    })
  }

  appendOrderToMap(order){
    
    var car = "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
    // var car2 = "M3396 5104 c-90 -22 -165 -67 -266 -162 -130 -121 -309 -317 -393-431 -39 -53 -95 -129 -124 -168 l-53 -73 -175 0 c-190 0 -217 -6 -241 -53-19 -35 -4 -88 29 -105 12 -7 68 -13 125 -14 l102 -3 -612 -611 -613 -611-112 -63 c-124 -70 -239 -165 -377 -311 -90 -96 -140 -166 -217 -299 -33 -58-85 -118 -229 -265 -108 -110 -197 -209 -211 -235 -31 -59 -33 -166 -5 -22526 -56 1395 -1425 1451 -1451 55 -26 165 -25 220 1 22 11 128 105 240 215 171167 213 202 288 244 112 62 181 113 286 212 137 129 233 245 301 367 l63 112611 613 611 612 3 -107 c3 -117 15 -144 68 -157 28 -7 78 15 90 41 5 10 11101 14 203 l5 185 155 112 c261 191 564 480 628 601 65 122 78 279 33 405 -40116 -243 417 -399 594 -77 87 -370 379 -445 442 -141 120 -377 282 -496 341-120 61 -231 74 -355 44z m-77 -1164 c187 -160 461 -434 621 -621 178 -208224 -268 208 -273 -7 -3 -48 -12 -92 -20 -127 -26 -322 -95 -456 -163 l-125-63 -20 27 c-141 194 -432 485 -628 628 l-27 20 64 127 c66 133 132 318 161453 10 44 19 86 21 93 5 16 67 -31 273 -208z m-862 -91 c-37 -98 -109 -236-168 -319 -28 -41 -149 -172 -267 -292 l-214 -216 -72 24 c-39 13 -78 27 -8630 -9 3 124 143 405 424 231 231 421 418 422 416 2 -2 -7 -32 -20 -67z m923-1784 c-179 -179 -328 -325 -331 -325 -3 0 -10 15 -16 34 l-11 34 217 214c119 118 246 236 283 262 62 44 166 105 178 106 3 0 -141 -146 -320 -325zm-1988 171 l47 -52 -227 -227 -227 -227 -55 55 -55 55 225 225 c124 124 229225 235 224 5 -1 31 -25 57 -53z m855 -848 l53 -53 -227 -227 -228 -228 -5656 -57 56 226 224 c124 123 228 224 231 224 3 0 29 -24 58 -52z"
    var marker = this.mapView.mark(order.latitude, order.longitude, "", {
      // url: "/images/truck_3418150.svg",
      path: car,
      rotation: 0,
      fillOpacity: 1,
      strokeColor: "white",
      fillColor: '#dc3545',
      scaledSize: new google.maps.Size(60, 60),
      anchor: new google.maps.Point(10, 25),
      rotation: [30, 60, 90, 180].random(),  

    });
    this.orderMarkers.push(new OrderMarker(order, marker, this.mapView))
  }
}
