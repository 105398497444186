import { DrivuMap } from './drivu_map';

export class AutocompleteServiceMap {

  constructor(options){
    
    this.mapElementId = options.mapElementId;
    this.mapOptions = options.mapOptions ||  {center: new google.maps.LatLng(25.044062, 55.187154), zoom: 13};

    this.componentRestrictions = options.componentRestrictions || {country: ['AE']}
    
    this.searchTextElement = options.searchTextElement;
    this.searchResultElement = options.searchResultElement;
    this.searchListElement = options.searchListElement
    
    this.afterFind = options.afterFind;

    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.searchResultElement.toggle(false);
  }

  loadMap() {
    this.map = new DrivuMap({
      elementId: this.mapElementId,
      mapOptions: this.mapOptions,
    });
  }

  addLocationMarkers(locations){
    _.each(locations, function(location){
      if(location.lat != null){
        var marker = this.map.generateMarker(location.lat, location.log, location.title);
        this.map.pinMarkerToCurrentMap(marker);
        this.map.moveCameraToMarker(marker);
      }
    }.bind(this))
  }

  bindSearchTextChanage(cb){
    this.searchTextElement.on('keyup click', function(e){
      ($(e.currentTarget).val().length == 0)  ? this.searchResultElement.toggle(false) : this.searchResultElement.toggle(true) 
      this.searchListElement.empty();
      this.getPlacesShop($(e.currentTarget).val(), function(predictions, status){
        this.afterFind(predictions, status, $(e.currentTarget).val());
      }.bind(this));
    }.bind(this));
  }

  getPlacesShop(searchText, cb){
    this.autocompleteService.getPlacePredictions({ input: searchText, componentRestrictions: this.componentRestrictions  }, cb);
  }

  removeResults(){
    this.searchResultElement.toggle(false) 
    this.searchListElement.empty();
  }

  appendDrivuBranchLogos(){
    this.map.appendDrivuBranchLogos();
  }

}