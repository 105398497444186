import { TestingOrder } from '../utilits/testing_order';

const tester_row = require('../templates/order_tests/tester_row')
const orderItemTemplate = require('../templates/order_item')
const testersSelectorTemptemplates = require("../templates/order_tests/testers_selector")

class OrderTestsView{
  constructor(options){
    this.markers = {};
    this.bounds = new google.maps.LatLngBounds();
    this.orders = [];
    this.testersModal = options.testersModal;
    this.userSearchResult = [];
    this.shops = options.shops; //JSON.parse('<%= j @shops.to_json(include: :branches).html_safe %>');
    this.users = options.users; //SON.parse('<%= j @testing_users.to_json(methods: :socket_url, include: :user_information).html_safe %>');
    this.map = new google.maps.Map(document.getElementById("map"), {zoom: 17, center: new google.maps.LatLng(0, 0), mapTypeId: google.maps.MapTypeId.ROADMAP, mapTypeControl: true});
    this.admins_user_informations_path = options.admins_user_informations_path;
    this.admins_users_path = options.admins_users_path;
    this.bindListeners();
  }

  bindListeners() {
    var _this = this;

    _.each(this.users, function(user){
      this.appendTester(user);
    }.bind(this));

    $('body').on('keypress', '.distanceTextField', function(e){
      if(e.which === 13 && $(this).val()){
        var user = _.find(_this.users, function(u){ return u.id == $(this).attr('id').split('-').pop()}.bind(this));

        $.ajax({
          type: 'PUT',
          url: _this.admins_user_informations_path,//'<%= admins_user_informations_path %>/' + user.id,
          data: {
            'user_information[testing_distance]': $(this).val()
          },
          success: function(response){
            user.user_information = parseInt($(this).val())
          }.bind(this)
        });
      }
    });    

    _.each(this.shops, function(shop){
      _.each(shop.branches, function(branch){

        var location = new google.maps.LatLng(parseFloat(branch.latitude), parseFloat(branch.longitude));
        this.bounds.extend(location);

        var marker = new google.maps.Marker({
          position: location,
          map: this.map,
          icon: shop.image.thumb.url,
          title: branch.name,
          branch: branch
        });

        marker.setMap(this.map);
        
        marker.addListener('click', function(){
          $('#testersTable').hide();
          if(_this.orders.length == 0) {
            _this.map.setCenter(this.getPosition());
            _this.generateOrdersForBranch(branch);
          }else {
            alert("You have already generated orders");
          }
        });

      }.bind(this));
    }.bind(this));

    this.map.fitBounds(this.bounds);

    $('#addTester').on('click', function(){

      $.ajax({
        type: 'GET',
        url: _this.admins_user_informations_path,
        success: function(testers){
          _this.userSearchResult = testers;
          var testerList = testersSelectorTemptemplates({users: testers});
          _this.testersModal.find('.modal-body').html(testerList);
          _this.testersModal.modal('show');
        }
      });
    });

    $('body').on('click', '.addTesterButton', function(){
      var userId = $(this).data('user-id');
      $.ajax({
        type: 'PUT',
        url: `${_this.admins_users_path}${userId }.json`,
        data: {
          'user[test_account]': true
        },
        success: function() {
          var user = _.find(_this.userSearchResult, function(u){ return u.id == userId});
          _this.users.push(user);
          _this.appendTester(user);
          $(this).closest('li').hide();
        }.bind(this)
      });
    });

    $('body').on('click', '.testerRemover', function(){

      var userId = $(this).data('user-id');

      $.ajax({
        type: 'PUT',
        url: '<%= admins_users_path %>/' + userId + '.json',
        data: {
          'user[test_account]': false
        },
        success: function(){
          var user = _.find(userSearchResult, function(u){ return u.id == userId});
          users = _.reject(users, function(u){ return u.id == userId });
          $(this).closest('tr').hide();
        }.bind(this)
      });
    });
  }

  appendTester(tester){
    var row = tester_row({user: tester});
    $('#testersTable').find('tbody').append(row);
  };

  generateOrdersForBranch(branch) {
    var interval = setInterval(function(){
      if(this.users.length > 0) {
        var user = this.users.shift();
        var order = new TestingOrder({
          user: user,
          branch: branch,
          distance: user.user_information.testing_distance,
          map: this.map
        });

        this.orders.push(order);
      } else {
        clearInterval(interval);
      }
    }.bind(this), 500);
  }
}

export {OrderTestsView}