module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<li href=\"#\" class=\" ")
    ; __append(escapeFn( elementClass ))
    ; __append("  py-4  list-group-item  order-")
    ; __append(escapeFn( order.status ))
    ; __append("\" data-order-id=")
    ; __append(escapeFn( order.id ))
    ; __append(" data-list-type=")
    ; __append(escapeFn( listType ))
    ; __append(">\n  <div class=\"d-flex justify-content-between align-items-center\">\n    <div>\n      ")
    ; if(order.user.connected == false) {
    ; __append("\n        <h5 class=\"m-0\"><span><i class=\"fa fa-car\" aria-hidden=\"true\"></i></span> ")
    ; __append(escapeFn( order.plate_number ))
    ; __append(" <span class=\"orange-font\">(disconnected)</span></h5>\n      ")
    ; }else{
    ; __append("\n        <h5 class=\"m-0\"><span><i class=\"fa fa-car\" aria-hidden=\"true\"></i></span> ")
    ; __append(escapeFn( order.plate_number ))
    ; __append("</h5>\n      ")
    ; }
    ; __append("\n    </div>\n  \n    <div class=\"distination\">\n      <p class=\"distance\"></p>\n      <h5  class=\"m-0 duration\">")
    ; __append(escapeFn( elementText ))
    ; __append("</h5>\n    </div>  \n  </div>\n\n  <div class=\"feedback pb-2\">\n    ")
    ;  _.each(order.feedbacks, function(feedback){ 
    ; __append("\n      <p class=\"text-center\">")
    ; __append(escapeFn( feedback ))
    ; __append("</p> \n    ")
    ;  }) 
    ; __append("\n  </div>\n\n</li>\n\n")
  }
  return __output;

}