export class RangeSplitter {

  constructor(ui) {
    this.ui = $(ui);
    this.rangeInput = $(this.ui).find('#customRange').get(0); //document.getElementById('customRange');
    this.minLabel = $(this.ui).find('#min-label').get(0); //document.getElementById('min-label');
    this.maxLabel = $(this.ui).find('#max-label').get(0); //document.getElementById('max-label');

    this.rangeInput.addEventListener('input', () => {
      const value = this.rangeInput.value;
      this.minLabel.textContent = `${value}%`;
      this.maxLabel.textContent = `${100 - value}%`;
    });
  }
}