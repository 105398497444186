module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<ul class=\"list-group\">\n  \n  ")
    ;  _.each(users, function(user){
    ; __append("\n    <li class=\"list-group-item\">")
    ; __append(escapeFn( user.username))
    ; __append(" <span class=\"pull-right\"><input type=\"button\" class=\"btn btn-success addTesterButton\" value=\"Add\" data-user-id=\"")
    ; __append(escapeFn( user.id ))
    ; __append("\"></span></li>\n  ")
    ;  })
    ; __append("\n</ul>")
  }
  return __output;

}