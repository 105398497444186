module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("\n<div class=\"row\">\n  <div class=\"col-sm-6\">\n    <h3>Reorders</h3>\n      ")
    ;  predictions.reorders.forEach(function(reorder){  
    ; __append("\n        <div class=\"row\">\n          <div class=\"col-md-2 favorite-image\">\n            <img src=\"")
    ; __append(escapeFn( reorder.image ))
    ; __append("\" width=\"100%\">\n          </div>\n          <div class=\"col-md-6 favorite-info\">\n            <p class='branch'></p><p class=\"text-primary\"><strong> ")
    ; __append(escapeFn( reorder.shop_name ))
    ; __append("</strong></p> </p>\n            ")
    ;  reorder.items.forEach(function(item){ 
    ; __append("\n              <p>")
    ; __append(escapeFn( item.quantity ))
    ; __append(" x ")
    ; __append(escapeFn( item.name ))
    ; __append("</p>\n              ")
    ; if(item.notes){
    ; __append("<p>Special Request: ")
    ; __append(escapeFn( item.notes ))
    ; __append("</p>")
    ; }
    ; __append("\n              \n              ")
    ; if(!_.isEmpty(item.extras)){
    ; __append("\n                <p class='info'>Extras</p>\n                ")
    ;  item.extras.forEach(function(extra){ 
    ; __append("\n                  <p>")
    ; __append(escapeFn( extra.name ))
    ; __append(":\n                    ")
    ;  extra.sub_extras.forEach(function(sub_extra){ 
    ; __append("\n                      <span> ")
    ; __append(escapeFn( sub_extra.name ))
    ; __append(", price: ")
    ; __append(escapeFn( sub_extra.price ))
    ; __append("</span>\n                    ")
    ;  })
    ; __append("  \n                  </p>  \n                ")
    ;  })
    ; __append("\n              ")
    ; }
    ; __append("\n\n            ")
    ;  })
    ; __append("\n            <p> total: ")
    ; __append(escapeFn( reorder.total ))
    ; __append(" </p>\n        </div><br />\n      </div>\n      ")
    ; })
    ; __append("\n  </div>\n\n  <div class=\"col-sm-6\">\n    <div>\n      <h3>Most Bought Orders </h3>\n      ")
    ;  predictions.trubleshoot_info.most_frequently_bought_reorders.forEach(function(reorder){  
    ; __append("\n      <div class=\"row\">\n        <div class=\"col-md-2 favorite-image\">\n          <img src=\"")
    ; __append(escapeFn( reorder.image ))
    ; __append("\" width=\"100%\">\n        </div>  \n        <div class=\"col-md-6 favorite-info\">\n          <p class='branch '></p><p class=\"text-primary\"><strong>")
    ; __append(escapeFn( reorder.shop_name ))
    ; __append(" </strong></p>\n          <p class='branch'></p> Count:  <strong>")
    ; __append(escapeFn( reorder.count ))
    ; __append("</strong></p>\n          ")
    ;  reorder.items.forEach(function(item){ 
    ; __append("\n            <p>")
    ; __append(escapeFn( item.quantity ))
    ; __append(" x ")
    ; __append(escapeFn( item.name ))
    ; __append("</p>\n            ")
    ; if(item.notes){ 
    ; __append("\n              <p>Special Request: ")
    ; __append(escapeFn( item.notes ))
    ; __append("</p>\n            ")
    ; }
    ; __append("\n\n            ")
    ;  if (! _.isEmpty(item.extras) ) { 
    ; __append("\n              <p class='info'>Extras</p>\n              ")
    ;  item.extras.forEach(function(extra){ 
    ; __append("\n                <p>")
    ; __append(escapeFn( extra.name ))
    ; __append(":\n                ")
    ;  extra.sub_extras.forEach(function(sub_extra){ 
    ; __append("\n                <span> ")
    ; __append(escapeFn( sub_extra.name ))
    ; __append(", price: ")
    ; __append(escapeFn( sub_extra.price ))
    ; __append("</span>\n              ")
    ;  })
    ; __append("  \n              </p>  \n              ")
    ;  })
    ; __append("   \n            ")
    ;  } 
    ; __append("\n          ")
    ;  })
    ; __append("\n          <p> total: ")
    ; __append(escapeFn( reorder.total ))
    ; __append(" </p>\n          <p> Created At: ")
    ; __append(escapeFn( reorder.created_at ))
    ; __append(" </p>\n        </div><br /><br />\n      </div>\n      ")
    ; })
    ; __append("\n    </div> \n\n    <div>\n      <h3>Latest Order</h3>\n      ")
    ;  if(Object.keys(predictions.trubleshoot_info.latest_order).length !== 0 ) {
    ; __append("\n        <div class=\"row\">\n          <div class=\"col-md-2 favorite-image\">\n            <img src=\"")
    ; __append(escapeFn(  predictions.trubleshoot_info.latest_order.image ))
    ; __append("\" width=\"100%\">\n          </div>  \n          <div class=\"col-md-6 favorite-info\">\n            <p class='branch'></p><p class=\"text-primary\"><strong>")
    ; __append(escapeFn(  predictions.trubleshoot_info.latest_order.shop_name ))
    ; __append("  </strong></p> \n            ")
    ;  predictions.trubleshoot_info.latest_order.items.forEach(function(item){ 
    ; __append("\n            <p>")
    ; __append(escapeFn( item.quantity ))
    ; __append(" x ")
    ; __append(escapeFn( item.name ))
    ; __append("</p>\n            ")
    ; if(item.notes){ 
    ; __append("\n              <p>Special Request: ")
    ; __append(escapeFn( item.notes ))
    ; __append("</p>\n            ")
    ; }
    ; __append("\n            ")
    ;  if (! _.isEmpty(item.extras) ) { 
    ; __append("\n              <p class='info'>Extras</p>\n              ")
    ;  item.extras.forEach(function(extra){ 
    ; __append("\n              <p>")
    ; __append(escapeFn( extra.name ))
    ; __append(":\n                ")
    ;  extra.sub_extras.forEach(function(sub_extra){ 
    ; __append("\n                  <span> ")
    ; __append(escapeFn( sub_extra.name ))
    ; __append(", price: ")
    ; __append(escapeFn( sub_extra.price ))
    ; __append("</span>\n                ")
    ;  })
    ; __append("  \n              </p>  \n              ")
    ;  })
    ; __append("\n            ")
    ; }
    ; __append("\n            ")
    ;  })
    ; __append("\n            <p> total: ")
    ; __append(escapeFn( predictions.trubleshoot_info.latest_order.total ))
    ; __append(" </p>\n            <p> Created At: ")
    ; __append(escapeFn( predictions.trubleshoot_info.latest_order.created_at ))
    ; __append(" </p>\n          </div><br />\n        </div>\n      ")
    ; }
    ; __append("\n    </div>\n\n    <div>\n      <h3>Nearest Order</h3>\n      ")
    ;  if(Object.keys(predictions.trubleshoot_info.nearest_order).length !== 0 ) { 
    ; __append("\n        <div class=\"row\">\n          <div class=\"col-md-2 favorite-image\">\n            <img src=\"")
    ; __append(escapeFn(  predictions.trubleshoot_info.nearest_order.image ))
    ; __append("\" width=\"100%\">\n          </div>  \n          <div class=\"col-md-6 favorite-info\">\n            <p class='branch'></p><p class=\"text-primary\"><strong>")
    ; __append(escapeFn(  predictions.trubleshoot_info.nearest_order.shop_name ))
    ; __append(" </strong></p>\n            ")
    ;   predictions.trubleshoot_info.nearest_order.items.forEach(function(item){ 
    ; __append("\n              <p>")
    ; __append(escapeFn( item.quantity ))
    ; __append(" x ")
    ; __append(escapeFn( item.name ))
    ; __append("</p>\n              ")
    ; if (item.notes) {
    ; __append("<p>Special Request: ")
    ; __append(escapeFn( item.notes ))
    ; __append("</p>")
    ; }
    ; __append("\n              ")
    ;  if (! _.isEmpty(item.extras) ) { 
    ; __append("\n                <p class='info'>Extras</p>\n                ")
    ;  item.extras.forEach(function(extra){ 
    ; __append("\n                  <p>")
    ; __append(escapeFn( extra.name ))
    ; __append(":\n                  ")
    ;  extra.sub_extras.forEach(function(sub_extra){ 
    ; __append("\n                  <span> ")
    ; __append(escapeFn( sub_extra.name ))
    ; __append(", price: ")
    ; __append(escapeFn( sub_extra.price ))
    ; __append("</span>\n                ")
    ;  })
    ; __append("  \n                </p>  \n                ")
    ;  })
    ; __append("\n              ")
    ; }
    ; __append("\n            ")
    ;  })
    ; __append("\n            <p> total: ")
    ; __append(escapeFn( predictions.trubleshoot_info.nearest_order.total ))
    ; __append(" </p>\n            <p> Created At: ")
    ; __append(escapeFn( predictions.trubleshoot_info.nearest_order.created_at ))
    ; __append(" </p>\n          </div><br />\n        </div>\n      ")
    ;  } 
    ; __append("\n    </div> \n  </div>\n</div>\n\n\n<div class=\"row\">\n  <div class=\"col-sm-12\">\n    <h3>Best Sellers</h3>\n      ")
    ;  predictions.best_sellers.forEach(function(result){  
    ; __append("\n        <div class=\"row\">\n          <div class=\"col-md-2 favorite-image\">\n            <img src=\"")
    ; __append(escapeFn( result.thumb_url ))
    ; __append("\" width=\"100%\">\n          </div>\n          <div class=\"col-md-6 favorite-info\">\n            <p class=\"text-primary\"> <strong>")
    ; __append(escapeFn( result.shop_name ))
    ; __append("  </strong></p>\n            <p> ")
    ; __append(escapeFn( result.name ))
    ; __append(", price: ")
    ; __append(escapeFn( result.price ))
    ; __append(" </p>\n            <p>Score: <strong>")
    ; __append(escapeFn( result.score ))
    ; __append("</strong></p>\n            ")
    ;  result.extras.forEach(function(extra){ 
    ; __append("\n                ")
    ;  extra.sub_extras.forEach(function(sub_extra){ 
    ; __append("\n                  ")
    ; if(sub_extra.selected){ 
    ; __append("\n                    <span><p  class=\"text-muted\">")
    ; __append(escapeFn( extra.name ))
    ; __append(": ")
    ; __append(escapeFn( sub_extra.name ))
    ; __append(", price: ")
    ; __append(escapeFn( sub_extra.price ))
    ; __append(" </span></p>    \n                  ")
    ; }
    ; __append("\n                ")
    ;  })
    ; __append("\n            ")
    ;  })
    ; __append("\n            <br /><br />\n          </div>\n      </div>\n    ")
    ; })
    ; __append("\n  </div>\n</div>")
  }
  return __output;

}