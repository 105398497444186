import { CartItem } from "./cart_item";

export class Cart {

  static source = 'web';
  static branch;
  static items() { return CartItem.all(); }
  static grandTotal() {    
    return _.chain(this.items()).map((it) => { return it.calculateTotal(); }).reduce((mem, it) => { return mem + it}, 0).value();
  }

  static currency() { return this.branch.currency_symbol }
  static ids() { return this.items().map((item) => { return item.id; }) }
  static findById(id) { return this.items().find((item) => { return item.__id == id}) }
  static clear() { this.items().forEach((it) => it.remove()) }
  static userWasRegistered() { localStorage.setItem('action', 'loggedIn') }
  static removeActions() { localStorage.removeItem('action') }
  static shouldAutoPlaceOrder() { return localStorage.getItem('action') == 'loggedIn' }
  static params() {
    return this.items().map((it) => {
      return {
        item_id: it.id,
        quantity: it.quantity,
        notes: it.notes,
        options: it.option_ids
      };
    })
  }
}