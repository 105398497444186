import { WorkingDay } from './working_day';

const moment = require('moment') 
const DATS_MAPER = {"0": 6, "1": 0, "2": 1, "3": 2, "4": 3, "5": 4, "6": 5}

export class GoogleWorkingHoursPeriod{
  constructor(periods){
    this.placeWorkingHourArray =_.map(periods, function(day){
      return new PlaceWorkingHour(day).attributes();
    })
  }

  serializeToDrivuWorkingHourFormat(){
    return _.map(this.placeWorkingHourArray, function(dayPeriod){
      return {isActive: dayPeriod.isActive, timeFrom: dayPeriod.start, timeTill: dayPeriod.end, day: _.find(WorkingDay.DAYESOfWeek, function(day){ return (day.index == dayPeriod.day); }).name};
    });
  }


}

export class PlaceWorkingHour{
  constructor(period){
    this.period = period
  }

  find_day() {
   return DATS_MAPER[this.period.open.day];
  }

  find_start(){
    return this.constractDate(this.period.open.time)
  }

  find_end(){
    return this.constractDate(this.period.close.time)
  }

  attributes(){
   return  {
      day: this.find_day(),
      start: this.find_start(),
      end: this.find_end(),
      isActive: true
    }
  }

  constractDate(hour) {
    return moment().minutes('00').hours(hour).format("HH:mm");
  }
}