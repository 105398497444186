import { Extra } from "./extra";
import { Model } from "./model";

export class Item extends Model {

  // id; price; originalPrice; name; quantity; notes; categoryId; bestSeller; branchId; ignorePrice; vat; taxType; extras

  constructor(options) {
    super(options)
    this.extras = options.extras.map((extra) => { return new Extra(extra) })
  }
}