module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<form id=\"create-item-form\" action=\"\" accept-charset=\"UTF-8\" method=\"post\">\n  <div class=\"modal-content container\">\n    <div class=\"modal-header\">\n      <img class=\"center-item img-responsive\" src=\"")
    ; __append(escapeFn( item.image_url ))
    ; __append("\" width=\"300\">\n    </div>\n\n    <div class=\"modal-body\">\n\n      <div class=\"row justify-content-between\" style=\"background:ligh\">\n        <div class=\"col-auto\">\n          <p>\n            <strong>")
    ; __append(escapeFn( item.name ))
    ; __append(" </strong>\n          </p>\n        </div>\n  \n        <div class=\"col-auto\">\n           <p><strong>Item price ")
    ; __append(escapeFn( item.price ))
    ; __append("</strong> </p> \n        </div>\n      </div>\n\n      <div class=\"row justify-content-center\">\n        <div class=\"col-4\">\n          <div class=\"input-group pull-right\">\n            <span class=\"input-group-btn\">\n              <button type=\"button\" class=\"quantity-left-minus btn btn-danger btn-number\"  data-type=\"minus\" data-field=\"\">\n              <span class=\"fa fa-minus\"></span>\n              </button>\n            </span>\n            <input type=\"text\" id=\"quantity\" name=\"quantity\" class=\"form-control input-number\"  min=\"1\" max=\"100\" value=")
    ; __append(escapeFn( item.quantity ))
    ; __append(" >\n            <span class=\"input-group-btn\">\n              <button type=\"button\" class=\"quantity-right-plus btn btn-success btn-number\" data-type=\"plus\" data-field=\"\">\n              <span class=\"fa fa-plus\"></span>\n              </button>\n            </span>\n          </div>\n        </div>\n      </div>\n\n      ")
    ;  item.extras.forEach(function(extra){  
    ; __append("\n        <div class=\"row mt-2\">\n          <div class=\"col-sm-12\">\n            <div class=\"bg-gray p-2\">\n              <p><strong>")
    ; __append(escapeFn( extra.name ))
    ; __append("</strong></p>\n            </div>\n            \n            <div class=\"d-flex  my-2 ps-2\">\n              ")
    ;  extra.options.forEach(function(option) { 
    ; __append("\n                ")
    ;  if(extra.content_type == 'multiple') { 
    ; __append("\n                  <div class=\"border p-2 text-center rounded mr-2\">\n                    <p>")
    ; __append(escapeFn( option.name ))
    ; __append("</p> \n                    <p>")
    ; __append(escapeFn( option.price ))
    ; __append("</p>\n                    <input  data-price = \"")
    ; __append(escapeFn( option.price ))
    ; __append("\" \n                    type=\"checkbox\" \n                    name=")
    ; __append(escapeFn( extra.name))
    ; __append(" \n                    value=")
    ; __append(escapeFn( option.id))
    ; __append(" \n                    >\n                  </div>\n                ")
    ;  } else { 
    ; __append("\n                  <div class=\"border p-2 text-center rounded mr-2\">\n                    <p>")
    ; __append(escapeFn( option.name ))
    ; __append(" </p>\n                    <p>")
    ; __append(escapeFn( option.price ))
    ; __append(" </p> \n                    <input  data-price = \"")
    ; __append(escapeFn( option.price ))
    ; __append("\" \n                    type=\"radio\"  \n                    name=")
    ; __append(escapeFn( extra.name))
    ; __append("  \n                    value=")
    ; __append(escapeFn( option.id))
    ; __append("\n                    >\n                  </div>\n                ")
    ;  } 
    ; __append("\n              ")
    ;  }); 
    ; __append("\n            </div>\n          </div>\n        </div>\n      ")
    ;  }) 
    ; __append("\n\n      <div class=\"row\">\n        <div class=\"col-sm-12 form-control border-0\">\n          <label>Special Request</label>\n          <input type=\"textbox\" name=\"notes\" id=\"item-note\" class=\"form-control\" placeholder=\"Do you have a special request?\">\n        </div>\n      </div>\n\n      <div class=\"row\">\n        <div class=\"col-sm-12 form-control border-0\">\n          <div>\n            <label for=\"from_loyalty\">Paid by loyalty stamps</label>\n            <input type=\"checkbox\" name=\"from_loyalty\" value=\"0\" id=\"from_loyalty\">\n          </div>\n        </div>\n      </div>\n\n    </div>\n\n    <div class=\"modal-footer\">\n      <button type=\"button\" class=\"btn btn-warning form-control item_done\" data-bs-dismiss=\"modal\">Done</button>\n    </div>\n  </div>\n</form>\n")
  }
  return __output;

}