'use strict';

import fingerprint from "fingerprintjs";

class DrivuFingerPrint {

  constructor() {
    this.hash_options = {
      canvas: true,
      screen_resolution: true
    };
  }

  get_secure_token() {
    return new fingerprint(this.hash_options).get().toString() + new Date().getTime().toString();
  }

}


export { DrivuFingerPrint }
