import { Dialog } from '../utilits/dialog';
class CategoryView{
  constructor(options){
    var dialog = new Dialog();
    
    $('#create-category').on('click',function(e){
        dialog.modal = $('#create-category');
        dialog.show();
    });

    $('#create-category-form').on('ajax:error',function(e,xhr, status, error){
        dialog.add_errors(xhr.responseJSON.errors)
    });

    $('#create-category-form').on('ajax:success',function(){
      dialog.hide();
      location.reload();
    });

    $('.destroy_category').on('ajax:success',function(){
      alert("Category Deleted")
      location.reload();
    });

  }
}

export {CategoryView}