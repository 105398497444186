import _ from "underscore";
import { globalFun } from "../../packs/application";
import { Branch } from "../utilits/branch";
import { GoogleMap } from "../utilits/google_map";

export class ZoneView {
  constructor(options){
    this.latitude = options.latitude;
    this.longitude = options.longitude;
    this.radius = options.radius;
    this.latitude_box = options.latitude_box;
    this.longitude_box = options.longitude_box;
    this.manuallyAddedBranchesElementList = options.manuallyAddedBranchesElementList;
    this.manuallyAddedBranches = this._setMauallyAddedBranches(options.manuallyAddedBranches  || []);
    this.regionBranches =  options.regionBranches || [];
    this.google_map = new GoogleMap({lat: this.latitude, lng: this.longitude, latitude_box: this.latitude_box, longitude_box: this.longitude_box});
    this.zoneManualAddedBranchIdsElement = options.zoneManualAddedBranchIdsElement

    this.initBinding();
  }

  initBinding(){
    this.google_map.set_map('Dubai', 'UE'); 
    this.google_map.draw_circle(this.radius);
    this.latitude_box.val(this.latitude);
    this.longitude_box.val(this.longitude);
    this.drowManuallyAddedBranchesElement();
    this._bindBranchSearch();
    this._bindRadiusChange();
    this._bindRadiusChange();
    this._bindActions();
  }

  _bindBranchSearch(){
    globalFun.add_search('#target_branch_id', '/admins/search_branches', 'branches', '#branch_search-results', function (data, response) {
      response($.map(data, function (value, key) {
        return { id: value.id, label: value.name, value: value.id, shop_id: value.shop_id, latitude: value.latitude, longitude: value.longitude }
      }));
    }, function (e, ui) {

      var branch = new Branch({id: ui.item.id, shop_id: ui.item.shop_id, display_name: ui.item.label, latitude: ui.item.latitude,longitude: ui.item.longitude})
      this.manuallyAddedBranches.push(branch);
      this.zoneManualAddedBranchIdsElement.val(
        JSON.stringify(_.pluck(this.manuallyAddedBranches, "id"))  
      );
      this.drowManuallyAddedBranchesElement();
    }.bind(this));

  }

  _bindRadiusChange(){
    $('#radius').on('keyup',function(e) {
      if(this.latitude_box.val() == "" && this.longitude_box.val() == ""){
        alert("Add marker");
        return;
      }

      var radius_in_miles = globalFun.getMiles(parseFloat(e.currentTarget.value));
      var c = this.google_map.draw_circle(radius_in_miles);
      this.get_branches_count_in_region();
      $("#zone_latitude").val(c.center.lat());
      $("#zone_longitude").val(c.center.lng());
      $("#zone_radius").val(parseFloat(e.currentTarget.value));
    }.bind(this));
  }

  _bindActions(){
    $('.branch-active').on('change', function(event){
      var ids = ["onlyActiveBranches", "onlyPendingBranches", "onlyOnMapAndDidnotSell"];

      var isChecked = $(event.currentTarget).is(':checked');
      if(isChecked) {
        var id = $(event.currentTarget).attr('id');
        _.each(_.difference(ids, [id]), function(element){
          $(`#${element}`).prop( "checked", false);
        });
      }

      var actions = {
        onlyActiveBranches: function(){ this.showOnlyOnMapBranches(); },
        onlyPendingBranches: function(){ this.showOnlyPendingBranches(); },
        onlyOnMapAndDidnotSell: function(){ this.showOnlyOnMapNotSellingBranches(); }
      };

      var checked = $('.branch-active:checked');
      if(checked.length == 0) {
        this.showAllBranches();
      } else {
        actions[checked.attr('id')]();
      }
    }.bind(this));
  }

  drowManuallyAddedBranchesElement(){
    this.manuallyAddedBranchesElementList.empty();
    _.each(this.manuallyAddedBranches, function(branch){
        this.manuallyAddedBranchesElementList.append(`<li>${branch.display_name}</li>`)
    }.bind(this));
  }    

  get_branches_count_in_region() {
    globalFun.showLoadingWheel();
    $.ajax({
      type: 'GET',
      url: '/admins/region_branches',
      data: {
        'branch_in_region[radius]': $('#radius').val(),
        'branch_in_region[lat]': $("#latitude_box").val(),
        'branch_in_region[lng]': $('#longitude_box').val()
      },
      success: function (branches) {
        globalFun.hideLoadingWheel();
        this.regionBranches = branches;
        this.showOnlyOnMapBranches();
      }.bind(this),
      error: function(){
        globalFun.hideLoadingWheel();
      }
    });
  }

  showAllBranches(){ this.showBranchesInMap(this.regionBranches); }

  showOnlyPendingBranches(){ this,showBranchesInMap(_.filter(this.regionBranches, function(branch){ return branch.on_map == false})); }

  showOnlyOnMapBranches(){ this.showBranchesInMap(_.filter(this.regionBranches, function(branch){ return branch.on_map == true})); }

  showOnlyOnMapNotSellingBranches() { this.showBranchesInMap(_.filter(this.regionBranches, function(branch){ return branch.on_map == true && branch.total_month_count == 0}));}

  showBranchesInMap(branches){
    this.google_map.delete_markers();
    $('.users-count').html(branches.length);
    $("#branches_in_location").text("");
    _.each(branches, function (branch) {
        $("#branches_in_location").append(branch.username + ", ")
        var marker = this.google_map.generate_restaurant_marker(branch.latitude, branch.longitude, branch.logo);
        this.google_map.pin_marker_to_current_map(marker);
    }.bind(this));
  }

  _setMauallyAddedBranches(branches){
    return _.map(branches, function(branch){
        return new Branch({id: branch.id, shop_id: branch.user_id, display_name: branch.display_name, latitude: branch.latitude, longitude: branch.longitude})
    });
  }
}