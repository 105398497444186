
'use strict';

export class DrivuMarker {

  constructor(url){
    this.url = new URL(url);
    this.latitude = this.url.searchParams.get('lat')
    this.longitude = this.url.searchParams.get('lgt')
    this.shopName = this.url.searchParams.get('shop')
    this.branchName = this.url.searchParams.get('branch')
  }

  addToMap(map) {
    this.marker = new google.maps.Marker({position: new google.maps.LatLng(this.latitude, this.longitude), icon: this.url.href});
    this.marker.setMap(map);
    this.addClickListener();
    return this.marker;
  }

  generateBranchUrl() {
    return `${window.location.origin}/DriveThru/${this.shopName}/${this.branchName}`
  }

  addClickListener() {
    this.marker.addListener('click', ()=> {
      window.open(this.generateBranchUrl());
    })
  }
}