
// import moment from 'moment';
import daterangepicker from 'daterangepicker';
var date_range_picker = function(options){
  this.selector = options.selector;
  this.config = Object.assign(options.config, this.selector.data('dateTimeConfig'));
  options.config.minDate = this.selector.data('mindate');
  this.selector.daterangepicker(options.config);
  this.on_apply();
  this.on_cancel();
  if(options.default_start_date != undefined && options.default_end_date != undefined){
    this.selector.val(`${moment(options.default_start_date).format(options.config.format)} - ${moment(options.default_end_date).format(options.config.format)}`)
  }
}

date_range_picker.prototype.on_apply= function(){
  var _this = this;
  this.selector.on('apply.daterangepicker', function(ev, picker) {
    (picker.singleDatePicker) ? set_single_string(this, _this, picker) : set_rang_string(this,_this,picker)
  });
}

date_range_picker.prototype.on_cancel= function(){
  this.selector.on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
  });
}

function set_rang_string(e, _this, picker){
  $(e).val(picker.startDate.format(_this.config.format) + ' - ' + picker.endDate.format(_this.config.format));
}

function set_single_string(e,_this,picker){
  $(e).val(picker.startDate.format(_this.config.format));
}

export { date_range_picker }