import { Indoor } from "../indoor";

export class OrderSource {

  static OrderAnonymous = document.cookie.includes('order_anonymous=true');
  static source = document.cookie["type"];
  static customerLane = document.cookie.includes('type=customerlane');
  static orderServing = document.cookie.includes('type=orderserving')

  static lane = document.cookie.includes('type=lane')
  static queue = document.cookie.includes('type=queue')

  static anonymous(){
    return document.cookie.includes('order_anonymous=true')
  }

  static getType(){
    if(OrderSource.customerLane) { return  Indoor.TYPES.CustomerLane    };
    if(OrderSource.orderServing) { return 'OrderServing' };
  }

}