module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"row\">\n  <div class=\"col-sm-12\">\n    <div class=\"nav-parent category-tab-con overflow-x-hidden-scrolbar\">\n      <ul class=\"nav nav-tabs d-inline-block\" role=\"tablist\">\n        <li class=\"nav-item d-inline-block\">\n          <a class=\"nav-link active\" data-bs-toggle=\"tab\"  href=\"#all-items\"  role=\"tab\">All items</a>\n        </li>\n\n        ")
    ; categories.forEach(function(category){  
    ; __append("\n          <li class=\"nav-item d-inline-block\">\n            <a class=\"nav-link\" data-bs-toggle=\"tab\"  href=\"#")
    ; __append(escapeFn( category.name.replace(/[^A-Z0-9]/ig, "")))
    ; __append("\"  role=\"tab\">")
    ; __append(escapeFn( category.name))
    ; __append("</a>\n          </li>\n        ")
    ; })
    ; __append("\n      </ul>\n    </div>\n    \n    <div class=\"tab-content\">\n      <div class=\"order-items-preview\">\n        <div class=\"d-flex flex-row bd-highlight mb-3 justify-content-between\">\n        </div>\n      </div>\n\n      <div class=\"tab-pane active\" id=\"all-items\"  role=\"tabpanel\">\n        ")
    ; categories.forEach(function(category){
    ; __append("\n          <div class=\"pt-3\">\n            <p><strong>")
    ; __append(escapeFn( category.name))
    ; __append("</strong></p>\n            ")
    ; category.menu_items.forEach(function(item){ 
    ; __append("\n              <div class=\"row align-items-center show_item justify-content-around item-row g-0  \">\n                  <div class=\"col-2\">\n                    <div class=\"square-con-box\">\n                      <img src=\"")
    ; __append(escapeFn( item.thumb_url))
    ; __append("\", class=\"rounded square-image mx-100 rounded border item-image\">\n                    </div>\n                  </div>\n\n                  <div class=\"col-10 item-desctiption ps-2\">\n                    <p>\n                      <a class=\"show_item\" data-remote=\"true\" href=\"/admins/restaurants/")
    ; __append(escapeFn( branch.shop_id ))
    ; __append("/branches/")
    ; __append(escapeFn( branch.id ))
    ; __append("/menu_items/")
    ; __append(escapeFn( item.id))
    ; __append("\">")
    ; __append(escapeFn( item.name))
    ; __append("</a>\n                    </p>\n  \n                    ")
    ;  if(item.pre_order) { 
    ; __append("\n                      <p class=\"text-success\">PreOrder Item </p>\n                    ")
    ;  } 
    ; __append("\n                  </div>\n              </div>\n              <hr>\n            ")
    ; })
    ; __append("\n          </div>\n        ")
    ; })
    ; __append("\n      </div>\n\n      ")
    ; categories.forEach(function(category){
    ; __append("\n        <div class=\"tab-pane\" id=\"")
    ; __append(escapeFn( category.name.replace(/[^A-Z0-9]/ig, "")))
    ; __append("\"  role=\"tabpanel\">\n          <div class=\"pt-3\">\n            <p><strong>")
    ; __append(escapeFn( category.name))
    ; __append("</strong></p>\n            ")
    ; category.menu_items.forEach(function(item){ 
    ; __append("\n                <div class=\"row align-items-center  show_item justify-content-around\">\n                    <div class=\"col-3\">\n                        <div class=\"square-con-box\">\n                          <img src=\"")
    ; __append(escapeFn( item.thumb_url))
    ; __append("\", class=\"rounded square-image mx-100 rounded border item-image\">\n                        </div>\n                    </div>\n                    \n                    <div class=\"col-9 item-desctiption\">\n                      <p >\n                        <a class=\"show_item\" data-remote=\"true\" href=\"/admins/restaurants/")
    ; __append(escapeFn( branch.shop_id ))
    ; __append("/branches/")
    ; __append(escapeFn( branch.id ))
    ; __append("/menu_items/")
    ; __append(escapeFn( item.id))
    ; __append("\">")
    ; __append(escapeFn( item.name))
    ; __append("</a>\n                      </p>\n\n                      ")
    ;  if(item.pre_order) { 
    ; __append("\n                        <p class=\"text-success\">PreOrder Item </p>\n                      ")
    ;  } 
    ; __append("\n                    </div>\n\n                </div>\n                <hr/>\n            ")
    ; })
    ; __append("\n          </div>\n        </div>\n      ")
    ; })
    ; __append("\n    </div>\n  </div>\n</div>\n\n")
  }
  return __output;

}