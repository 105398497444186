export var toast = require('toastr')

export class DatesChartView {

  dateFields = '.date-picker'
  loadButton = '.loader'

  constructor(ui) {
    this.ui = ui;
    this.loadListeners()
    if($(this.ui).data('autoload')) {
      $(this.ui).find(this.loadButton).trigger('click')
    }

  }

  resultContainerId() { return `${this.ui.id}_result` }

  loadListeners() {
    $(this.ui).find(this.loadButton).on('click', () => {

      var dates = _.without(_.map($(this.dateFields), (it) => { return it.value }), '');

      if(dates.length == 0) {
        toast.error("Please select at least one date");
        return
      }

      Packs.application.globalFun.showLoadingWheel();
      $.ajax({
        url: $(this.ui).data('url'),
        type: 'get',
        data: { dates: dates },
        success: function(res){
          var chart = new Chartkick.ColumnChart(this.resultContainerId(), res, {stacked: true, legend: false, title: $(this.ui).data('title') })
          this.ui.style.height = "750px"
          $(this.ui).find(`#${this.resultContainerId()}`).get(0).style.height = "700px"
          Packs.application.globalFun.hideLoadingWheel();
        }.bind(this),
        error: function(){}
      })      
    })
  }

}