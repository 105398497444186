module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"option-row\" extra_id=\"")
    ; __append( extra.id)
    ; __append("\">\n  <div class=\"option-new option bg-white pt-2\" id=\"")
    ; __append( uniqId)
    ; __append("\">\n\n    <div class=\"alert alert-danger errors-container hidden\" role=\"alert\">\n      <h4 class=\"alert-heading\">Errors</h4>\n      <p>\n        <div class=\"error-list list-group\"></div>\n      </p>\n    </div>\n    \n    <div class=\"circle-loader\" style=\"display: none;\">\n      <div class=\"checkmark draw\" style=\"display: none;\"></div>\n    </div>\n    \n    <div class=\"form-group form-row  justify-content-arround align-items-center\">\n      \n      <div class=\"col  with-trash-icon  ps-3\">  \n        <div class=\"trash-link\">\n          <a class=\"delete_option fa fa-trash-o\"></a>\n        </div>\n        <input type=\"text\" name=\"name\" id=\"name\" placeholder=\"option name\" class=\"name control-field form-control\">\n      </div>\n\n      <div class=\"col\">\n        <input type=\"text\" name=\"price\" id=\"price\" placeholder=\"price\" class=\" price control-field form-control\">\n      </div>\n\n      <div class=\"col-sm col-auto \">\n        <div class=\"form-check form-switch  float-endt\">\n        <label class=\"form-check form-switch\" for=\"active\">\n          <input type=\"checkbox\" name=\"active\" id=\"active\" value=\"1\" class=\"form-check-input active\" checked=\"checked\">\n          <span class=\"form-check-label\">\n            Active\n          </span>\n        </label> \n      </div>\n      </div>\n    </div>\n    <hr>\n  </div>\n</div>")
  }
  return __output;

}