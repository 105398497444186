'use strict';

import { showLoadingWheel, hideLoadingWheel } from "./drivu_global_functions";
const testingOrderMarkerInfoTemplate  = require('../templates/testing_order_marker_info'); 
import {UserSocketIO} from  "../utilits/user_socket_io";

var Step = function(options){
  this.startLocation = new google.maps.LatLng(parseFloat(options.start_point[0]), parseFloat(options.start_point[1]));
  this.endLocation = new google.maps.LatLng(parseFloat(options.end_point[0]), parseFloat(options.end_point[1]));
  this.currentLocation = this.startLocation;
  this.points = google.maps.geometry.encoding.decodePath(options.poly);
  this.poly = new google.maps.Polygon({paths: this.points, strokeColor: '#FF0000', strokeOpacity: 1.0, strokeWeight: 2});
  //In meter
  this.distance = parseFloat(options.distance);
  //In second
  this.time = parseFloat(options.time);

  this.checkPointLocation = this.startLocation;

  this.userIsDrivingInside = function(location){
    return google.maps.geometry.poly.containsLocation(location, this.poly);
  }.bind(this);

  this.adjustEstimations = function(location){

    var distanceBehind = google.maps.geometry.spherical.computeDistanceBetween(location, this.checkPointLocation);
    var drovePercent = distanceBehind / this.distance;
    this.distance -= distanceBehind;
    this.time = this.time * drovePercent
    this.checkPointLocation = location
  }

};

export var Navigation = function(options){
  //this.poly = options.points;
  this.points = google.maps.geometry.encoding.decodePath(options.points);
  this.estimatedTime = 0.0;
  this.estimatedDistance = 0.0;
  this.polyline = new google.maps.Polyline({path: this.points, geodesic: true, strokeColor: '#4867ad', strokeOpacity: 1.0, strokeWeight: 5});

  this.steps = _.map(options.instructions, function(instruction){
    var step = new Step(instruction);
    this.estimatedDistance += step.distance;
    this.estimatedTime += step.time;

    return step;
  }.bind(this));

  this.currentStep = this.steps[0];
  this.adjustEstimations = function(location) {
    if(!this.currentStep.userIsDrivingInside(location)){
      var removed = this.steps.shift();
      this.currentStep = this.steps[0];

      this.estimatedDistance -= removed.distance;
      this.estimatedTime -= removed.time;
    } else {
      this.currentStep.adjustEstimations(location);
      this.estimatedTime = 0.0;
      this.estimatedDistance = 0.0;
      this.steps.forEach(function(step){
        this.estimatedDistance += step.distance;
        this.estimatedTime += step.time;
      }.bind(this));
    }
  }.bind(this);
};

var TestingOrder = function(options){
  
  this.branch = options.branch;
  this.user = options.user;
  this.currentLocation = findPointInDistance(options.distance, this);
  this.currentLocationIndex = 0;

  this.map = options.map;
  this.marker;
  this.navigation;
  this.socket;
  this.drivingInterval;

  this.create();
};

TestingOrder.prototype.setMarker = function(marker){
  this.marker = marker;
};

TestingOrder.prototype.setLocation = function(location){
  this.currentLocation = location;
  this.navigation.adjustEstimations(location);
  var heading = google.maps.geometry.spherical.computeHeading(this.marker.getPosition(), location);
  heading = (heading < 0) ? (360+heading) : heading;
  this.socket.stream_location(location.lat(), location.lng(), parseInt(this.navigation.estimatedTime), parseInt(this.navigation.estimatedDistance), heading);
};

TestingOrder.prototype.create = function(){
  showLoadingWheel();
  $.ajax({
    type: 'POST',
    data: {
      'order[user_id]': this.user.id,
      'order[branch_id]': this.branch.id,
      'order[latitude]': this.currentLocation.lat(),
      'order[longitude]': this.currentLocation.lng()
    },
    url: '/admins/order_tests',
    success: function(response){
      hideLoadingWheel();
      this.navigation = new Navigation(response.order.navigation);
      this.addMarkerOnMap();
      this.addNavigationOnMap();
      this.connect();
      this.startDriving();
    }.bind(this),
    error: function(){
      hideLoadingWheel();
    }
  });
};

TestingOrder.prototype.addMarkerOnMap = function(){

  var icon = {
    url: '../../../images/car_icon.png',
    // scaledSize: new google.maps.Size(130, 100),
    // origin: new google.maps.Point(0, 0),
    // anchor: new google.maps.Point(0, 0),
    rotation: 90,
  };

  this.marker = new google.maps.Marker({
    position: this.currentLocation,
    map: this.map,
    icon: icon,
    title: this.branch.name,
    infoWindow: new google.maps.InfoWindow({content: testingOrderMarkerInfoTemplate({username: this.user.username, plateNumber: this.user.plate_number})}),
  });

  this.marker.setMap(this.map);
  this.marker.infoWindow.open(this.map, this.marker);
  Packs.application.current_marker = this.marker;
  console.log(Packs.application.current_marker);
};

TestingOrder.prototype.addNavigationOnMap = function(){
  this.navigation.polyline.setMap(this.map);
};

TestingOrder.prototype.connect = function(){
  this.socket = new UserSocketIO(this.user);
  
};

TestingOrder.prototype.startDriving = function(){
  this.drivingInterval = setInterval(function(){
    this.moveOneStep();
  }.bind(this), 1000);
};

TestingOrder.prototype.moveOneStep = function() {
  var index = this.currentLocationIndex + 1;
  
  if(this.navigation.points.length > index) {
    var location = this.navigation.points[index];
    this.setLocation(location);
    this.marker.setPosition(location);
    var icon = this.marker.getIcon();
    icon.rotation = 30;
    this.marker.setIcon(icon);
    this.marker.setMap(this.map);
    this.currentLocationIndex = index;
  }else{
    clearInterval(this.drivingInterval);
  }
};

//// Private
function findPointInDistance(distance, _this){
  var pointA = new google.maps.LatLng(parseFloat(_this.branch.latitude), parseFloat(_this.branch.longitude));
  var bearing = Math.floor(Math.random() * 360);


  return google.maps.geometry.spherical.computeOffset(pointA, distance, bearing);
}

export{ TestingOrder}