module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("\n<div class=\"col-sm-6\">\n  ")
    ;  order.items.forEach(function(item){  
    ; __append("\n    <div class=\"row\">\n      \n      <div class=\"col-2\">\n        <p>")
    ; __append(escapeFn( item.thumb_url ))
    ; __append("</p>\n        <img class=\"img-thumbnail  img-responsive small-image\" width=\"100\" src='")
    ; __append(escapeFn( item.thumb_url ))
    ; __append("'>\n      </div>\n\n      <div class=\"col\">\n        <h4 class=\"\">")
    ; __append(escapeFn( item.quantity))
    ; __append(" x ")
    ; __append(escapeFn( item.name ))
    ; __append("</h4>\n        <ul>\n          ")
    ;  item.extras.forEach(function(extra){
    ; __append("\n            <li>\n              <p> ")
    ; __append(escapeFn( extra.name))
    ; __append(" -></p>\n              ")
    ;  extra.sub_extras.forEach(function(option){ 
    ; __append(" \n                  <p>")
    ; __append(escapeFn( option.name ))
    ; __append("</p>\n                  <ul>\n                    <li>Original Price: ")
    ; __append(escapeFn(  option.original_price ))
    ; __append("</li>\n                  </ul>\n              ")
    ;  }) 
    ; __append("\n            </li>\n          ")
    ; })
    ; __append("\n          <p class=\"gray-color\">Special Request: ")
    ; __append(escapeFn( item.notes ))
    ; __append("</p>\n        </ul>\n\n    </div>\n  ")
    ;  })
    ; __append("\n\n\n        <div class=\"row\">\n          <div class=\"col-sm-6\">\n            <p class=\"pull-left\">Total Amount </p> <p class=\"pull-right\"> AED ")
    ; __append(escapeFn( order.total ))
    ; __append("</p>\n          </div>\n        </div>\n        \n        ")
    ;  if(order.cash_amount) { 
    ; __append("\n        <div class=\"row\"> \n          <div class=\"col-sm-6\">\n            <p class=\"pull-left\">CASH </p> <p class=\"pull-right\"> AED ")
    ; __append(escapeFn( order.cash_amount ))
    ; __append("</p>\n          </div>\n        </div>\n\n        <div class=\"row\">\n          <div class=\"col-sm-6\">\n            <p class=\"pull-left text-danger\">CASH DUE </p> <p class=\"pull-right\">AED ")
    ; __append(escapeFn( order.cash_amount - order.total ))
    ; __append("</p>\n          </div>\n        </div>\n\n        ")
    ;  } 
    ; __append("\n      </div>\n")
  }
  return __output;

}