import{reloadCurrentScreen, showLoadingWheel, hideLoadingWheel} from '../utilits/drivu_global_functions'
class SponsorCampaignView {

  constructor(options) {  
    Object.keys(options).forEach(function(key) {
      this[key] = options[key];
    }.bind(this));
    this.bounds = new google.maps.LatLngBounds();
    this.initLoad();
  }

  initLoad(){
    var _this = this;
    _.each(this.branches, function(branch){
      
      var branchMarker = new google.maps.Marker({
        position: (new google.maps.LatLng(parseFloat(branch.latitude), parseFloat(branch.longitude))),
        icon: branch.user.image.white_thumb.url
      });

      this.branchesMarkers.push(branchMarker);
      var count = this.counts[branch.id.toString()];

      parseInt(count).times(function(){
        var location = new google.maps.LatLng(parseFloat(branch.latitude), parseFloat(branch.longitude));
        _this.bounds.extend(location)
        var marker = new google.maps.Marker({
          position: location,
          icon: {
            url: "/shop_cluster/cup-bubble.png",
            labelOrigin: {x: 55, y: 30}
          },
          label: {
            text: "1",
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
          }
        });

        _this.markers.push(marker);
      });

    }.bind(this));

    var clusterStyles = [
      {
        textColor: 'white',
        url: '/shop_cluster/cup-bubble.png',
        height: 70,
        width: 90,
        textSize: 18,
        height: 70,
        anchor: 60
      }
    ];

    var center = new google.maps.LatLng(25.044062, 55.187154);

    this.map = new google.maps.Map(document.getElementById('map'), {
      center: center,
      zoom: 13,
      mapTypeId: 'satellite',
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    });

    var mcOptions = {
      gridSize: 50,
      styles: clusterStyles,
      maxZoom: 15
    };

    this.map.fitBounds(this.bounds);

    // ClusterIcon.prototype.createCss = function(pos) {
    //   var style = [];
    //   style.push('background-image:url(' + this.url_ + ');');
    //   var backgroundPosition = this.backgroundPosition_ ? this.backgroundPosition_ : '0 0';
    //   style.push('background-position:' + backgroundPosition + ';');
    
    //   if (typeof this.anchor_ === 'object') {
    //     if (typeof this.anchor_[0] === 'number' && this.anchor_[0] > 0 &&
    //         this.anchor_[0] < this.height_) {
    //       style.push('height:' + (this.height_ - this.anchor_[0]) +
    //           'px; padding-top:' + this.anchor_[0] + 'px;');
    //     } else {
    //       style.push('height:' + this.height_ + 'px; line-height:' + this.height_ +
    //           'px;');
    //     }
    //     if (typeof this.anchor_[1] === 'number' && this.anchor_[1] > 0 &&
    //         this.anchor_[1] < this.width_) {
    //       style.push('width:' + (this.width_ - this.anchor_[1]) +
    //           'px; padding-left:' + this.anchor_[1] + 'px;');
    //     } else {
    //       style.push('width:' + this.width_ + 'px; text-align:center;');
    //     }
    //   } else {
    //     style.push('height:' + this.height_ + 'px; line-height:' +
    //         50 + 'px; width:' + this.width_ + 'px; text-align:center;');
    //   }
    
    //   var txtColor = this.textColor_ ? this.textColor_ : 'black';
    //   var txtSize = this.textSize_ ? this.textSize_ : 11;
    
    //   style.push('cursor:pointer; top:' + pos.y + 'px; left:' +
    //       pos.x + 'px; color:' + txtColor + '; position:absolute; font-size:' +
    //       txtSize + 'px; font-family:Arial,sans-serif; font-weight:bold; padding-top: 5px; padding-left: 25px;');
    //   return style.join('');
    // };

    new MarkerClusterer(this.map, this.markers, mcOptions);

    $('#branchCheckBox').on('click', function(){
      _this.toggleShopMarkers($(this).is(':checked'));
    });

    $('body').on('click', '.rawRemover', function(){
      $(this).closest('.list-group-item').remove();
    });

    $('#addCriteriaTargetsButton').on('click', function(){
      var payload = $(this).closest('.modal-content').find('form').serializeArray().toObject('name', 'value');
      var url = `/admins/sponsors/${_this.campaign.sponsor_id}/sponsor_campaigns/${_this.campaign.id}/user_criterias`;
      showLoadingWheel();
      $.ajax({
        url: url,
        method: 'POST',
        data: payload,
        success: function(){ reloadCurrentScreen(); },
        error: function(){ reloadCurrentScreen(); }
      });
    });
  
  	_.each(this.types, function(type){
      _this.addTargetSearch(type);
      _this.registerTargets(type);
      _this.destroyTargetType(type);
    });

    this.destroyTargetType('userCriteria');

    $('.notificationButton').on('click', function(){
      $('#dashboard_message_target_id').val($(this).attr('id').split('-').pop());
      $('#notificationModal').modal('show');
      $('#successMessage').hide();
      $('#dashboard_message_body').val('');
    });
  }
	
  toggleShopMarkers(show){
		var m = show ? this.map : null;
		_.each(this.branchesMarkers, function(marker){
			marker.setMap(m);
		});
	}

  add_search(element, url, response_key, append_to_element, after_success_callback, on_select_callback){
		$(element).autocomplete({
			source: function (request, response) {
				$.ajax({
					dataType: 'json',
					data: {term: request.term},
					url: url,
					success: function(data,s,xhr){
						var data = xhr.responseJSON[response_key];
						after_success_callback(data, response)
					}
				});
			},
			appendTo: append_to_element,
			select: function(e, ui){ on_select_callback(e, ui) }
		});
	}

  addTargetSearch(type){
		this.add_search('#'+type.plural()+'-search-txt', '/admins/search_'+type.plural(), type.plural(), '#'+type.plural()+'-search-results', function(data, response){
			response($.map(data, function (value, key) {
				return { label: value.name, value: value.id, type: value.type}
			}));
		}, function(e, ui){
			$(this).val("");
			$('#selected-'+type.plural()).append('<li class="list-group-item '+type+'_target_ids" value="'+ui.item.value+'">'+ui.item.label+'<i class="fa fa-remove fa-2x pull-right rawRemover" style="color: red;"></i></li>');
		});
	}

	registerTargets(type){
    var _this = this
		$(`#add${type.plural().upperCaseFirst()}Button`).on('click', function(){
			var ids = _.map($('.'+type+'_target_ids'), function(e){ return $(e).val() });
			showLoadingWheel();
			$('.'+type+'_target_ids').remove();
			var url = `/admins/sponsors/${_this.campaign.sponsor_id}/sponsor_campaigns/${_this.campaign.id}/${type.plural()}`
			$.ajax({
				url: url,
				method: "POST",
				data: {
					"target[resource_ids]": ids
				},
				success: function(){
					reloadCurrentScreen();
				},
				error: function(){
					reloadCurrentScreen();
				}
			});
		});	
	}

	destroyTargetType(type) {
    var _this = this
		var model = (type == 'userCriteria') ? 'user_criterias' : type.plural();
		$('.'+type+'TargetRemover').on('click', function(){
			showLoadingWheel();
			var url = `/admins/sponsors/${_this.campaign.sponsor_id}/sponsor_campaigns/${_this.campaign.id}/${model}/${$(this).attr('id').split('-').pop()}`
			$.ajax({
				url: url,
				method: "DELETE",
				success: function(){
					hideLoadingWheel();
					$(this).closest('tr').remove();
				}.bind($(this)),
				error: function(){
					hideLoadingWheel();
				}
			});
		});
	}
}


export { SponsorCampaignView }