import { Spinner } from 'spin.js';
import { DrivuSpinner } from './drivu_spinner';
require("bootstrap4-datetimepicker")

export function onElementInserted(containerSelector, elementSelector, callback) {
	var onMutationsObserved = function(mutations) {
		mutations.forEach(function(mutation) {
			if (mutation.addedNodes.length) {
				var elements = $(mutation.addedNodes).find(elementSelector);
				for (var i = 0, len = elements.length; i < len; i++) {
					callback(elements[i]);
				}
			}
		});
	};

	var target = $(containerSelector)[0];
	var config = { childList: true, subtree: true };
	var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
	var observer = new MutationObserver(onMutationsObserved);    
	observer.observe(target, config);
}

export function showLoadingWheel(element) { DrivuSpinner.showLoadingWheel(element) }
export function hideLoadingWheel() { DrivuSpinner.hideLoadingWheel() }

export function add_branch_online_counters() {
	
	var Counter = function (element) {
		this.element = element;
		this.date = new Date(element.attr('id').split('::').pop()).getTime();
	}

	var counters = $('.countDown').map(function () {
		return (new Counter($(this)));
	});

	var interval = setInterval(function () {
		counters.each(function () {
			var now = new Date().getTime();
			var distance = this.date - now;
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
			var result = (distance < 0) ? "Should be online" : (hours + ":" + minutes + ":" + seconds);
			$(this.element).attr('data-original-title', result);
			$('#' + $(this.element).attr('aria-describedby')).find('.popover-title').html(result);
		});
	}, 1000);

	if (counters.length === 0) clearInterval(interval);
}

export function add_search(element, url, response_key, append_to_element, after_success_callback, on_select_callback, request_params = {}) {
	$(element).autocomplete({
		minLength: 0,
		source: function (request, response) {
			$.ajax({
				dataType: 'json',
				data: Object.assign({term: request.term}, request_params, {key: request.term}),
				url: url,
				success: function (data, s, xhr) {		
					var data = xhr.responseJSON[response_key];
					after_success_callback(data, response)
				}.bind(this)
			});
		},
		appendTo: append_to_element,
		select: function (e, ui) { 
			on_select_callback(e, ui, this); 
		}
	});
}

export function reloadCurrentScreen() { location.reload(); }

export function addDatetimepicker(element, date){

	element.datetimepicker({
		icons: {
			time: 'fa fa-clock-o',
			date: 'fa fa-calendar',
			up: 'fa fa-arrow-up',
			down: 'fa fa-arrow-down',
			previous: 'fa fa-chevron-left',
			next: 'fa fa-chevron-right',
			today: 'fa fa-calendar-check',
			clear: 'fa fa-trash',
			close: 'fa fa-times'
		},
		date: new Date(date),

	});
}

export function append_validation_errors(form_key, form_errors, form_class, key_as_element_id=undefined) {
	$(`.${form_class} .is-invalid`).removeClass('is-invalid');
	$(`.${form_class}`).find('.errors-con').find('.invalid-feedback').empty();
	_.mapObject(form_errors, function (errors, key) {
		if(key_as_element_id != undefined){
			var element = $('#' +  key);
		}else{
			var element = (form_class !== undefined) ? $(`.${form_class}`).find('#' + form_key + '_' + key) : $('#' + form_key + '_' + key);
		}
		_.each(errors, function (error) {
			append_validation_errors_to_element(element, error)
		}.bind(this));
	}.bind(this));
	
}

export function append_validation_errors_to_element(element, error){
	element.addClass('is-invalid');
	if (!_.isEmpty(error)) {
		if (element.parents('.form-group').find('.errors-con').length == 0) {
			element.parents('.form-group').append(`
				<div class="d-inline errors-con">
					<div class="invalid-feedback d-inline"> 
					</div>
				</div>
			`)
		}
		console.log(error);
		element.parents('.form-group').find('.errors-con').find('.invalid-feedback').empty();
		element.parents('.form-group').find('.errors-con').find('.invalid-feedback').append("(" + error + "" + ")");
	}
}

export function addBootstrapPaginator(){
	var boostrapPaginator =  new pagination.TemplatePaginator({
		 prelink: this.preLink , 
		 current: this.currentPage, 
		 rowsPerPage: this.perPage,
		 totalResult: this.totalEntries, 
		 slashSeparator: false,
		 template: function(result) {
				 var i, len, prelink;
				 var html = '<div><ul class="pagination">';
				 if(result.pageCount < 2) {
						 html += '</ul></div>';
						 return html;
				 }
				 prelink = this.preparePreLink(result.prelink);
				 console.log(prelink);
				 if(result.previous) {
						 html += '<li class="page-item"><a class="page-link" href="' + prelink + result.previous + '">' + this.options.translator('PREVIOUS') + '</a></li>';
				 }
				 if(result.range.length) {
						 for( i = 0, len = result.range.length; i < len; i++) {
								 if(result.range[i] === result.current) {
										 html += '<li class="active page-item"><a class="page-link" href="' + prelink + result.range[i] + '">' + result.range[i] + '</a></li>';
								 } else {
										 html += '<li class="page-item"><a class="page-link" href="' + prelink + result.range[i] + '">' + result.range[i] + '</a></li>';
								 }
						 }
				 }
				 if(result.next) {
						 html += '<li class="page-item"><a class="page-link" href="' + prelink + result.next + '" class="paginator-next">' + this.options.translator('NEXT') + '</a></li>';
				 }
				 html += '</ul></div>';
				 return html;
		 }
	 });
	 $('.invoices-view').prepend(boostrapPaginator.render());
}

export function getMiles(radius) {
	return radius * 1600;
}

export function getGooglePlaseDetails(place_id, cb){
	$.ajax({
		url: `/place_details/${place_id}`,
		method: "get",
		success: function(data){
			cb(data.details);
		}
	})
}

export function itemViewRequest(menuItemId, cartId, only_view=false){
	$.ajax({
		url: `/indoors/menus/${menuItemId}/item.js?q=${cartId}&only_view=${only_view}`,
		method: 'get',
		data: {q: cartId},
		success: function(){},
		error: function(){}
	});
}

export function getUserCountry(cb){
	$.ajax({
		url: "https://ipinfo.io",
		dataType: "jsonp",
		method: "get",
		success: function(response){
			cb(response.country)
		},
		error: function(){
			console.log(arguments);
			cb('ARE');
		}
	})
}


export function toggleShopMarkers(show, drivuMap){
	var m = show ? drivuMap.map : null;
	_.each(drivuMap.branchLogoMarkers, function(drivuMarker){
		drivuMarker.marker.setMap(m);
	});
}


