import { InvoiceView } from "./invoice_view";
const statementOfAccountRow = require('../templates/statement_of_account_row');

var accounting = require("accounting-js");
export class StatementOfAccountView {
  constructor(options){
    this.trn = options.trn; 
    this.address =  options.address; 
    this.userId = options.user_id;
    this.statementOfAccountTransactionsPath = options.statementOfAccountPath;
    this.last_balance_in_previous_page =  0;
    this.rootElement = $(options.elementId);
    this.bindLisener(); 
  }

  getFirstPage(){
    var _this = this;
    this.requestTransactions(1, function(){
      _this.loadPaginateTransactions(_this);
    });
  }

  bindLisener(){
    this.getFirstPage();
    this.delegateTransactionClick()
  }  

  delegateTransactionClick(){
    this.rootElement.on( "ajax:success", '.invoice-request', function(e, xhr, status){
      new InvoiceView({
        invoice: xhr["invoice"],
        trn: this.trn,
        address: this.address
      });
    }.bind(this));
  }

  loadPaginateTransactions(_this){
    async.waterfall(_.map(_.range(2, _this.totalPages + 1), function(page){
      return function(cb){_this.requestTransactions(page, cb)}
    }), function(){});
  }

  requestTransactions(page, cb){
    var _this = this;
    $.ajax({
      url: _this.statementOfAccountTransactionsPath,
      method: "get",
      dataType: "JSON",
      data: {statement_of_account: {page: page, last_balance_in_previous_page: _this.last_balance_in_previous_page} },
      async: true,
      success: function(data, status, xhr){
        _this.last_balance_in_previous_page = _.last(data["transactions"]).account_of_statment_balance;
        _this.totalPages = data["total_pages"];
        _this.rootElement.append(statementOfAccountRow({transactions: data["transactions"], accounting: accounting}));
        $('#current-balance-value').text(_this.last_balance_in_previous_page);
        cb();
      },
      errors: function(){
      }
    });
  }
}