import { PushNotification } from "../utilits/push_notification";
import { WatchOrdersOnMap } from "../utilits/watch_orders_on_map";

export class LiveOrdersView {  
  
  constructor(ui){
    this.userId = $(ui).data('userId');
    this.orders = $(ui).data('orders');
    this.delegate = {
      locationChange: (payload) => {
        var orderMarker = _.find(this.ordersMap.orderMarkers, function(marker){ return marker.id ==  payload.user_id });
        var orderLocation = new google.maps.LatLng(payload.latitude, payload.longitude);
        orderMarker.moveTo(orderLocation, payload.orientation, payload.user_id);
      },
      newOrder: (order_id) => {
        Packs.application.toastr.success('new order');
        // PushNotification.Sound.stop();
        this.getOrder(order_id, function( order ) {
          this.ordersMap.appendNewOrder(order);
        }.bind(this));

        this.updateBestSeller();
      }
    }
    this.ordersMap = new WatchOrdersOnMap({userId: this.userId, orders: this.orders, delegate: this.delegate});
   
    $('#hide-markers').on('click', function() {
      this.ordersMap.mapView.removeDrivuBranchLogos();
    }.bind(this));

    $('#show-markers').on('click', function() {
      this.ordersMap.mapView.showDrivuBranchLogos();
    }.bind(this))
  }

  getOrder(order_id, cb ){
    $.ajax({
      url: `/moderator/live_orders/${order_id}`,
      dataType: "json",
      success: function(data, status, xhr){
        cb(data.order);
      }
    })
  }

  updateBestSeller(){
    $.ajax({
      url: `/moderator/live_best_seller.js`,
      method: "get"
    });
  }
}


