module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("\n<div class=\"form-group d-sm-flex flex-row justify-content-between\">\n\n  <div class=\"form-check form-switch\">\n    <input type=\"hidden\" value=\"0\"><input id=\"active_")
    ; __append(escapeFn( item.id ))
    ; __append("\" class=\"form-check-input item-active\" type=\"checkbox\" checked=\"checked\">\n    <label class=\"form-check-label\" for=\"active_")
    ; __append(escapeFn( item.id ))
    ; __append("\">Active</label>\n  </div>\n\n  <div class=\"form-check form-switch new_item\">\n    <input type=\"hidden\" value=\"0\"><input id=\"new_item_")
    ; __append(escapeFn( item.id ))
    ; __append("\" class=\"form-check-input item-new\" type=\"checkbox\">\n    <label class=\"form-check-label\" for=\"new_item_")
    ; __append(escapeFn( item.id ))
    ; __append("\">New item</label>\n  </div>\n\n  <div class=\"form-check form-switch best_seller\">\n    <input type=\"hidden\" value=\"0\"><input id=\"best_sellers_")
    ; __append(escapeFn( item.id ))
    ; __append("\" class=\"form-check-input item-best-sellers\" type=\"checkbox\">\n    <label class=\"form-check-label\" for=\"best_sellers_")
    ; __append(escapeFn( item.id ))
    ; __append("\">Best seller</label>\n  </div>\n\n  <div class=\"custom-control\">\n  </div>\n</div>\n\n<div class=\"row\">\n  <div class=\"col-sm-6\">\n    <div class=\"input-wrap\">\n      <a class=\"item-extras btn dashed-btn btn-block\" id=\"item-extra-options-path-")
    ; __append(escapeFn( item.id ))
    ; __append("\" title=\"Add Extras/Options\" href=\"\">Add options/add-ons</a>\n    </div>\n  </div>\n</div>")
  }
  return __output;

}