export class OrderView{
  constructor(options) {
    this.order_status_class_name =  options.order_status_class_name;
    this.bindListeners();
  }

  bindListeners() {
   var  _this = this;
    $("table").on( 'focus', this.order_status_class_name, function(e) {
      $(this).data('prev-status', $(this).val());
    });

    $("table").on('change', this.order_status_class_name, function(e) {
      var prev = parseInt($(this).data('prev-status'));
      var current = parseInt($(this).val());
      if([3, 4].includes(prev) && current == 2){
        _this.authorization(this, _this);
      }else{
        _this.update_order(this);
      }
    });
  }

  update_order(e){
    if($(e).val() == 3){
     var cancellation_notes= prompt("Please enter your cancellation reason");
    } 

    $.ajax({
      url: $(e).parents("tr").first().attr("id"),
      type: 'put',
      dataType: 'JSON',
      data: {
      'order[status]': $(e).val(),
      'order[prev_status]': $(e).data('prev-status'),
      'order[notes]': $(e).data('note'),
      'order[password]': $(e).data('password'),
      'order[cancellation_notes]': cancellation_notes,
      },
      success: function(status,data,xhr){
        $("#order-authorization-modal").modal("hide");
      },
      error: function(xhr){
        // console.log(xhr.responseJSON.errors);
        Packs.application.toastr.error(`Error: ${xhr.responseJSON.errors}`);

      }
    })
  }

  authorization(e, _this){
    $("#order-authorization-modal").modal('show');
    $(".authorize-btn").on('click',function(){
      $(this).data("note",$('#can-to-done-note').val());
      _this.update_order(this);
    }.bind(e));

  }

  changed_from_cancelled_or_delayed_to_done(prev, current) {
    ([3, 4].includes(prev) && current == 2) ? true : false
  }
}