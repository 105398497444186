import { Model } from "./model";
export class Branch extends Model {
  // currency; name; taxType;

  getVatTitle() {
    switch (this.taxType) {
      case Branch.TaxType.Included:
        return '+VAT (Included)'
    
      default:
        return '+VAT'
    }
  }
}

Branch.TaxType  = {
  Included: "tax_included",
  Excluded: "tax_excluded",
  Inclusive: "tax_inclusive"
}