(function() {

  var jqueryRemotiPartInit = function($, undefined) {
    var remotipart;

    $.remotipart = remotipart = {

      setup: function(form) {
        // Preserve form.data('ujs:submit-button') before it gets nulled by $.ajax.handleRemote
        var button = form.data('ujs:submit-button'),
          csrfParam = $('meta[name="csrf-param"]').attr('content'),
          csrfToken = $('meta[name="csrf-token"]').attr('content'),
          csrfInput = form.find('input[name="' + csrfParam + '"]').length;

        form
        // Allow setup part of $.rails.handleRemote to setup remote settings before canceling default remote handler
        // This is required in order to change the remote settings using the form details
          .one('ajax:beforeSend.remotipart', function(e, xhr, settings){
            // Delete the beforeSend bindings, since we're about to re-submit via ajaxSubmit with the beforeSubmit
            // hook that was just setup and triggered via the default `$.rails.handleRemote`
            // delete settings.beforeSend;
            delete settings.beforeSend;

            settings.iframe      = true;
            settings.files       = $($.rails.fileInputSelector, form);
            settings.data        = form.serializeArray();

            // Insert the name/value of the clicked submit button, if any
            if (button)
              settings.data.push(button);

            // jQuery 1.9 serializeArray() contains input:file entries
            // so exclude them from settings.data, otherwise files will not be sent
            settings.files.each(function(i, file){
              for (var j = settings.data.length - 1; j >= 0; j--)
                if (settings.data[j].name == file.name)
                  settings.data.splice(j, 1);
            })

            settings.processData = false;

            // Modify some settings to integrate JS request with rails helpers and middleware
            if (settings.dataType === undefined) { settings.dataType = 'script *'; }
            settings.data.push({name: 'remotipart_submitted', value: true});
            if (csrfToken && csrfParam && !csrfInput) {
              settings.data.push({name: csrfParam, value: csrfToken});
            }

            // Allow remotipartSubmit to be cancelled if needed
            if ($.rails.fire(form, 'ajax:remotipartSubmit', [xhr, settings])) {
              // Second verse, same as the first
              $.rails.ajax(settings).always(function(data){
                $.rails.fire(form, 'ajax:remotipartComplete', [data]);
              });
              setTimeout(function(){ $.rails.disableFormElements(form); }, 20);
            }

            //Run cleanup
            remotipart.teardown(form);

            // Cancel the jQuery UJS request
            return false;
          })

          // Keep track that we just set this particular form with Remotipart bindings
          // Note: The `true` value will get over-written with the `settings.dataType` from the `ajax:beforeSend` handler
          .data('remotipartSubmitted', true);
      },

      teardown: function(form) {
        form
          .unbind('ajax:beforeSend.remotipart')
          .removeData('remotipartSubmitted')
      }
    };

    $(document).on('ajax:aborted:file', 'form', function(){
      var form = $(this);

      remotipart.setup(form);

      // Manually call jquery-ujs remote call so that it can setup form and settings as usual,
      // and trigger the `ajax:beforeSend` callback to which remotipart binds functionality.
      $.rails.handleRemote(form);
      return false;
    });
  }

  if (window.jQuery) {
    jqueryRemotiPartInit(jQuery);
  } else if (typeof exports === 'object' && typeof module === 'object') {
    module.exports = jqueryRemotiPartInit;
  }

})(this);
 