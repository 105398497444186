const trendingItemsTemplate = require('../templates/trending_items.ejs')
const itemsDiscoverTemplate = require('../templates/discover.ejs')
const branchDiscoverTemplate = require('../templates/discover_all.ejs')
const itemDiscoverTemplate = require('../templates/item_discover.ejs')

import { hideLoadingWheel, showLoadingWheel } from '../utilits/drivu_global_functions';
import { DrivuMap } from '../utilits/drivu_map';

// const DESIGNS = ['tag', 'all', 'item']
const Designs = {
  BRANCH: 1,
  ITEMS: 0,
  ITEM: 2
}

const CITY_NAME_FIELD = "#city_name"

export class DiscoverEmulationView {

  static getTrendingItems(admins_trending_items_path) {
    $.ajax({
      url: admins_trending_items_path,
      method: "get",
      dataType: "json",
      success: function (response, status, xhr) {
        $(`#${response.city.id}-trending-items`).html(trendingItemsTemplate({ response: response, city_name: response.city.name }))
      },
      errors: function () {
        console.log("errors");
      }
    });
  }

  constructor(options) {
    this.current_design = Designs.BRANCH
    this.current_tag_id 
    Object.keys(options).forEach(function (key) {
      this[key] = options[key]
    }.bind(this));

    this.drivuMap = new DrivuMap({
      elementId: "map-canvas",
      mapOptions: {
        center: new google.maps.LatLng(parseFloat(25.245283333780417), parseFloat(55.295513929581375)),
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true
      }
    });

    this.bindListeners();
  }

  bindListeners() {
    var _this = this;
    $('#discover_city').on("change", function () {
      $(CITY_NAME_FIELD).val(this.value)
    });

    this._setFilterForm();

    $('#rebuild-trending-items').on("submit", function (e) {
      this._saveFilterParams(e);
      showLoadingWheel();
    }.bind(this));

    $('#rebuild-trending-items').on("ajax:success", function () {
      hideLoadingWheel();
    });

    this.drivuMap.onClick(function (event) {
      this.drivuMap.moveMarker(event.latLng.lat(), event.latLng.lng());
      this.drivuMap.pinMarkerToCurrentMap(this.drivuMap.marker);
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'latLng': event.latLng }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            var city = this.getCityFromGeocodeResult(results[1]);
            $(CITY_NAME_FIELD).val(city);
          }
        }
        this.drawScreen();
      }.bind(this));

    }.bind(this));

    $('.tags').on('click', '.tag-btn', function () {
      $(".tags div").removeClass("active");
      $(this).addClass("active");
      _this.current_tag_id = $('.tag-btn.active').attr("id");
      _this.drawScreen();
    });


    $('#filter-by-city-name').on('click', function (e) {
      this.getDiscoverItems();
    }.bind(this));

    this.requestTagsWithCity();
    this.drawScreen();

  }

  getCityFromGeocodeResult(result) {
    var city = '';
    for (var i = 0; i < result.address_components.length; i++) {
      if (result.address_components[i].types[0] == 'locality') {
        city = result.address_components[i].long_name;
        break;
      }
    }
    return city;
  }

  drawScreen() {
    this._getDiscoverItems();
    // this._getAdItem();
    // if( this._tagDesign() ) { this._getDiscoverTrendingItems() };
  }

  requestTagsWithCity() {
    if ($(CITY_NAME_FIELD).val().length > 0) {
      $.ajax({
        url: this.admins_branch_tags_path,
        type: 'GET',
        data: {
          city_name: $(CITY_NAME_FIELD).val(),
          mode: "discover"
        },
        success: function () { },
        error: function () { }
      });
    }
  }

  calculateDistance(from_location, to_location) {
    var distanceMeter = google.maps.geometry.spherical.computeDistanceBetween(from_location, to_location);
    var distanceKiloMeter = (distanceMeter / 1000);
    var timeDiff = ((distanceKiloMeter * 60) / 40)
    return (timeDiff < 2) ? 2 : timeDiff
  }

  _saveFilterParams(e) {
    localStorage.setItem("trending-filter-form", JSON.stringify($('#rebuild-trending-items').serializeArray()))
  }

  _setFilterForm() {
    _.each(JSON.parse(localStorage.getItem("trending-filter-form")), function (element) {
      $("[name=" + "'" + element.name + "'" + "]").val(element.value);
    })
  }

  _sendRequest(path, method, dataType, params, after_success, after_error) {
    $.ajax({
      url: path,
      method: method,
      data: params,
      dataType: dataType,
      success: function (response, status, xhr) { after_success(response, status, xhr) },
      errors: function () { after_error() }.bind(this)
    });
  }

  _getDiscoverItems() {
    this._sendRequest(this.admins_discovers_path, 'get', 'json', {
      
      latitude: this.drivuMap.marker.position.lat(),
      longitude: this.drivuMap.marker.position.lng(),
      tag: $('.tag-btn.active').attr("id"),
      city_name: $('#city_name').val(),
      per_page: 15
    }, function (response, status, xhr) {
      // console.log(response);
      this.current_design = response.design
      // this.renderAllView(response);
      $('.branches').html("");
      switch (this.current_design) {
        case Designs.BRANCH:
          this.renderAllView(response)
          break;
        case Designs.ITEMS:
          this.renderTagView(response)
          break;
        case Designs.ITEM:
          this.renderSingleItem(response)
          break;
        default:
          break;
      }
      // this._allDesign() ? this.renderAllView(response) : this.renderTagView(response)
      // console.log(this);

    }.bind(this), function () {
      console.log("getDiscoverItems errors");
    });
  }

  _getAdItem() {
    this._sendRequest(this.admins_discover_advertisements_path, 'get', 'json', { source: 'discover', city_name: $(CITY_NAME_FIELD).val() }, function (response, status, xhr) {

    }, function () {

    });
  }

  _getDiscoverTrendingItems() {
    console.log('_getDiscoverTrendingItems');
  }

  _allDesign() {
    return this.current_design == Designs.BRANCH
  }

  _tagDesign() {
    return this.current_design == Designs.ITEMS
  }

  renderAllView(response) {
    this.calculateDistanceAfterResponse(response)
    $('.branches').html(branchDiscoverTemplate({ response: response, current_tag_id: Number($('.tag-btn.active').attr("id")) }))
  }

  renderTagView(response) {
    this.calculateDistanceAfterResponse(response)
    $('.branches').html(itemsDiscoverTemplate({ response: response, current_tag_id: Number($('.tag-btn.active').attr("id")) }))
  }

  renderSingleItem(response) {
    this.calculateDistanceAfterResponse(response)
    $('.branches').html(itemDiscoverTemplate({ response: response, current_tag_id: Number($('.tag-btn.active').attr("id"))}))
  }

  calculateDistanceAfterResponse(response) {
    response.branches.forEach(branch => {
      branch.minsAway = Math.ceil(this.calculateDistance(this.drivuMap.marker.position, new google.maps.LatLng(parseFloat(branch.latitude),
        parseFloat(branch.longitude))))
    });
  }
}