module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  _.each(web_payment_methods, function(payment_method) { 
    ; __append("\n  <div class=\"payment-option mb-2\" data-payment-method=\"")
    ; __append(escapeFn( payment_method.on_select_event))
    ; __append("\">\n    <div class=\"row justify-content-between py-3\">\n      <div class=\"col\">\n        <div class=\"row g-0 align-items-center\">\n          <div class=\"col-1\">\n            <div class=\"payment-method-custome-checkbox\">\n              <input class=\"form-check\" type=\"radio\" name=\"payment_method\"  value=\"")
    ; __append(escapeFn( payment_method.value ))
    ; __append("\" >\n            </div>\n          </div>\n\n          <div class=\"col\">\n            <label class=\"mb-0\" >")
    ; __append(escapeFn( payment_method.name ))
    ; __append("</label>\n          </div>\n        </div>\n      </div>\n\n      <div class=\"icons col-auto\">\n          <img src = ")
    ; __append(escapeFn(`${payment_method.icon}` ))
    ; __append(" width=\"30\">\n      </div>\n    </div>\n\n    <div class=\"row justify-content-between\">\n      <div class=\"col\">\n        <div class=\"row g-0\">\n          <div class=\"col offset-1\">\n            ")
    ;  if(payment_method.value == 'credit-card' ) {
    ; __append("\n              <div class=\"border-top collapse in-use-credit-card \">\n    \n                ")
    ; if(shop_in_use_credit_card != undefined) {
    ; __append("\n                  <form action=\"")
    ; __append(escapeFn( shop_adv_sales_request_path ))
    ; __append("\" accept-charset=\"UTF-8\" method=\"post\">\n                    <input type=\"hidden\" name=\"credit_card[ref_id]\" id=\"credit_card_ref_id\" value=\"")
    ; __append(escapeFn( shop_in_use_credit_card.ref_id))
    ; __append("\">\n                    <input type=\"hidden\" name=\"authenticity_token\" id=\"authenticity_token\" value=\"")
    ; __append(escapeFn( form_authenticity_token))
    ; __append("\">\n    \n                    <input type=\"hidden\" name=\"credit_card[ref_id]\" id=\"credit_card_ref_id\" value=\"")
    ; __append(escapeFn( shop_in_use_credit_card.ref_id))
    ; __append("\">\n                    <p class=\"my-2 ms-3\">****")
    ; __append(escapeFn(shop_in_use_credit_card.number))
    ; __append("</p>\n                  </form>\n                  <p class=\"blue-font  auth-request-btn-inside-credit-card get-hosted-payment-page-url\" >Pay with differant Credit/Debit card</p>\n\n                ")
    ; }
    ; __append("\n              </div>\n    \n            ")
    ;  } 
    ; __append("\n\n            <div class=\"border-top collapse pay-from-online-sales-note \">\n              ")
    ; if(payment_method.value == 'curbsidePayFromOnlinePaymentSales') {
    ; __append("\n                    <p class=\"blue-font\" >Note: the amount will be deducted automatically from the online payment sales received from orders paid by customers.</p>\n              ")
    ; }
    ; __append("\n            </div>\n          </div> \n        </div>\n      </div>\n    </div>\n\n\n  </div>\n")
    ; })
    ; __append("\n\n")
  }
  return __output;

}