export class ShopOrderView{
  constructor(options={}){
    Object.keys(options).forEach(function(key) {
      this[key] = options[key];
    }.bind(this));

    $('#shopFilterForm').on('submit', function(params) {
      this.getValues($('#shopFilterForm').serialize());
      this.getOrders($('#shopFilterForm').serialize())
    }.bind(this));
   
    this.getValues();
  }


  getValues(filterDate){
    // this.getTotalValues(filterDate);
    // this.getDailyValues(filterDate);
    this.getMostSellingItems(filterDate);
    this.getShopMostBuyers(filterDate);
    this.getFinancialSummary(filterDate);
  }

  getTotalValues(filterDate){
    $.ajax({
      url: `total_values.js?${filterDate}`,
      method: "get",
    })
  }

  getDailyValues(filterDate){
    $.ajax({
      url: `daily_values.js?${filterDate}`,
      method: "get",
    })
  }

  getMostSellingItems(filterDate){
    $.ajax({
      url: `most_selling_items.js?${filterDate}`,
      method: "get",
    })
  }

  getShopMostBuyers(filterDate){
    $.ajax({
      url: `shop_most_buyers.js?${filterDate}`,
      method: "get",
    })
  }

  getFinancialSummary(filterDate){
    $.ajax({
      url: `financial_summary.js?${filterDate}`,
      method: "get",
    })
  }

  getOrders(filterDate){
    $.ajax({
      url: `restaurant_orders.js/?${filterDate}`,
      method: "get",
      
    })
  }
}