module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("\n<div class=\"invoice\">\n  <div class=\"p-sm-5\">\n   \n    <div class=\"row justify-content-sm-end\">\n      <div class=\"col-sm-6 col-12 text-end\">\n        <div class=\"invoice-logo-header\">\n          <img src= \"https://drivu.s3.eu-west-1.amazonaws.com/orange_logo.png\"/>\n        </div>\n        <ul class=\"unstyled-list drivu-financial-information\">\n          <li>4M Technologies LLC</li>\n          <li>TRN: 100055610800003</li>\n          <li>Jumeirah Center,</li>\n          <li>1st Floor,Dubai,UAE</li>\n        </ul>\n      </div>\n    </div>\n  \n    <div class=\"row\">\n      <div class=\"col-sm-6\">\n        <ul class=\" list-group unstyled-list shop-financial-information pl-sm-0\" >\n          <li class=\"commercial-name\">")
    ; __append(escapeFn( invoice.commercial_document_name ))
    ; __append("</li>\n          <li class=\"trn\">TRN: ")
    ; __append(escapeFn( trn ))
    ; __append("<span> رقم التسجيل الضريبي</span></li>\n          <li class=\"address\">")
    ; __append(escapeFn( address ))
    ; __append("</li>\n        </ul>\n      </div>\n  \n      <div class=\"col-sm-6\">\n        <ul class=\" list-group unstyled-list text-sm-right invoice-information\">\n          <li class=\"orange-font\">Invoice date <span>تاريخ الاصدار</span></li>\n          <li>")
    ; __append(escapeFn( invoice.end_date ))
    ; __append(" </li>\n          <li class=\"orange-font\">Invoice number <span> رقم الفاتورة المتسلسل</span></li>\n          <li>")
    ; __append(escapeFn( invoice.invoice_number ))
    ; __append("</li>\n        </ul>\n      </div>\n    </div>\n  \n    <div class=\"row justify-content-center\">\n      <div class=\"col-12\">\n        <h3 class=\"text-center\">TAX INVOICE</h3>\n      </div>\n    </div>\n\n    \n    <div class=\"row \">\n      <div class=\"col-12\">\n          <div class=\"table-responsive-sm  p-2 table-borderless\">\n            <table class=\"table bg-gray text-center\">\n              <thead class=\"white-border-bottom\" >\n                <th>\n                  <p>التاريخ</p>\n                  Date\n                </th>\n                \n                <th>\n                  <p class=\"rtl\"> تفاصيل المعاملة</p>\n                  Transaction deatils\n                </th>\n\n                <th>\n                  <p>المبلغ بدرهم الامارات</p>                \n                  Amount in AED\n                </th>\n  \n                ")
    ;  if(ShowOnlinePaymenTransactions){ 
    ; __append("\n                  <th>\n                    <p>معاملات دفع\n                       <br>الكتروني</p>\n                    Online Payment\n                    <br> transactions\n                  </th>\n                  <th>\n                    <p>المبلغ بدرهم الامارات</p>                \n                    Amount in AED\n                  </th>\n                ")
    ; }
    ; __append("\n                \n                <th>\n                  <p>الضريبة القيمة المضافة بدرهم الامارات</p>                \n                  Vat in AED\n                </th>\n                \n                <th>\n                  <p>المبلغ الاجمالي  بدرهم الامارات</p>\n                  Total in AED\n                </th>\n              </thead>\n              <tbody>\n                <tr>\n                  <td>")
    ; __append(escapeFn( invoice.end_date))
    ; __append("</td>\n                  \n                  <td>\n                    Drivu Service fees<br>\n                    ")
    ; __append(escapeFn( `from ${invoice.start_date} to ${invoice.end_date}`  ))
    ; __append("\n                  </td>\n                  \n                  <td>")
    ; __append(escapeFn( invoice.total_service_fees ))
    ; __append("</td>\n  \n                  ")
    ;  if(ShowOnlinePaymenTransactions){ 
    ; __append("\n                    <td>\n                      processing fees \n                      ")
    ; __append(escapeFn( `from ${invoice.start_date} to ${invoice.end_date}`  ))
    ; __append("\n                    </td>\n                    <td>\n                      ")
    ; __append(escapeFn( invoice.total_credit_card_fees ))
    ; __append("<br>\n                    </td>\n\n                  ")
    ;  } 
    ; __append("\n  \n                  <td>")
    ; __append(escapeFn( invoice.total_drivu_vat ))
    ; __append("</td>\n                  <td>")
    ; __append(escapeFn(  invoice.total_service_fees_and_vat ))
    ; __append("</td>\n                </tr>              \n  \n                ")
    ; if (!invoice.old_invoice) {
    ; __append("\n                  <tr>\n                    <td>")
    ; __append(escapeFn( invoice.end_date))
    ; __append("</td>\n                    <td>\n                      Online payment processing fees<br>\n                      ")
    ; __append(escapeFn( `from ${invoice.start_date} to ${invoice.end_date}`  ))
    ; __append("\n                    </td>\n                    <td>")
    ; __append(escapeFn( invoice.total_credit_card_fees ))
    ; __append("</td>\n                    <td>")
    ; __append(escapeFn( invoice.total_telr_vat ))
    ; __append("</td>\n                    <td>")
    ; __append(escapeFn(  invoice.total_online_payment_processing_fees ))
    ; __append("</td>\n                  </tr>              \n                ")
    ;  } 
    ; __append("\n\n                <tr>\n                  <td colspan=\"7\" class=\"p-0\">\n                    <div class=\"row rounded py-3  btn-orange m-0\">\n                      <div class=\"col-6 text-start\" style=\"padding-left: 0.75rem\">Total</div>\n                      <div class=\" col-6 text-end\">")
    ; __append(escapeFn( invoice.currency ))
    ; __append("<span>")
    ; __append(escapeFn( invoice.total_amount_due ))
    ; __append("</span></div>\n                    </div>\n                  </td>\n                </tr>\n\n              </tbody>\n            </table>\n\n          </div>\n      </div>\n    </div>\n\n    ")
    ; if( parseFloat(invoice.new_balance_until) < 0 ) { 
    ; __append("\n      <div class=\"row\">\n        <div class=\"col-12\">\n          <p class=\"notes\">Note: The amount mentioned in the invoice has been settled & paid off through sales coming from Drivu online payment. Please refer to your Statement of Account in the same email.</p>\n        </div>\n      </div>\n    ")
    ; }else{
    ; __append("\n      <div class=\"row\">\n        <div class=\"col-12 grey-border\">\n          <ul class=\"list-group p-2\">\n            <p>Amount to be payable through deposit or bank transfer to: </p>\n            <p>4M Technologies LLC</p>\n            <p>Emirates Islamic Bank</p>\n            <p>Account Number: 3707505951702</p>\n            <p>IBAN: AE090340003707505951702</p>\n          </ul>\n        </div>\n      </div>\n    ")
    ; }
    ; __append("\n  </div>\n</div>\n")
  }
  return __output;

}