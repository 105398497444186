import { add_search } from "../utilits/drivu_global_functions";

export class AdminsPagesView {

  constructor(){
  }

  branchesSearch(){
    add_search('#branch-name', '/admins/searches', 'branches', '.branch-name-results' ,function (data, response) {
      response($.map(data, function (value, key) {
      
          return { label: `${value.shop_name} -> ${value.name}`, value: value.id }
      }));
     }, function (e, ui) {
      $(this).val("");
      console.log(ui.item)
    }, {type: "branch" });

  }
}