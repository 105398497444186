import { TestingOrder } from "./testing_order";

export class SimulateUserMovement{
 
  constructor(ui){
    this.order = $(ui).data('order');
    this.navigation = $(ui).data('navigation');
    this.map = Packs.application.MapView.pool[0].map
  }
  
  start(){
    // orderView.navigation = new Navigation(order.navigation);
    // orderView.navigation.polyline.setMap(map);
    var moveOrder = new TestingOrder({branch: this.order.branch, user: this.order.user, distance: 40, map: this.map });
    console.log(moveOrder);
    moveOrder.navigation = this.navigation;
    // moveOrder.marker = orderView.marker;
    moveOrder.connect();
    moveOrder.startDriving();
  }
}