import {} from "jquery-cropper"
const ImageCropperTemp = require("../templates/image_cropper.ejs")

export class ImageCropper{

  constructor(originalImageSrc, afterFinishCb,  options){
    this.originalImageSrc = originalImageSrc;
    this.options = {
      aspectRatio: 16 / 9,
      minContainerWidth: "100%",
      minContainerHeight: "auto",
      viewMode: 2,    
    }//options || {aspectRatio: 16 / 9, minContainerWidth: 300, minContainerHeight: 600};
  }

  start(finishCb){
    this._appendModal();
    this.originImageElement = $('#image-cropper').find('#image')
    this.originImageElement.on(this._events()).cropper(this.options);

    $('.actions-buttons').on('click', '[data-method]', function (e) {
      if ($(e.currentTarget).prop('disabled') || $(e.currentTarget).hasClass('disabled')) {
        return;
      }
      var data = $(e.currentTarget).data();
      if(data.method == "finish"){
          this._hideModal();
          finishCb(this);
      }else{
        this.originImageElement.cropper(data.method, data.option, data.secondOption);
      }
    }.bind(this));
  }

  getBlobFile(cb){
    this.originImageElement.cropper("getCroppedCanvas").toBlob(function(blob){
      let file = new File([blob], `${new Date().getTime()}-img.jpg`, {type:"image/jpeg", lastModified: new Date().getTime() });
      let container = new DataTransfer();
      container.items.add(file);
      cb(container.files);
    }.bind(this))
  }

  _events(){
    // return {
    //   ready: function (e) {
    //     console.log(e.type);
    //   },
    //   cropstart: function (e) {
    //     console.log(e.type, e.detail.action);
    //   },
    //   cropmove: function (e) {
    //     console.log(e.type, e.detail.action);
    //   },
    //   cropend: function (e) {
    //     console.log(e.type, e.detail.action);
    //   },
    //   crop: function (e) {
    //     console.log(e.type);
    //   },
    //   zoom: function (e) {
    //     console.log(e.type, e.detail.ratio);
    //   }
    // };
  }

  _appendModal(){
    $('body').append(ImageCropperTemp({image_src: this.originalImageSrc}));
    $('#image-cropper').modal("show");
  }

  _hideModal(){
    $('#image-cropper').modal("hide");
  }

}