'use strict';
import { DrivuFingerPrint } from "../utilits/drivu_finger_print";
import { PushNotification } from "./push_notification";

import * as io from 'socket.io-client';
var toastr = require('toastr');

export class DrivuSocketIO {

  constructor(options, callback) {
    console.log(options)
    PushNotification.Sound.load();
    this.user_id = options.user_id;
    this.connected = false;
    this.client = undefined;
    this.new_location_callback = options.new_location_callback || (function () { });
    this.newOrderListener = options.newOrderListener || function () { };
    this.orderStatusChangeListener = options.orderStatusChangeListener || function () { };
    this.onOrderFeedbackCb = options.onOrderFeedbackCb || function () { };
    this.OnNewComment = options.OnNewComment || function () { };
    this.location_list = [];

    this.get_socket_url(function (err, url, token) {

      this.client = io(url, {
        query: {
          token: token,
          multiplex: false, secure: true
        }
      });
      $('#connectionAlert').hide();
      this.connected = true;
      if (options.order_owner_id)
        this.bind_listeners(callback);

      this.client.on('item_delete', (payload) => {
        toastr.error(`${payload.payload.item_name} was removed by shop`);
      });
    }.bind(this));
  }

  get_socket_url(callback) {
    $.ajax({
      type: "GET",
      url: "/admins/socket_urls/" + this.#get_public_key(),
      success: function (response) {
        callback(null, response.url, response.token);
      },
      error: function (error) {
        callback(error, null);
      }
    });
  }

  examine_url(user_id) {
    var order_page_regex = /\/admins\/orders\/(.*)/g;
    var multi_shop_agent_regex = /multi_shop_agent\/shop_orders/;
    var support_order_regex = /\/support_agents\/orders\/(.*)/g;
    var live_order_regex = /moderator\/live_order/;
    
    var match = order_page_regex.exec(window.location.href) || multi_shop_agent_regex.exec(window.location.href) || support_order_regex.exec(window.location.href) || live_order_regex.exec(window.location.href);
    if (match && user_id) {
      console.log('watch_user_location');
      this.watch_user_location(user_id);
    } else {
      console.log('unwatch_user_location');
      this.unwatch_user_location(user_id);
      this.disconnect();
    }
  }

  bind_listeners(callback) {

    this.client.on('connect', function () {
      $('#connectionAlert').hide();
      if (callback)
        callback();
      this.client.on("new_location", this.new_location_callback);
    }.bind(this));

    this.client.on('remote_notification', (args) => {
      const delegate = {
        new_order: () => {
          this.newOrderListener(args.payload.order_id);
        },
        
        order_status_changed: () => {
          PushNotification.Sound.stop();
          this.orderStatusChangeListener(args.payload.order_id, args.payload.status)
        },

        feedback: function() {
          PushNotification.Sound.playFor(1000)
          this.onOrderFeedbackCb(args.payload)
        }.bind(this),
        
        new_comment: function(title, body){
          this.OnNewComment(title, body)
        }.bind(this)

      };

      new PushNotification(args.payload, delegate);
    });

    this.client.on("error", function(e) {
      $('#connectionAlert').show();
    })

    this.client.on("disconnect", function () {
      $('#connectionAlert').show();
      this.connected = false;
    }.bind(this));
  }

  watch_user_location(user_id) {
    this.client.emit("watch_location", { user_id: user_id });
    if (this.location_list.indexOf(user_id) != -1)
      this.location_list.push(user_id);
  }

  unwatch_user_location(user_id) {
    var id_index = this.location_list.indexOf(user_id);
    this.client.emit("unwatch_location", { user_id: user_id });
    this.location_list.splice(id_index, 1);
  }

  disconnect() {
    if (this.connected)
      this.client.disconnect();
  }

  user_new_location(params) {
    this.client.emit("stream_location", params);
  }

  #get_public_key(){
    return new DrivuFingerPrint().get_secure_token();
  }

}