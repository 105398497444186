import _ from "underscore";

export class OrderItem {

  static type = 'OrderItem';

  constructor(item, options) {
    this.__id = (item.__id == 'undefined' || item.__id == undefined) ?  Math.random().toString(36).slice(2) : item.__id;    
    this.id = item.id;
    this.name = item.name || undefined;
    this.notes = item.notes || undefined;
    this.image_url = item.image_url;
    this.extras = item.extras;
    this.option_ids = item.option_ids || [];
    this.pre_order = item.pre_order;
    this.price = item.price;
    this.quantity = item.quantity || 1;
    this.item_modal_id = options ? options.item_modal_id : undefined;
    this.key = this.__id.toString();
    this.currency = (item.branch) ? item.branch.currency_symbol : item.currency
    this.desc = item.desc || '',
    this.active_promotion = item.active_promotion,
    this.discount_rate  = item.discount_rate
  }

  toJson() {
    return {
      __id: this.__id,
      id: this.id,
      notes: this.notes,
      option_ids: this.option_ids,
      price: this.price,
      extras: this.extras,
      quantity: this.quantity,
      grand_total_before_discount: this.calculateTotalBeforeDiscount(),
      grand_total: this.calculateTotal(),
      currency: this.currency,
      type: OrderItem.type,
      desc: this.desc,
      active_promotion: this.active_promotion,
      discount_rate: this.discount_rate
    }
  }

  grandTotalWithCurrency() { return `${this.calculateTotal()} ${this.currency}` }
  grandTotalWithCurrencyAndQuantity() { return `${this.grandTotalWithCurrency()} X ${this.quantity}` }

  originalGrandTotalWithCurrencyAndQuantity() { return `${this.originalGrandTotalWithCurrency()} X ${this.quantity}` }
  
  originalGrandTotalWithCurrency(){ return `${this.calculateTotalBeforeDiscount()} ${this.currency}`}
  
  calculateTotal() {
    var total = this.calculateTotalBeforeDiscount()
    return (total - ((this.discount_rate / 100) * total))
  }
 
  calculateTotalBeforeDiscount(){
   return (_.chain(this.extras)
    .map((extra) => { return extra.options })
    .flatten()
    .select((option) => { return this.option_ids.includes(option.id) })
    .map((option) => { return option.price })
    .reduce((memo, num) => { return memo + num}, 0) + this.price) * this.quantity;

  }
  quantity_minus(e) {
    this.quantity = parseInt($('#quantity').val());
    if (this.quantity > 0) { this.quantity = $('#quantity').val(this.quantity - 1).val(); }
  }

  quantity_plus(e) {
    this.quantity = parseInt($('#quantity').val());
    this.quantity = $('#quantity').val(this.quantity + 1).val();
  }

  update_item(key, property, value) {
    var retrievedObject = localStorage.getItem(key);
    var obj = JSON.parse(retrievedObject);
    obj[property] = value;
    localStorage.setItem(key, JSON.stringify(obj));
  }

  get_item(key, property, value) {
    return JSON.parse(localStorage.getItem(key));
  }

  set_item(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  item_exists() {
    return (localStorage.getItem(this.id) != null);
  }

  option_selected(id) {
    return _.contains(this.option_ids, id.toString());
  }

  selectOption(id) { if(!this.option_selected(id)) this.option_ids.push(id) }
  unselectOption(id) { this.option_ids.delete(id) }

  buildDescription(extras) {
    const options = _.chain(extras).map((extra) => {
      const op = extra.options.filter((option) => { return this.option_ids.includes(option.id) })
      if(op.length > 0) {
        return `${extra.name}: ${_.pluck(op, 'name').join(', ')}`
      }
    }).without(undefined).value()

    this.desc = `${options.join('<br />')}`
    if(this.notes) this.desc += `<br />Special request: ${this.notes}`
  }

  remove() { localStorage.removeItem(this.key) }

  save() {
    this.remove();
    localStorage.setItem(this.key, JSON.stringify(this.toJson()))
    return this
  }


  static findOrInitializeFor(item) {
    var local = JSON.parse(localStorage.getItem(item.__id.toString()))
    return local == null ? (new OrderItem(item, {})) : (new OrderItem(local, {}));
  }

  static getItem(id) { return JSON.parse(localStorage.getItem(id)); }
  static all() {
    return _.chain(Object.values({ ...localStorage }))
            .select((val) => { return val.isValidJson(); })
            .map((val) => { return JSON.parse(val); })
            .select((val) => { return val.type == OrderItem.type; })
            .map((it) => { return new OrderItem(it); })
            .value();
  }



}