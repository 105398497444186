import { Indoor } from "../indoor";
import acceptedIcone from '../../../images/accepted_order_icon.webp';
import pendingIcone from '../../../images/pending_acceptance_icon.webp';

Indoor.orderDetailsView = class  {
  constructor(ui){
    this.ui = ui;
  }
}

Indoor.anonymousUserorderDetailsView = class extends Indoor.orderDetailsView {
  statusFiled = "#order-status-text"
  statusImageFiled = "#order-status-image"
  peperationTimeFiled = '#peperation-time'
  pendingProfileTitleFiled = '.pending-profile-title'
  acceptedProfileTitleFiled = '.accepted-profile-title'
  branchLocationFiled = '.branch-location'
 
  acceptedOrder = {
    status: 'Accepted',
    statusImage: acceptedIcone,
    peperationTime: "",
    title: 'Staff are coming',
    class_name: "accepted",
    branchLocationDisplay: false,
    pendingProfileTitle: false,
    acceptedProfileTitle: true
  } 

  pendingOrder = {
    status: 'Waiting acceptance',
    statusImage: pendingIcone,
    peperationTime: "",
    title: '',
    class_name: "waiting_acceptance",
    branchLocationDisplay: true,
    pendingProfileTitle: true,
    acceptedProfileTitle: false

  }

  constructor(ui){
    super(ui);
    this.order =  JSON.parse(ui.dataset.order);
    this.intervalId = window.setInterval(function(){
      this.getOrderStatus();
    }.bind(this), 5000);
  }

  getOrderStatus(){
    $.ajax({
      url: this.constructor.checkOrderStatusPath(this.order.branch_id), 
      method: "get",
      success: function(data){
        data.accepted == true ? this.updateView(this.acceptedOrder) : this.updateView(this.pendingOrder)
        if(data.done == true){
          clearInterval(this.intervalId);
          this.constructor.removeTraceToken();
          window.location.href = this.constructor.menuPath(this.order.branch_id);
        }
      }.bind(this)
    })
  }

  updateView(viewValues){
    $(this.statusFiled).text(viewValues.status)
    $(this.statusImageFiled).attr('src', viewValues.statusImage)
    $(this.peperationTimeFiled).text(viewValues.peperationTimeFiled)
    $(this.statusFiled).removeClass(this.pendingOrder.class_name).removeClass(this.acceptedOrder.class_name).addClass(viewValues.class_name)
    if(viewValues.title != ""){$(this.profileTitle).text(viewValues.title)}
    $(this.branchLocationFiled).toggle(viewValues.branchLocationDisplay)
    $(this.acceptedProfileTitleFiled).toggle(viewValues.acceptedProfileTitle)
    $(this.pendingProfileTitleFiled).toggle(viewValues.pendingProfileTitle)
  }

  static saveTraceToken(token){
    localStorage.setItem('current-order-trace-token', token)
  }

  static getTraceToken(){
    return localStorage.getItem('current-order-trace-token')
  }

  static removeTraceToken(){
    return localStorage.removeItem('current-order-trace-token')
  }

  static getOrderStatus(branch_id){
    $.ajax({
      url: this.checkOrderStatusPath(branch_id), 
      method: "get",
      success: function(data){
        if(data.done == true){
          this.removeTraceToken();
        }else{
          window.location.href = this.OrderPreviewPath(branch_id)
        }
      }.bind(this)
    })
  }
}

Indoor.CustomerLane.OrderDetailsView  = class extends Indoor.anonymousUserorderDetailsView {
   constructor(ui){
    super(ui)
   }

  static checkOrderStatusPath(branch_id){
    return `/customer_lane/branches/${branch_id}/orders/${this.getTraceToken()}/check_status`
  }

  static OrderPreviewPath(branch_id){
    return `/customer_lane/branches/${branch_id}/orders/${this.getTraceToken()}`
  }
 
  static menuPath(branch_id){
    return `/customer_lane/branches/${branch_id}/menus`;
  }

}

Indoor.OrderServing.OrderDetailsView = class extends Indoor.anonymousUserorderDetailsView {
   constructor(ui){
    super(ui)
   }

  static checkOrderStatusPath(branch_id){
    return `/order_serving/branches/${branch_id}/orders/${this.getTraceToken()}/check_status`
  }

  static OrderPreviewPath(branch_id){
    return `/order_serving/branches/${branch_id}/orders/${this.getTraceToken()}`
  }
 
  static menuPath(branch_id){
    return `/order_serving/branches/${branch_id}/menus`;
  }
} 

