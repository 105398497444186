export class StateCell {

  constructor(ui) {
    
    this.ui = ui
    this.id = $(this.ui).data('id')

    $(this.ui).find(`#parking-enabled-${this.id}`).on('change', event => {
      this.updateState(event.target.checked, 'parking_enabled');
    })

    $(this.ui).find(`#select-zone-parking-enabled-${this.id}`).on('change', event => {
      this.updateState(event.target.checked, 'select_zone');
    })

    $(this.ui).find('.plate-direction').on('change', e => {
      this.updateState(e.target.value, 'plate_direction')
    })

    $(this.ui).find(`#supported-states-control-${this.id}`).on('changed.bs.select', (e) => {
      
      var selected = $(e.target).find("option:selected")
      var arrSelected = [];
      selected.each(function() { arrSelected.push($(this).val()) })
      this.updateState(arrSelected, 'supported_state_ids')
      
    });
  }

  updateState(value, field) {
    const name = `state[${field}]`
    var data = {}
    data[name] = value
    $.ajax({
      type: 'PUT',
      url: `/admins/states/${this.id}.json`,
      data: data,
      success: () => {},
      error: () => {}
    })
  }

}