module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<ul class=\"list-group\">\n  <li class=\"list-group-item\"><span class=\"glyphicon glyphicon-user\">(")
    ; __append(escapeFn( username ))
    ; __append(")</span></li>\n  <li class=\"list-group-item\"><span class=\"glyphicon glyphicon-barcode\">(")
    ; __append(escapeFn( plateNumber ))
    ; __append(")</span></li>\n</ul>\n")
  }
  return __output;

}