
const branchTemplate = require('../templates/filtered_branch');

class TagSimulatorView{
  constructor(options){
    this.pageNumber = 1;
    this.totalBranches = 0;
    this.branches = [];
    this.center = new google.maps.LatLng(25.044062, 55.187154);
    this.bounds = new google.maps.LatLngBounds();
    this.tags = options.tags //JSON.parse('<%= j @tags.to_json.html_safe %>');
    this.currentTag;
    this.branchesMarkers = [];
    this.userMarker;
    this.map = new google.maps.Map(document.getElementById('map'), { center: this.center, zoom: 13});
    this.bindListeners();
    this.admin_searches_path = options.admin_searches_path;
    this.admins_tags_path = options.admins_tags_path;
  }

  bindListeners(){
    var _this = this;
    this.bindMapListeners(_this);

    _.each(this.tags, function(tag){
      var btn = document.createElement("BUTTON");
      btn.className = "btn btn-secondary tagButton";
      btn.innerText = tag.name;
      btn.id = `tagButton-${tag.id}`
      $("#mapTags").append(btn);
    });


    $('.tagButton').on('click', function(){
      var id = $(this).attr('id').split('-').pop();
      _this.currentTag = _.find(_this.tags, function(tag){ return tag.id == id });
      _this.resetBranches()
      _this.getBranches();
    });


    $('#loadMoreButton').on('click', function(){
      this.getBranches();
    }.bind(this))

    $('#cityNameField').on('keyup', function (e) {
      if (e.keyCode === 13) {
        _this.request_tags_with_city($(this).val())
      }
    });

  }

  bindMapListeners(_this){
    this.userMarker = new google.maps.Marker({
      position: (new google.maps.LatLng(0.0, 0.0)),
      map: _this.map
    });
  
    this.map.addListener('click', function(e) {
      this.userMarker.setPosition(e.latLng);
      this.resetBranches();
      this.getBranches();
    }.bind(this));  
  }


  resetBranches() {
    this.pageNumber = 0;
    this.totalBranches = 0;
    this.branches = [];
  }

  getBranches(){
    var _this =  this
    this.showLoadingButton();
    this.pageNumber++;  
    $.ajax({
      url: _this.admin_searches_path, //'<%= admins_searches_path %>.json',
      data: {
        latitude: _this.userMarker.getPosition().lat(),
        longitude: _this.userMarker.getPosition().lng(),
        tag: (_this.currentTag ? _this.currentTag.id : null),
        city_name: $('#cityNameField').val(),
        type: 'BRANCH',
        page: _this.pageNumber
      },
      success: function(response){
        _this.branches = _this.branches.concat(response.branches);
        _this.totalBranches = response.total;
        _this.renderBranches();
        _this.hideLoadingButton();
      },
      error: function(){ _this.hideLoadingButton(); }
    });
  }

  request_tags_with_city(city_name){
    if (city_name.length > 0) {
      $.ajax({
        url: this.admins_tags_path,//'<%= admins_tags_path %>.js',
        type: 'GET',
        data: {
          city_name: city_name
        },
        success: function(){},
        error: function(){}
      });
    }
  }

  showLoadingButton(){
    $('#loadMoreButton').hide();
    $("#loadingButton").show();
  }

  hideLoadingButton(){
    $('#loadMoreButton').show();
    $("#loadingButton").hide();
  }
  
  renderBranches(){
    $('.filteredBranch').fadeOut();
    _.each(this.branchesMarkers, function(m){
      m.setMap(null);
    }.bind(this));

    _.each(this.branches, function(branch){
      var location = new google.maps.LatLng(parseFloat(branch.latitude), parseFloat(branch.longitude));
      branch.distance = google.maps.geometry.spherical.computeDistanceBetween (location, this.userMarker.getPosition())
      var html = branchTemplate({branch: branch});
      $(html).insertBefore($("#listFooter"));
      this.bounds.extend(location);
      
      var m = new google.maps.Marker({
        position: location,
        icon: branch.thumb_url,
        map: this.map
      });

      this.branchesMarkers.push(m);
      this.map.fitBounds(this.bounds);
      
    }.bind(this));


    if(!this.thereAreMoreBranches()) $("#listFooter").hide();
  }

  thereAreMoreBranches(){
    return this.branches.length < this.totalBranches
  }

}

export{TagSimulatorView}